import ContentLayout from '@amzn/awsui-components-react/polaris/content-layout';
import Header from '@amzn/awsui-components-react/polaris/header';
import { useParams } from 'react-router-dom';
import PanelReviewSessionCreateEditForm from '@/components/PanelReviewSessionCreateEditForm';
import { usePanelReviewSession } from '@/api/panel-review';
import PromoSpinner from '@/components/common/PromoSpinner';

const PageHeader = () => {
  return <Header variant="awsui-h1-sticky">Edit panel review session</Header>;
};

const EditPanelReviewSession = () => {
  const { panelReviewSessionId } = useParams();
  const { isPanelReviewSessionLoading, panelReviewSession } = usePanelReviewSession(panelReviewSessionId);

  return (
    <ContentLayout header={<PageHeader />}>
      {isPanelReviewSessionLoading ? (
        <PromoSpinner />
      ) : (
        <PanelReviewSessionCreateEditForm panelReviewSession={panelReviewSession} />
      )}
    </ContentLayout>
  );
};

export default EditPanelReviewSession;
