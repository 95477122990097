import React, { useState, useEffect } from 'react';
import { SpaceBetween, Cards } from '@amzn/awsui-components-react/polaris';
import CandidateInfo from './components/CandidateInfo';
import WarningModal from './components/WarningModal';
import { usePapiProfile } from '@/api/amzn-people';
import {
  Nullable,
  PanelReviewSessionCommentItem,
  PanelReviewSessionResource,
  PanelReviewSessionVoteItem,
} from '@/models';
import { PanelReviewSessionVote, PanelReviewState } from '@/api/API';
import { useAppContext } from '@/contexts';
import {
  usePanelReviewSessionCommentRecords,
  usePanelReviewSessionVoteRecords,
  usePanelReviewSession,
  usePanelReviewSessionActions,
} from '@/api/panel-review';
import { Owner, REVIEW_SESSION_REFRESH_SECONDS, Reviewer } from './config';
import { PANEL_REVIEW_STATE_ORDER } from '@/common/constants';
import { PanelReviewSessionsPage } from '@/common/pages';
import useNavigator from '@/common/hooks/use-navigator';
import VotingSection from './components/VotingSection';
import SessionInfo from './components/SessionInfo';
import CommentList from '../PanelReviewComment/CommentList';

const ViewPanelReviewSession = ({ panelReviewSession }) => {
  const [localReviewSessionData] = useState<PanelReviewSessionResource>(panelReviewSession);
  const [initialSessionState, setInitialSessionState] = useState<PanelReviewState>(localReviewSessionData.sessionState);
  const [reviewSessionState, setReviewSessionState] = useState(localReviewSessionData.sessionState);
  const [reviewers, setReviewers] = useState<Array<Reviewer>>([]);
  const [isReviewersLoading, setIsReviewersLoading] = useState(true);
  const [isSkipLoading, setIsSkipLoading] = useState(true);
  const [skipReviewer, setSkipReviewer] = useState<Reviewer>({ alias: '', firstName: '', lastName: '' });
  const [missingReviewerVotes, setMissingReviewerVotes] = useState<Array<Reviewer>>([]);
  const [owner, setOwner] = useState<Owner>({ alias: '', firstName: '', lastName: '' });
  const [userVote, setUserVote] = useState({ inclined: null });
  const [inclinedVote, setInclinedVote] = useState<PanelReviewSessionVote>();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const { reviewSessionActions } = usePanelReviewSessionActions(localReviewSessionData.id);
  const { getPanelReviewSessionState } = usePanelReviewSession(null);
  const { listVotesBySessionId, onCreate, onUpdate } = usePanelReviewSessionVoteRecords();
  const [sessionVotes, setSessionVotes] = useState<Map<PanelReviewState, Map<string, PanelReviewSessionVoteItem>>>(
    new Map()
  );
  const { listCommentsBySessionId, isMutating } = usePanelReviewSessionCommentRecords();
  const [comments, setComments] = useState<Array<PanelReviewSessionCommentItem>>([]);
  const { user: candidateProfile } = usePapiProfile(localReviewSessionData.candidateAlias);
  const { getPapiProfile } = usePapiProfile();
  const { currentUser, spoofUser } = useAppContext();
  const { goToPage } = useNavigator();
  const user = spoofUser?.alias || currentUser?.alias;

  const isOwner = user === localReviewSessionData.ownerAlias;

  const handleSetVotes = (results: Nullable<Array<PanelReviewSessionVoteItem>>) => {
    const sessionVotesMap = new Map<PanelReviewState, Map<string, PanelReviewSessionVoteItem>>();
    if (results) {
      results.forEach((vote) => {
        if (!sessionVotesMap.has(vote.voteStage)) {
          sessionVotesMap.set(vote.voteStage, new Map());
        }
        sessionVotesMap.get(vote.voteStage)?.set(vote.alias, vote);
      });
    }
    setSessionVotes(sessionVotesMap);
  };

  const handleVoteRefresh = () => {
    void listVotesBySessionId({
      panelReview: localReviewSessionData.id,
      alias: user,
      state: localReviewSessionData.sessionState,
    }).then((results) => {
      handleSetVotes(results);
    });
  };

  const handleCommentRefresh = () => {
    void listCommentsBySessionId({
      panelReviewId: localReviewSessionData.id,
      alias: user!,
    }).then((results) => {
      setComments(results);
    });
  };

  useEffect(() => {
    if (user) {
      const reviewerProfiles: Reviewer[] = [];

      let reviewerPapiCount = 0;

      localReviewSessionData.panelReviewers.forEach((reviewer) => {
        void getPapiProfile(reviewer.alias).then((result) => {
          reviewerProfiles.push({
            alias: reviewer.alias,
            firstName: `${result?.firstName}`,
            lastName: `${result?.lastName}`,
          });
          reviewerPapiCount += 1;
          if (reviewerPapiCount === localReviewSessionData.panelReviewers.length) {
            setReviewers(
              reviewerProfiles.sort((a, b) => {
                return (a?.alias ?? '').localeCompare(b?.alias ?? '');
              })
            );
            setIsReviewersLoading(false);
          }
        });
      });

      void getPapiProfile(localReviewSessionData.areaLeadAlias).then((result) => {
        setSkipReviewer({
          alias: localReviewSessionData.areaLeadAlias,
          firstName: `${result?.firstName}`,
          lastName: `${result?.lastName}`,
        });
        setIsSkipLoading(false);
      });

      const ownerProfile = getPapiProfile(localReviewSessionData.ownerAlias);

      void ownerProfile.then((result) => {
        setOwner({
          alias: localReviewSessionData.ownerAlias,
          firstName: `${result?.firstName}`,
          lastName: `${result?.lastName}`,
        });
      });

      void listVotesBySessionId({
        panelReview: localReviewSessionData.id,
        alias: user,
        state: localReviewSessionData.sessionState,
      }).then((results) => {
        handleSetVotes(results);
      });

      void listCommentsBySessionId({
        panelReviewId: localReviewSessionData.id,
        alias: user,
      }).then((results) => {
        setComments(results);
      });

      const pollInterval = setInterval(() => {
        void listVotesBySessionId({
          panelReview: localReviewSessionData.id,
          alias: user,
          state: initialSessionState,
        }).then((results) => {
          handleSetVotes(results);
        });

        void listCommentsBySessionId({
          panelReviewId: localReviewSessionData.id,
          alias: user,
        }).then((results) => {
          setComments(results);
        });

        if (!isOwner) {
          void getPanelReviewSessionState(localReviewSessionData.id).then((state) => {
            if (state && state.sessionState && initialSessionState !== state.sessionState) {
              setReviewSessionState(state.sessionState);
              setInitialSessionState(state.sessionState);
            }
          });
        }
      }, REVIEW_SESSION_REFRESH_SECONDS * 1000);

      return () => {
        clearInterval(pollInterval);
      };
    }
    return () => {};
  }, [
    getPanelReviewSessionState,
    getPapiProfile,
    initialSessionState,
    isOwner,
    listCommentsBySessionId,
    listVotesBySessionId,
    localReviewSessionData,
    user,
  ]);

  const handleSaveStateChange = async (newState) => {
    if (initialSessionState !== newState) {
      let runUpdate = true;

      const revertState =
        PANEL_REVIEW_STATE_ORDER.indexOf(newState) < PANEL_REVIEW_STATE_ORDER.indexOf(initialSessionState);

      let allReviewers;

      if (!revertState && initialSessionState !== PanelReviewState.SCHEDULED) {
        if (newState === PanelReviewState.COMPLETE) {
          allReviewers = [skipReviewer];
        } else {
          allReviewers = reviewers.concat(skipReviewer);
        }

        const missingVotes: Reviewer[] = [];

        allReviewers.forEach((reviewer) => {
          if (!sessionVotes.get(initialSessionState)?.get(reviewer.alias)) {
            missingVotes.push(reviewer);
          }
        });

        if (missingVotes.length > 0) {
          setMissingReviewerVotes(missingVotes);
          runUpdate = false;
          setShowWarningModal(true);
          return;
        }
      }

      if (runUpdate) {
        const updateResult = await reviewSessionActions.updateState({
          sessionState: newState,
        });
        if (updateResult && updateResult.sessionState === newState) {
          if (newState === PanelReviewState.COMPLETE) goToPage(PanelReviewSessionsPage);
          setReviewSessionState(updateResult.sessionState);
          setInitialSessionState(updateResult.sessionState);
        }
      }
    }
  };

  const handleModalDismiss = () => {
    setShowWarningModal(false);
    setReviewSessionState(initialSessionState);
  };

  const handleStateChange = (newState) => {
    setReviewSessionState(newState);
  };

  const handleVote = (voteType, value) => {
    if (value === 'Yes') {
      setInclinedVote(PanelReviewSessionVote.YES);
    } else if (value === 'No') {
      setInclinedVote(PanelReviewSessionVote.NO);
    }
    setUserVote((prevVote) => ({ ...prevVote, [voteType]: value }));
  };

  const handleVoteSave = () => {
    if (inclinedVote) {
      if (
        sessionVotes.has(reviewSessionState) &&
        sessionVotes.get(reviewSessionState)?.has(user!) &&
        sessionVotes.get(reviewSessionState)?.get(user!)?.vote &&
        sessionVotes.get(reviewSessionState)?.get(user!)?.vote !== inclinedVote
      ) {
        const voteId = sessionVotes.get(reviewSessionState)?.get(user!)?.id;
        void onUpdate({
          id: voteId!,
          vote: inclinedVote,
        }).then(() => handleVoteRefresh());
      } else if (
        !sessionVotes.has(reviewSessionState) ||
        (sessionVotes.has(reviewSessionState) && !sessionVotes.get(reviewSessionState)?.has(user!)) ||
        (sessionVotes.has(reviewSessionState) &&
          sessionVotes.get(reviewSessionState)?.has(user!) &&
          !sessionVotes.get(reviewSessionState)?.get(user!)?.vote &&
          localReviewSessionData.panelReviewers.map((reviewer) => {
            return reviewer.alias === user;
          }))
      ) {
        void onCreate({
          panelReview: localReviewSessionData.id,
          reviewerAlias: user!,
          vote: inclinedVote,
          voteStage: reviewSessionState,
        }).then(() => handleVoteRefresh());
      }
    }
  };
  const items = [
    {
      header: 'Candidate Information',
      content: <CandidateInfo candidateProfile={candidateProfile} manager={owner} />,
    },
    {
      header: 'Session Information',
      content: (
        <SessionInfo
          panelReviewSession={localReviewSessionData}
          isOwner={isOwner}
          owner={owner}
          initialSessionState={initialSessionState}
          sessionState={reviewSessionState}
          onStateChange={handleStateChange}
          onSaveStateChange={handleSaveStateChange}
        />
      ),
    },
    {
      header: 'Comments',
      content: (
        <CommentList
          comments={comments}
          isCommentsLoading={isMutating}
          panelReviewId={localReviewSessionData.id}
          voteStage={initialSessionState}
          commentRefresh={handleCommentRefresh}
        />
      ),
    },
    {
      header: isOwner ? 'Reviewer Votes' : 'Session Voting',
      content: (
        <VotingSection
          user={user ?? ''}
          isOwner={isOwner}
          sessionState={initialSessionState}
          skipReviewer={skipReviewer}
          reviewers={reviewers}
          sessionVotes={sessionVotes}
          userVote={userVote}
          isReviewersLoading={isReviewersLoading}
          isSkipLoading={isSkipLoading}
          onVote={handleVote}
          onSave={handleVoteSave}
          onRefresh={handleVoteRefresh}
        />
      ),
    },
  ];

  return (
    <>
      <WarningModal
        visible={showWarningModal}
        onDismiss={handleModalDismiss}
        missingReviewerVotes={missingReviewerVotes}
        reviewState={initialSessionState}
        futureState={reviewSessionState}
      />
      <SpaceBetween direction="vertical" size="l">
        <Cards
          items={items}
          cardDefinition={{
            header: (item) => item.header,
            sections: [
              {
                id: 'content',
                content: (item) => item.content,
              },
            ],
          }}
          cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
        />
      </SpaceBetween>
    </>
  );
};

export default ViewPanelReviewSession;
