/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateWorkSummaryInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt?: string | null,
  alias: string,
  candidatePathId?: string | null,
  categoryIds?: Array< string > | null,
  baselineRoleDimensionIds?: Array< string > | null,
  content: string,
  contentVersion?: number | null,
  leadershipPrinciples?: Array< string > | null,
  status?: WorkSummaryStatus | null,
  statusReason?: WorkSummaryStatusReason | null,
  submittedAt?: string | null,
  title: string,
  type: WorkSummaryType,
  _version?: number | null,
};

export enum WorkSummaryStatus {
  DRAFT = "DRAFT",
  PEER_REVIEW = "PEER_REVIEW",
  MANAGER_REVIEW = "MANAGER_REVIEW",
  MANAGER_PEER_REVIEW = "MANAGER_PEER_REVIEW",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  DELETED = "DELETED",
}


export enum WorkSummaryStatusReason {
  RETURNED_FOR_UPDATES = "RETURNED_FOR_UPDATES",
  CONTENT_NOT_USABLE = "CONTENT_NOT_USABLE",
  MANAGER_INITIAL_APPROVAL = "MANAGER_INITIAL_APPROVAL",
  MANAGER_FINAL_APPROVAL = "MANAGER_FINAL_APPROVAL",
  ADMIN_STATUS_CHANGE = "ADMIN_STATUS_CHANGE",
  PROMO_PATH_ASSIGNMENT_CHANGE = "PROMO_PATH_ASSIGNMENT_CHANGE",
  PEER_REVIEW_COMPLETE = "PEER_REVIEW_COMPLETE",
}


export enum WorkSummaryType {
  CUSTOMER = "CUSTOMER",
  AMAZON = "AMAZON",
}


export type ModelWorkSummaryConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  candidatePathId?: ModelIDInput | null,
  categoryIds?: ModelIDInput | null,
  baselineRoleDimensionIds?: ModelIDInput | null,
  content?: ModelStringInput | null,
  contentVersion?: ModelIntInput | null,
  leadershipPrinciples?: ModelStringInput | null,
  status?: ModelWorkSummaryStatusInput | null,
  statusReason?: ModelWorkSummaryStatusReasonInput | null,
  submittedAt?: ModelStringInput | null,
  title?: ModelStringInput | null,
  type?: ModelWorkSummaryTypeInput | null,
  and?: Array< ModelWorkSummaryConditionInput | null > | null,
  or?: Array< ModelWorkSummaryConditionInput | null > | null,
  not?: ModelWorkSummaryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelWorkSummaryStatusInput = {
  eq?: WorkSummaryStatus | null,
  ne?: WorkSummaryStatus | null,
};

export type ModelWorkSummaryStatusReasonInput = {
  eq?: WorkSummaryStatusReason | null,
  ne?: WorkSummaryStatusReason | null,
};

export type ModelWorkSummaryTypeInput = {
  eq?: WorkSummaryType | null,
  ne?: WorkSummaryType | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type WorkSummary = {
  __typename: "WorkSummary",
  createdAt: string,
  id: string,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt: string,
  alias: string,
  candidatePathId?: string | null,
  categoryIds?: Array< string > | null,
  baselineRoleDimensionIds?: Array< string > | null,
  content: string,
  contentVersion?: number | null,
  leadershipPrinciples?: Array< string > | null,
  status?: WorkSummaryStatus | null,
  statusReason?: WorkSummaryStatusReason | null,
  submittedAt?: string | null,
  title: string,
  type: WorkSummaryType,
  candidatePath?: PromoPath | null,
  categories?: ModelCategoryConnection | null,
  baselineRoleDimension?: ModelBaselineRoleDimensionConnection | null,
  comments?: ModelCommentConnection | null,
  documents?: ModelWorkSummaryFilesConnection | null,
  peerReviews?: ModelPeerReviewConnection | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export type PromoPath = {
  __typename: "PromoPath",
  createdAt: string,
  id: string,
  owner?: string | null,
  updatedAt: string,
  allowAttachments?: boolean | null,
  categoryIds?: Array< string > | null,
  name?: string | null,
  pathType: PathDefinitionType,
  role?: string | null,
  startLevel?: number | null,
  status: PromoPathStatus,
  suggestedLPs?: Array< string > | null,
  targetLevel?: number | null,
  candidate?: string | null,
  targetDate?: string | null,
  totalCompleted?: number | null,
  totalRequired?: number | null,
  manager?: string | null,
  templateId?: string | null,
  categories?: ModelCategoryConnection | null,
  descendants?: ModelPromoPathConnection | null,
  peerReviews?: ModelPeerReviewConnection | null,
  template?: PromoPath | null,
  workSummaries?: ModelWorkSummaryConnection | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export enum PathDefinitionType {
  CANDIDATE = "CANDIDATE",
  MANAGER = "MANAGER",
  ORGANIZATION = "ORGANIZATION",
  SYSTEM = "SYSTEM",
}


export enum PromoPathStatus {
  ACTIVE = "ACTIVE",
  ASSIGNED = "ASSIGNED",
  COMPLETED = "COMPLETED",
  DELETED = "DELETED",
}


export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
};

export type Category = {
  __typename: "Category",
  createdAt: string,
  id: string,
  owner?: string | null,
  updatedAt: string,
  description: string,
  isDefault?: string | null,
  name: string,
  role: string,
  status: CategoryStatus,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export enum CategoryStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}


export type ModelPromoPathConnection = {
  __typename: "ModelPromoPathConnection",
  items:  Array<PromoPath | null >,
  nextToken?: string | null,
};

export type ModelPeerReviewConnection = {
  __typename: "ModelPeerReviewConnection",
  items:  Array<PeerReview | null >,
  nextToken?: string | null,
};

export type PeerReview = {
  __typename: "PeerReview",
  createdAt: string,
  id: string,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt: string,
  content?: string | null,
  requiredBy: string,
  reviewedContentVersion?: number | null,
  reviewStatus?: PeerReviewStatus | null,
  reviewType: PeerReviewType,
  candidatePathId?: string | null,
  candidatePath?: PromoPath | null,
  reviewerAlias: string,
  reviewer: UserProfile,
  workSummaryId?: string | null,
  workSummary?: WorkSummary | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export enum PeerReviewStatus {
  REQUESTED = "REQUESTED",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  RETURNED_FOR_REVISION = "RETURNED_FOR_REVISION",
  DELETED = "DELETED",
}


export enum PeerReviewType {
  CANDIDATE = "CANDIDATE",
  MANAGER = "MANAGER",
}


export type UserProfile = {
  __typename: "UserProfile",
  alias: string,
  createdAt: string,
  owner?: string | null,
  updatedAt: string,
  email: string,
  hasLoggedIn?: boolean | null,
  hireDate?: string | null,
  identityId?: string | null,
  isManager: boolean,
  jobLevel: number,
  jobTitle?: string | null,
  manager: string,
  managerProfile?: UserProfile | null,
  name?: string | null,
  orgName?: string | null,
  personId?: string | null,
  preferences?: UserPreferences | null,
  status?: UserProfileStatus | null,
  sharedFiles?: ModelSharedUserFilesConnection | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export type UserPreferences = {
  __typename: "UserPreferences",
  landingPage?: string | null,
  visualMode?: VisualMode | null,
  density?: Density | null,
};

export enum VisualMode {
  AUTO = "AUTO",
  DARK = "DARK",
  LIGHT = "LIGHT",
}


export enum Density {
  COMFORTABLE = "COMFORTABLE",
  COMPACT = "COMPACT",
}


export enum UserProfileStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DELETED = "DELETED",
}


export type ModelSharedUserFilesConnection = {
  __typename: "ModelSharedUserFilesConnection",
  items:  Array<SharedUserFiles | null >,
  nextToken?: string | null,
};

export type SharedUserFiles = {
  __typename: "SharedUserFiles",
  createdAt: string,
  id: string,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt: string,
  fileRecordID: string,
  fileRecord: FileRecord,
  userProfileAlias: string,
  userProfile: UserProfile,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type FileRecord = {
  __typename: "FileRecord",
  createdAt: string,
  id: string,
  owner?: string | null,
  updatedAt: string,
  fileDateModified: number,
  fileName: string,
  fileSizeBytes: number,
  s3Key: string,
  storageIdKey: string,
  status: FileRecordStatus,
  workSummaries?: ModelWorkSummaryFilesConnection | null,
  users?: ModelSharedUserFilesConnection | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export enum FileRecordStatus {
  ACTIVE = "ACTIVE",
  HIDDEN = "HIDDEN",
  DELETED = "DELETED",
}


export type ModelWorkSummaryFilesConnection = {
  __typename: "ModelWorkSummaryFilesConnection",
  items:  Array<WorkSummaryFiles | null >,
  nextToken?: string | null,
};

export type WorkSummaryFiles = {
  __typename: "WorkSummaryFiles",
  createdAt: string,
  id: string,
  owner?: string | null,
  updatedAt: string,
  fileRecordID: string,
  fileRecord: FileRecord,
  workSummaryID: string,
  workSummary: WorkSummary,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export type ModelWorkSummaryConnection = {
  __typename: "ModelWorkSummaryConnection",
  items:  Array<WorkSummary | null >,
  nextToken?: string | null,
};

export type ModelBaselineRoleDimensionConnection = {
  __typename: "ModelBaselineRoleDimensionConnection",
  items:  Array<BaselineRoleDimension | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type BaselineRoleDimension = {
  __typename: "BaselineRoleDimension",
  createdAt: string,
  id: string,
  updatedAt: string,
  jobLevel: number,
  title: string,
  description: string,
  jobRole: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
  owner?: string | null,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items:  Array<Comment | null >,
  nextToken?: string | null,
};

export type Comment = {
  __typename: "Comment",
  createdAt: string,
  id: string,
  owner?: string | null,
  updatedAt: string,
  alias: string,
  content: string,
  status: CommentStatus,
  type: CommentType,
  workSummaryId?: string | null,
  author: UserProfile,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export enum CommentStatus {
  ACTIVE = "ACTIVE",
  RESTRICTED = "RESTRICTED",
  DELETED = "DELETED",
}


export enum CommentType {
  CANDIDATE = "CANDIDATE",
  PEER_REVIEW = "PEER_REVIEW",
}


export type UpdateWorkSummaryInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt?: string | null,
  alias?: string | null,
  candidatePathId?: string | null,
  categoryIds?: Array< string > | null,
  baselineRoleDimensionIds?: Array< string > | null,
  content?: string | null,
  contentVersion?: number | null,
  leadershipPrinciples?: Array< string > | null,
  status?: WorkSummaryStatus | null,
  statusReason?: WorkSummaryStatusReason | null,
  submittedAt?: string | null,
  title?: string | null,
  type?: WorkSummaryType | null,
  _version?: number | null,
};

export type DeleteWorkSummaryInput = {
  id: string,
  _version?: number | null,
};

export type CreatePeerReviewInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt?: string | null,
  content?: string | null,
  requiredBy: string,
  reviewedContentVersion?: number | null,
  reviewStatus?: PeerReviewStatus | null,
  reviewType: PeerReviewType,
  candidatePathId?: string | null,
  reviewerAlias: string,
  workSummaryId?: string | null,
  _version?: number | null,
};

export type ModelPeerReviewConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  content?: ModelStringInput | null,
  requiredBy?: ModelStringInput | null,
  reviewedContentVersion?: ModelIntInput | null,
  reviewStatus?: ModelPeerReviewStatusInput | null,
  reviewType?: ModelPeerReviewTypeInput | null,
  candidatePathId?: ModelIDInput | null,
  reviewerAlias?: ModelStringInput | null,
  workSummaryId?: ModelIDInput | null,
  and?: Array< ModelPeerReviewConditionInput | null > | null,
  or?: Array< ModelPeerReviewConditionInput | null > | null,
  not?: ModelPeerReviewConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPeerReviewStatusInput = {
  eq?: PeerReviewStatus | null,
  ne?: PeerReviewStatus | null,
};

export type ModelPeerReviewTypeInput = {
  eq?: PeerReviewType | null,
  ne?: PeerReviewType | null,
};

export type UpdatePeerReviewInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt?: string | null,
  content?: string | null,
  requiredBy?: string | null,
  reviewedContentVersion?: number | null,
  reviewStatus?: PeerReviewStatus | null,
  reviewType?: PeerReviewType | null,
  candidatePathId?: string | null,
  reviewerAlias?: string | null,
  workSummaryId?: string | null,
  _version?: number | null,
};

export type DeletePeerReviewInput = {
  id: string,
  _version?: number | null,
};

export type CreateFileRecordInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  fileDateModified: number,
  fileName: string,
  fileSizeBytes: number,
  s3Key: string,
  storageIdKey: string,
  status: FileRecordStatus,
  _version?: number | null,
};

export type ModelFileRecordConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  fileDateModified?: ModelIntInput | null,
  fileName?: ModelStringInput | null,
  fileSizeBytes?: ModelIntInput | null,
  s3Key?: ModelStringInput | null,
  storageIdKey?: ModelStringInput | null,
  status?: ModelFileRecordStatusInput | null,
  and?: Array< ModelFileRecordConditionInput | null > | null,
  or?: Array< ModelFileRecordConditionInput | null > | null,
  not?: ModelFileRecordConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFileRecordStatusInput = {
  eq?: FileRecordStatus | null,
  ne?: FileRecordStatus | null,
};

export type UpdateFileRecordInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  updatedAt?: string | null,
  fileDateModified?: number | null,
  fileName?: string | null,
  fileSizeBytes?: number | null,
  s3Key?: string | null,
  storageIdKey?: string | null,
  status?: FileRecordStatus | null,
  _version?: number | null,
};

export type DeleteFileRecordInput = {
  id: string,
  _version?: number | null,
};

export type CreatePromoPathInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  allowAttachments?: boolean | null,
  categoryIds?: Array< string > | null,
  name?: string | null,
  pathType: PathDefinitionType,
  role?: string | null,
  startLevel?: number | null,
  status: PromoPathStatus,
  suggestedLPs?: Array< string > | null,
  targetLevel?: number | null,
  candidate?: string | null,
  targetDate?: string | null,
  totalCompleted?: number | null,
  totalRequired?: number | null,
  manager?: string | null,
  templateId?: string | null,
  _version?: number | null,
};

export type ModelPromoPathConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  allowAttachments?: ModelBooleanInput | null,
  categoryIds?: ModelIDInput | null,
  name?: ModelStringInput | null,
  pathType?: ModelPathDefinitionTypeInput | null,
  role?: ModelStringInput | null,
  startLevel?: ModelIntInput | null,
  status?: ModelPromoPathStatusInput | null,
  suggestedLPs?: ModelStringInput | null,
  targetLevel?: ModelIntInput | null,
  candidate?: ModelStringInput | null,
  targetDate?: ModelStringInput | null,
  totalCompleted?: ModelIntInput | null,
  totalRequired?: ModelIntInput | null,
  manager?: ModelStringInput | null,
  templateId?: ModelIDInput | null,
  and?: Array< ModelPromoPathConditionInput | null > | null,
  or?: Array< ModelPromoPathConditionInput | null > | null,
  not?: ModelPromoPathConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPathDefinitionTypeInput = {
  eq?: PathDefinitionType | null,
  ne?: PathDefinitionType | null,
};

export type ModelPromoPathStatusInput = {
  eq?: PromoPathStatus | null,
  ne?: PromoPathStatus | null,
};

export type UpdatePromoPathInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  updatedAt?: string | null,
  allowAttachments?: boolean | null,
  categoryIds?: Array< string > | null,
  name?: string | null,
  pathType?: PathDefinitionType | null,
  role?: string | null,
  startLevel?: number | null,
  status?: PromoPathStatus | null,
  suggestedLPs?: Array< string > | null,
  targetLevel?: number | null,
  candidate?: string | null,
  targetDate?: string | null,
  totalCompleted?: number | null,
  totalRequired?: number | null,
  manager?: string | null,
  templateId?: string | null,
  _version?: number | null,
};

export type DeletePromoPathInput = {
  id: string,
  _version?: number | null,
};

export type CreateCategoryInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  description: string,
  isDefault?: string | null,
  name: string,
  role: string,
  status: CategoryStatus,
  _version?: number | null,
};

export type ModelCategoryConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  isDefault?: ModelStringInput | null,
  name?: ModelStringInput | null,
  role?: ModelStringInput | null,
  status?: ModelCategoryStatusInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCategoryStatusInput = {
  eq?: CategoryStatus | null,
  ne?: CategoryStatus | null,
};

export type UpdateCategoryInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  updatedAt?: string | null,
  description?: string | null,
  isDefault?: string | null,
  name?: string | null,
  role?: string | null,
  status?: CategoryStatus | null,
  _version?: number | null,
};

export type DeleteCategoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserProfileInput = {
  alias: string,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  email: string,
  hasLoggedIn?: boolean | null,
  hireDate?: string | null,
  identityId?: string | null,
  isManager: boolean,
  jobLevel: number,
  jobTitle?: string | null,
  manager: string,
  name?: string | null,
  orgName?: string | null,
  personId?: string | null,
  preferences?: UserPreferencesInput | null,
  status?: UserProfileStatus | null,
  _version?: number | null,
};

export type UserPreferencesInput = {
  landingPage?: string | null,
  visualMode?: VisualMode | null,
  density?: Density | null,
};

export type ModelUserProfileConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  email?: ModelStringInput | null,
  hasLoggedIn?: ModelBooleanInput | null,
  hireDate?: ModelStringInput | null,
  identityId?: ModelStringInput | null,
  isManager?: ModelBooleanInput | null,
  jobLevel?: ModelIntInput | null,
  jobTitle?: ModelStringInput | null,
  manager?: ModelStringInput | null,
  name?: ModelStringInput | null,
  orgName?: ModelStringInput | null,
  personId?: ModelStringInput | null,
  status?: ModelUserProfileStatusInput | null,
  and?: Array< ModelUserProfileConditionInput | null > | null,
  or?: Array< ModelUserProfileConditionInput | null > | null,
  not?: ModelUserProfileConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserProfileStatusInput = {
  eq?: UserProfileStatus | null,
  ne?: UserProfileStatus | null,
};

export type UpdateUserProfileInput = {
  alias: string,
  createdAt?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  email?: string | null,
  hasLoggedIn?: boolean | null,
  hireDate?: string | null,
  identityId?: string | null,
  isManager?: boolean | null,
  jobLevel?: number | null,
  jobTitle?: string | null,
  manager?: string | null,
  name?: string | null,
  orgName?: string | null,
  personId?: string | null,
  preferences?: UserPreferencesInput | null,
  status?: UserProfileStatus | null,
  _version?: number | null,
};

export type DeleteUserProfileInput = {
  alias: string,
  _version?: number | null,
};

export type CreateWorkSummaryFilesInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  fileRecordID: string,
  workSummaryID: string,
  _version?: number | null,
};

export type ModelWorkSummaryFilesConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  fileRecordID?: ModelIDInput | null,
  workSummaryID?: ModelIDInput | null,
  and?: Array< ModelWorkSummaryFilesConditionInput | null > | null,
  or?: Array< ModelWorkSummaryFilesConditionInput | null > | null,
  not?: ModelWorkSummaryFilesConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateWorkSummaryFilesInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  updatedAt?: string | null,
  fileRecordID?: string | null,
  workSummaryID?: string | null,
  _version?: number | null,
};

export type DeleteWorkSummaryFilesInput = {
  id: string,
  _version?: number | null,
};

export type CreateSharedUserFilesInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt?: string | null,
  fileRecordID: string,
  userProfileAlias: string,
  _version?: number | null,
};

export type ModelSharedUserFilesConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  fileRecordID?: ModelIDInput | null,
  userProfileAlias?: ModelStringInput | null,
  and?: Array< ModelSharedUserFilesConditionInput | null > | null,
  or?: Array< ModelSharedUserFilesConditionInput | null > | null,
  not?: ModelSharedUserFilesConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateSharedUserFilesInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  coOwners?: Array< string > | null,
  updatedAt?: string | null,
  fileRecordID?: string | null,
  userProfileAlias?: string | null,
  _version?: number | null,
};

export type DeleteSharedUserFilesInput = {
  id: string,
  _version?: number | null,
};

export type CreateCommentInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  alias: string,
  content: string,
  status: CommentStatus,
  type: CommentType,
  workSummaryId?: string | null,
  _version?: number | null,
};

export type ModelCommentConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  content?: ModelStringInput | null,
  status?: ModelCommentStatusInput | null,
  type?: ModelCommentTypeInput | null,
  workSummaryId?: ModelIDInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCommentStatusInput = {
  eq?: CommentStatus | null,
  ne?: CommentStatus | null,
};

export type ModelCommentTypeInput = {
  eq?: CommentType | null,
  ne?: CommentType | null,
};

export type UpdateCommentInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  updatedAt?: string | null,
  alias?: string | null,
  content?: string | null,
  status?: CommentStatus | null,
  type?: CommentType | null,
  workSummaryId?: string | null,
  _version?: number | null,
};

export type DeleteCommentInput = {
  id: string,
  _version?: number | null,
};

export type CreateAuditTrailsInput = {
  id?: string | null,
  changedFields?: Array< string > | null,
  changeJustification?: string | null,
  humanReadableLog: Array< string >,
  modifiedBy?: string | null,
  rawNewRecord: string,
  rawOldRecord?: string | null,
  recordId: string,
  recordType: AuditTrailRecordType,
  _version?: number | null,
};

export enum AuditTrailRecordType {
  WORKSUMMARY = "WORKSUMMARY",
  PEERREVIEW = "PEERREVIEW",
  COMMENT = "COMMENT",
  PROMOPATH = "PROMOPATH",
}


export type ModelAuditTrailsConditionInput = {
  changedFields?: ModelStringInput | null,
  changeJustification?: ModelStringInput | null,
  humanReadableLog?: ModelStringInput | null,
  modifiedBy?: ModelStringInput | null,
  rawNewRecord?: ModelStringInput | null,
  rawOldRecord?: ModelStringInput | null,
  recordId?: ModelIDInput | null,
  recordType?: ModelAuditTrailRecordTypeInput | null,
  and?: Array< ModelAuditTrailsConditionInput | null > | null,
  or?: Array< ModelAuditTrailsConditionInput | null > | null,
  not?: ModelAuditTrailsConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelAuditTrailRecordTypeInput = {
  eq?: AuditTrailRecordType | null,
  ne?: AuditTrailRecordType | null,
};

export type AuditTrails = {
  __typename: "AuditTrails",
  id: string,
  changedFields?: Array< string > | null,
  changeJustification?: string | null,
  humanReadableLog: Array< string >,
  modifiedBy?: string | null,
  rawNewRecord: string,
  rawOldRecord?: string | null,
  recordId: string,
  recordType: AuditTrailRecordType,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAuditTrailsInput = {
  id: string,
  changedFields?: Array< string > | null,
  changeJustification?: string | null,
  humanReadableLog?: Array< string > | null,
  modifiedBy?: string | null,
  rawNewRecord?: string | null,
  rawOldRecord?: string | null,
  recordId?: string | null,
  recordType?: AuditTrailRecordType | null,
  _version?: number | null,
};

export type DeleteAuditTrailsInput = {
  id: string,
  _version?: number | null,
};

export type CreatePromoReadinessRecordInput = {
  alias: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  id?: string | null,
  certList?: Array< string > | null,
  techPillar?: Array< string > | null,
  tfcMembership: string,
  rating: string,
  feedbackProviders?: Array< string > | null,
  otherContributions: string,
  _version?: number | null,
};

export type ModelPromoReadinessRecordConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  certList?: ModelStringInput | null,
  techPillar?: ModelStringInput | null,
  tfcMembership?: ModelStringInput | null,
  rating?: ModelStringInput | null,
  feedbackProviders?: ModelStringInput | null,
  otherContributions?: ModelStringInput | null,
  and?: Array< ModelPromoReadinessRecordConditionInput | null > | null,
  or?: Array< ModelPromoReadinessRecordConditionInput | null > | null,
  not?: ModelPromoReadinessRecordConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
};

export type PromoReadinessRecord = {
  __typename: "PromoReadinessRecord",
  alias: string,
  createdAt: string,
  updatedAt: string,
  id: string,
  certList?: Array< string > | null,
  techPillar?: Array< string > | null,
  tfcMembership: string,
  rating: string,
  feedbackProviders?: Array< string > | null,
  otherContributions: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
  owner?: string | null,
};

export type UpdatePromoReadinessRecordInput = {
  alias: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  id?: string | null,
  certList?: Array< string > | null,
  techPillar?: Array< string > | null,
  tfcMembership?: string | null,
  rating?: string | null,
  feedbackProviders?: Array< string > | null,
  otherContributions?: string | null,
  _version?: number | null,
};

export type DeletePromoReadinessRecordInput = {
  alias: string,
  _version?: number | null,
};

export type CreateDocumentReviewSessionRecordInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
  ownerAlias: string,
  candidateAlias: string,
  coOwners?: Array< string > | null,
  workdocsLink: string,
  chimeLink?: string | null,
  sessionStart: string,
  sessionState: DocumentReviewSessionState,
  overrideReason?: string | null,
  _version?: number | null,
};

export enum DocumentReviewSessionState {
  SCHEDULED = "SCHEDULED",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
}


export type ModelDocumentReviewSessionRecordConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  ownerAlias?: ModelStringInput | null,
  candidateAlias?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
  workdocsLink?: ModelStringInput | null,
  chimeLink?: ModelStringInput | null,
  sessionStart?: ModelStringInput | null,
  sessionState?: ModelDocumentReviewSessionStateInput | null,
  overrideReason?: ModelStringInput | null,
  and?: Array< ModelDocumentReviewSessionRecordConditionInput | null > | null,
  or?: Array< ModelDocumentReviewSessionRecordConditionInput | null > | null,
  not?: ModelDocumentReviewSessionRecordConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelDocumentReviewSessionStateInput = {
  eq?: DocumentReviewSessionState | null,
  ne?: DocumentReviewSessionState | null,
};

export type DocumentReviewSessionRecord = {
  __typename: "DocumentReviewSessionRecord",
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
  ownerAlias: string,
  candidateAlias: string,
  coOwners?: Array< string > | null,
  workdocsLink: string,
  chimeLink?: string | null,
  sessionStart: string,
  sessionState: DocumentReviewSessionState,
  overrideReason?: string | null,
  documentReviewers?: ModelDocumentReviewSessionToReviewerMappingConnection | null,
  votes?: ModelDocumentReviewSessionVoteRecordConnection | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export type ModelDocumentReviewSessionToReviewerMappingConnection = {
  __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
  items:  Array<DocumentReviewSessionToReviewerMapping | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type DocumentReviewSessionToReviewerMapping = {
  __typename: "DocumentReviewSessionToReviewerMapping",
  id: string,
  documentReviewSessionRecordID: string,
  calibratedDocumentReviewerRecordID: string,
  documentReviewSessionRecord: DocumentReviewSessionRecord,
  calibratedDocumentReviewerRecord: CalibratedDocumentReviewerRecord,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
  coOwners?: string | null,
};

export type CalibratedDocumentReviewerRecord = {
  __typename: "CalibratedDocumentReviewerRecord",
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
  alias: string,
  completedSessions?: number | null,
  documentReviews?: ModelDocumentReviewSessionToReviewerMappingConnection | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export type ModelDocumentReviewSessionVoteRecordConnection = {
  __typename: "ModelDocumentReviewSessionVoteRecordConnection",
  items:  Array<DocumentReviewSessionVoteRecord | null >,
  nextToken?: string | null,
};

export type DocumentReviewSessionVoteRecord = {
  __typename: "DocumentReviewSessionVoteRecord",
  id: string,
  createdAt: string,
  updatedAt: string,
  reviewerAlias: string,
  documentReviewSession: string,
  documentReady?: DocumentReviewSessionVote | null,
  candidateReady?: DocumentReviewSessionVote | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
  owner?: string | null,
};

export enum DocumentReviewSessionVote {
  YES = "YES",
  NO = "NO",
}


export type UpdateDocumentReviewSessionRecordInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
  ownerAlias?: string | null,
  candidateAlias?: string | null,
  coOwners?: Array< string > | null,
  workdocsLink?: string | null,
  chimeLink?: string | null,
  sessionStart?: string | null,
  sessionState?: DocumentReviewSessionState | null,
  overrideReason?: string | null,
  _version?: number | null,
};

export type DeleteDocumentReviewSessionRecordInput = {
  id: string,
  _version?: number | null,
};

export type CreateCalibratedDocumentReviewerRecordInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
  alias: string,
  completedSessions?: number | null,
  _version?: number | null,
};

export type ModelCalibratedDocumentReviewerRecordConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  completedSessions?: ModelIntInput | null,
  and?: Array< ModelCalibratedDocumentReviewerRecordConditionInput | null > | null,
  or?: Array< ModelCalibratedDocumentReviewerRecordConditionInput | null > | null,
  not?: ModelCalibratedDocumentReviewerRecordConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateCalibratedDocumentReviewerRecordInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
  alias?: string | null,
  completedSessions?: number | null,
  _version?: number | null,
};

export type DeleteCalibratedDocumentReviewerRecordInput = {
  id: string,
  _version?: number | null,
};

export type CreateDocumentReviewSessionVoteRecordInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  reviewerAlias: string,
  documentReviewSession: string,
  documentReady?: DocumentReviewSessionVote | null,
  candidateReady?: DocumentReviewSessionVote | null,
  _version?: number | null,
};

export type ModelDocumentReviewSessionVoteRecordConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  reviewerAlias?: ModelStringInput | null,
  documentReviewSession?: ModelIDInput | null,
  documentReady?: ModelDocumentReviewSessionVoteInput | null,
  candidateReady?: ModelDocumentReviewSessionVoteInput | null,
  and?: Array< ModelDocumentReviewSessionVoteRecordConditionInput | null > | null,
  or?: Array< ModelDocumentReviewSessionVoteRecordConditionInput | null > | null,
  not?: ModelDocumentReviewSessionVoteRecordConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
};

export type ModelDocumentReviewSessionVoteInput = {
  eq?: DocumentReviewSessionVote | null,
  ne?: DocumentReviewSessionVote | null,
};

export type UpdateDocumentReviewSessionVoteRecordInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  reviewerAlias?: string | null,
  documentReviewSession?: string | null,
  documentReady?: DocumentReviewSessionVote | null,
  candidateReady?: DocumentReviewSessionVote | null,
  _version?: number | null,
};

export type DeleteDocumentReviewSessionVoteRecordInput = {
  id: string,
  _version?: number | null,
};

export type CreateDocumentReviewSessionToReviewerMappingInput = {
  id?: string | null,
  documentReviewSessionRecordID: string,
  calibratedDocumentReviewerRecordID: string,
  _version?: number | null,
};

export type ModelDocumentReviewSessionToReviewerMappingConditionInput = {
  documentReviewSessionRecordID?: ModelIDInput | null,
  calibratedDocumentReviewerRecordID?: ModelIDInput | null,
  and?: Array< ModelDocumentReviewSessionToReviewerMappingConditionInput | null > | null,
  or?: Array< ModelDocumentReviewSessionToReviewerMappingConditionInput | null > | null,
  not?: ModelDocumentReviewSessionToReviewerMappingConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
};

export type UpdateDocumentReviewSessionToReviewerMappingInput = {
  id: string,
  documentReviewSessionRecordID?: string | null,
  calibratedDocumentReviewerRecordID?: string | null,
  _version?: number | null,
};

export type DeleteDocumentReviewSessionToReviewerMappingInput = {
  id: string,
  _version?: number | null,
};

export type CreatePanelReviewSessionToReviewerMappingInput = {
  id?: string | null,
  panelReviewRecordID: string,
  calibratedPanelReviewerRecordID: string,
  _version?: number | null,
};

export type ModelPanelReviewSessionToReviewerMappingConditionInput = {
  panelReviewRecordID?: ModelIDInput | null,
  calibratedPanelReviewerRecordID?: ModelIDInput | null,
  and?: Array< ModelPanelReviewSessionToReviewerMappingConditionInput | null > | null,
  or?: Array< ModelPanelReviewSessionToReviewerMappingConditionInput | null > | null,
  not?: ModelPanelReviewSessionToReviewerMappingConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
};

export type PanelReviewSessionToReviewerMapping = {
  __typename: "PanelReviewSessionToReviewerMapping",
  id: string,
  panelReviewRecordID: string,
  calibratedPanelReviewerRecordID: string,
  panelReviewRecord: PanelReviewRecord,
  calibratedPanelReviewerRecord: CalibratedPanelReviewerRecord,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  owner?: string | null,
  coOwners?: string | null,
};

export type PanelReviewRecord = {
  __typename: "PanelReviewRecord",
  id: string,
  owner?: string | null,
  coOwners?: Array< string > | null,
  createdAt: string,
  updatedAt: string,
  ownerAlias: string,
  candidateAlias: string,
  areaLeadAlias: string,
  documentReviewSession: string,
  workdocsLink: string,
  chimeLink: string,
  sessionStart: string,
  sessionState: PanelReviewState,
  overrideReason?: string | null,
  panelReviewers?: ModelPanelReviewSessionToReviewerMappingConnection | null,
  votes?: ModelPanelReviewVoteRecordConnection | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export enum PanelReviewState {
  SCHEDULED = "SCHEDULED",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
  INITIAL_VOTE = "INITIAL_VOTE",
  FINAL_VOTE = "FINAL_VOTE",
  FINAL_OUTCOME = "FINAL_OUTCOME",
}


export type ModelPanelReviewSessionToReviewerMappingConnection = {
  __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
  items:  Array<PanelReviewSessionToReviewerMapping | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPanelReviewVoteRecordConnection = {
  __typename: "ModelPanelReviewVoteRecordConnection",
  items:  Array<PanelReviewVoteRecord | null >,
  nextToken?: string | null,
};

export type PanelReviewVoteRecord = {
  __typename: "PanelReviewVoteRecord",
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
  reviewerAlias: string,
  panelReview: string,
  vote: PanelReviewSessionVote,
  voteStage: PanelReviewState,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export enum PanelReviewSessionVote {
  YES = "YES",
  NO = "NO",
}


export type CalibratedPanelReviewerRecord = {
  __typename: "CalibratedPanelReviewerRecord",
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
  alias: string,
  completedSessions?: number | null,
  panelReviews?: ModelPanelReviewSessionToReviewerMappingConnection | null,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export type UpdatePanelReviewSessionToReviewerMappingInput = {
  id: string,
  panelReviewRecordID?: string | null,
  calibratedPanelReviewerRecordID?: string | null,
  _version?: number | null,
};

export type DeletePanelReviewSessionToReviewerMappingInput = {
  id: string,
  _version?: number | null,
};

export type CreatePanelReviewRecordInput = {
  id?: string | null,
  owner?: string | null,
  coOwners?: Array< string > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  ownerAlias: string,
  candidateAlias: string,
  areaLeadAlias: string,
  documentReviewSession: string,
  workdocsLink: string,
  chimeLink: string,
  sessionStart: string,
  sessionState: PanelReviewState,
  overrideReason?: string | null,
  _version?: number | null,
};

export type ModelPanelReviewRecordConditionInput = {
  owner?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  ownerAlias?: ModelStringInput | null,
  candidateAlias?: ModelStringInput | null,
  areaLeadAlias?: ModelStringInput | null,
  documentReviewSession?: ModelIDInput | null,
  workdocsLink?: ModelStringInput | null,
  chimeLink?: ModelStringInput | null,
  sessionStart?: ModelStringInput | null,
  sessionState?: ModelPanelReviewStateInput | null,
  overrideReason?: ModelStringInput | null,
  and?: Array< ModelPanelReviewRecordConditionInput | null > | null,
  or?: Array< ModelPanelReviewRecordConditionInput | null > | null,
  not?: ModelPanelReviewRecordConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPanelReviewStateInput = {
  eq?: PanelReviewState | null,
  ne?: PanelReviewState | null,
};

export type UpdatePanelReviewRecordInput = {
  id: string,
  owner?: string | null,
  coOwners?: Array< string > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  ownerAlias?: string | null,
  candidateAlias?: string | null,
  areaLeadAlias?: string | null,
  documentReviewSession?: string | null,
  workdocsLink?: string | null,
  chimeLink?: string | null,
  sessionStart?: string | null,
  sessionState?: PanelReviewState | null,
  overrideReason?: string | null,
  _version?: number | null,
};

export type DeletePanelReviewRecordInput = {
  id: string,
  _version?: number | null,
};

export type CreateCalibratedPanelReviewerRecordInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
  alias: string,
  completedSessions?: number | null,
  _version?: number | null,
};

export type ModelCalibratedPanelReviewerRecordConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  completedSessions?: ModelIntInput | null,
  and?: Array< ModelCalibratedPanelReviewerRecordConditionInput | null > | null,
  or?: Array< ModelCalibratedPanelReviewerRecordConditionInput | null > | null,
  not?: ModelCalibratedPanelReviewerRecordConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type UpdateCalibratedPanelReviewerRecordInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
  alias?: string | null,
  completedSessions?: number | null,
  _version?: number | null,
};

export type DeleteCalibratedPanelReviewerRecordInput = {
  id: string,
  _version?: number | null,
};

export type DeletePanelReviewVoteRecordInput = {
  id: string,
  _version?: number | null,
};

export type ModelPanelReviewVoteRecordConditionInput = {
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  reviewerAlias?: ModelStringInput | null,
  panelReview?: ModelIDInput | null,
  vote?: ModelPanelReviewSessionVoteInput | null,
  voteStage?: ModelPanelReviewStateInput | null,
  and?: Array< ModelPanelReviewVoteRecordConditionInput | null > | null,
  or?: Array< ModelPanelReviewVoteRecordConditionInput | null > | null,
  not?: ModelPanelReviewVoteRecordConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPanelReviewSessionVoteInput = {
  eq?: PanelReviewSessionVote | null,
  ne?: PanelReviewSessionVote | null,
};

export type UpdatePanelReviewVoteRecordInput = {
  id: string,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
  reviewerAlias?: string | null,
  panelReview?: string | null,
  vote?: PanelReviewSessionVote | null,
  voteStage?: PanelReviewState | null,
  _version?: number | null,
};

export type CreatePanelReviewVoteRecordInput = {
  id?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  owner?: string | null,
  reviewerAlias: string,
  panelReview: string,
  vote: PanelReviewSessionVote,
  voteStage: PanelReviewState,
  _version?: number | null,
};

export type CreatePanelReviewCommentInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  alias: string,
  panelReviewId: string,
  voteStage: PanelReviewState,
  content: string,
  _version?: number | null,
};

export type ModelPanelReviewCommentConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  panelReviewId?: ModelIDInput | null,
  voteStage?: ModelPanelReviewStateInput | null,
  content?: ModelStringInput | null,
  and?: Array< ModelPanelReviewCommentConditionInput | null > | null,
  or?: Array< ModelPanelReviewCommentConditionInput | null > | null,
  not?: ModelPanelReviewCommentConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type PanelReviewComment = {
  __typename: "PanelReviewComment",
  createdAt: string,
  id: string,
  owner?: string | null,
  updatedAt: string,
  alias: string,
  panelReviewId: string,
  voteStage: PanelReviewState,
  content: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export type UpdatePanelReviewCommentInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  updatedAt?: string | null,
  alias?: string | null,
  panelReviewId?: string | null,
  voteStage?: PanelReviewState | null,
  content?: string | null,
  _version?: number | null,
};

export type DeletePanelReviewCommentInput = {
  id: string,
  _version?: number | null,
};

export type CreateDocumentReviewCommentRecordInput = {
  createdAt?: string | null,
  id?: string | null,
  owner?: string | null,
  updatedAt?: string | null,
  alias: string,
  documentReviewId: string,
  voteStage: DocumentReviewSessionState,
  content: string,
  _version?: number | null,
};

export type ModelDocumentReviewCommentRecordConditionInput = {
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  documentReviewId?: ModelIDInput | null,
  voteStage?: ModelDocumentReviewSessionStateInput | null,
  content?: ModelStringInput | null,
  and?: Array< ModelDocumentReviewCommentRecordConditionInput | null > | null,
  or?: Array< ModelDocumentReviewCommentRecordConditionInput | null > | null,
  not?: ModelDocumentReviewCommentRecordConditionInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type DocumentReviewCommentRecord = {
  __typename: "DocumentReviewCommentRecord",
  createdAt: string,
  id: string,
  owner?: string | null,
  updatedAt: string,
  alias: string,
  documentReviewId: string,
  voteStage: DocumentReviewSessionState,
  content: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  lastModifiedBy: string,
};

export type UpdateDocumentReviewCommentRecordInput = {
  createdAt?: string | null,
  id: string,
  owner?: string | null,
  updatedAt?: string | null,
  alias?: string | null,
  documentReviewId?: string | null,
  voteStage?: DocumentReviewSessionState | null,
  content?: string | null,
  _version?: number | null,
};

export type DeleteDocumentReviewCommentRecordInput = {
  id: string,
  _version?: number | null,
};

export type ManagerProfile = {
  __typename: "ManagerProfile",
  alias: string,
  directReports?:  Array<DirectReport > | null,
};

export type DirectReport = {
  __typename: "DirectReport",
  alias: string,
  hireDate: string,
  personId: string,
  jobLevel: number,
  isManager: boolean,
  firstName: string,
  lastName: string,
  email: string,
  hrJobTitle: string,
};

export type ManagerPapiProfile = {
  __typename: "ManagerPapiProfile",
  alias?: string | null,
  directReports?:  Array<PapiDirectReportProfile > | null,
};

export type PapiDirectReportProfile = {
  __typename: "PapiDirectReportProfile",
  username?: string | null,
  personId?: string | null,
  jobLevel?: number | null,
  isManager?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  hrJobTitle?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserProfileFilterInput = {
  alias?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  email?: ModelStringInput | null,
  hasLoggedIn?: ModelBooleanInput | null,
  hireDate?: ModelStringInput | null,
  identityId?: ModelStringInput | null,
  isManager?: ModelBooleanInput | null,
  jobLevel?: ModelIntInput | null,
  jobTitle?: ModelStringInput | null,
  manager?: ModelStringInput | null,
  name?: ModelStringInput | null,
  orgName?: ModelStringInput | null,
  personId?: ModelStringInput | null,
  status?: ModelUserProfileStatusInput | null,
  and?: Array< ModelUserProfileFilterInput | null > | null,
  or?: Array< ModelUserProfileFilterInput | null > | null,
  not?: ModelUserProfileFilterInput | null,
};

export type ModelUserProfileConnection = {
  __typename: "ModelUserProfileConnection",
  items:  Array<UserProfile | null >,
  nextToken?: string | null,
};

export type PeopleProfile = {
  __typename: "PeopleProfile",
  alias: string,
  jobLevel: number,
  isManager: boolean,
  firstName: string,
  lastName: string,
  email: string,
  hrJobTitle: string,
  managerAlias?: string | null,
  managerName?: string | null,
};

export type PapiProfile = {
  __typename: "PapiProfile",
  username: string,
  personId: string,
  firstName: string,
  lastName?: string | null,
  jobLevel?: string | null,
  job?:  Array<PapiJobProfile > | null,
  emailAddress?: string | null,
  isManager?: string | null,
  businessTitle?: string | null,
  jobFamilyName?: string | null,
  manager?: string | null,
  skipManager?: string | null,
  employeeId?: string | null,
  lastHireDate?: string | null,
  location?:  Array<PapiLocationProfile > | null,
  costCenterId: number,
};

export type PapiJobProfile = {
  __typename: "PapiJobProfile",
  businessTitle?: string | null,
  jobLevel?: number | null,
  hireDate?: string | null,
  lastHireDate?: string | null,
  costCenterEntryDate?: string | null,
  jobEntryDate?: string | null,
  departmentEntryDate?: string | null,
};

export type PapiLocationProfile = {
  __typename: "PapiLocationProfile",
  buildingCode: string,
  city?: string | null,
  country?: string | null,
  name?: string | null,
  timezone?: string | null,
};

export enum FileRecordType {
  WORKSUMMARY = "WORKSUMMARY",
  SHARED_USER = "SHARED_USER",
}


export type DownloadableFile = {
  __typename: "DownloadableFile",
  fileRecordId?: string | null,
  referenceId?: string | null,
  fileRecordType?: FileRecordType | null,
  presignedURL?: string | null,
};

export type ModelBaselineRoleDimensionFilterInput = {
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  jobLevel?: ModelIntInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  jobRole?: ModelStringInput | null,
  and?: Array< ModelBaselineRoleDimensionFilterInput | null > | null,
  or?: Array< ModelBaselineRoleDimensionFilterInput | null > | null,
  not?: ModelBaselineRoleDimensionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
};

export type ModelPeerReviewFilterInput = {
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  content?: ModelStringInput | null,
  requiredBy?: ModelStringInput | null,
  reviewedContentVersion?: ModelIntInput | null,
  reviewStatus?: ModelPeerReviewStatusInput | null,
  reviewType?: ModelPeerReviewTypeInput | null,
  candidatePathId?: ModelIDInput | null,
  reviewerAlias?: ModelStringInput | null,
  workSummaryId?: ModelIDInput | null,
  and?: Array< ModelPeerReviewFilterInput | null > | null,
  or?: Array< ModelPeerReviewFilterInput | null > | null,
  not?: ModelPeerReviewFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelWorkSummaryFilterInput = {
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  candidatePathId?: ModelIDInput | null,
  categoryIds?: ModelIDInput | null,
  baselineRoleDimensionIds?: ModelIDInput | null,
  content?: ModelStringInput | null,
  contentVersion?: ModelIntInput | null,
  leadershipPrinciples?: ModelStringInput | null,
  status?: ModelWorkSummaryStatusInput | null,
  statusReason?: ModelWorkSummaryStatusReasonInput | null,
  submittedAt?: ModelStringInput | null,
  title?: ModelStringInput | null,
  type?: ModelWorkSummaryTypeInput | null,
  and?: Array< ModelWorkSummaryFilterInput | null > | null,
  or?: Array< ModelWorkSummaryFilterInput | null > | null,
  not?: ModelWorkSummaryFilterInput | null,
};

export type ModelFileRecordFilterInput = {
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  fileDateModified?: ModelIntInput | null,
  fileName?: ModelStringInput | null,
  fileSizeBytes?: ModelIntInput | null,
  s3Key?: ModelStringInput | null,
  storageIdKey?: ModelStringInput | null,
  status?: ModelFileRecordStatusInput | null,
  and?: Array< ModelFileRecordFilterInput | null > | null,
  or?: Array< ModelFileRecordFilterInput | null > | null,
  not?: ModelFileRecordFilterInput | null,
};

export type ModelFileRecordConnection = {
  __typename: "ModelFileRecordConnection",
  items:  Array<FileRecord | null >,
  nextToken?: string | null,
};

export type ModelPromoPathFilterInput = {
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  allowAttachments?: ModelBooleanInput | null,
  categoryIds?: ModelIDInput | null,
  name?: ModelStringInput | null,
  pathType?: ModelPathDefinitionTypeInput | null,
  role?: ModelStringInput | null,
  startLevel?: ModelIntInput | null,
  status?: ModelPromoPathStatusInput | null,
  suggestedLPs?: ModelStringInput | null,
  targetLevel?: ModelIntInput | null,
  candidate?: ModelStringInput | null,
  targetDate?: ModelStringInput | null,
  totalCompleted?: ModelIntInput | null,
  totalRequired?: ModelIntInput | null,
  manager?: ModelStringInput | null,
  templateId?: ModelIDInput | null,
  and?: Array< ModelPromoPathFilterInput | null > | null,
  or?: Array< ModelPromoPathFilterInput | null > | null,
  not?: ModelPromoPathFilterInput | null,
};

export type ModelCategoryFilterInput = {
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  description?: ModelStringInput | null,
  isDefault?: ModelStringInput | null,
  name?: ModelStringInput | null,
  role?: ModelStringInput | null,
  status?: ModelCategoryStatusInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
};

export type ModelCommentFilterInput = {
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  content?: ModelStringInput | null,
  status?: ModelCommentStatusInput | null,
  type?: ModelCommentTypeInput | null,
  workSummaryId?: ModelIDInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
};

export type ModelAuditTrailsConnection = {
  __typename: "ModelAuditTrailsConnection",
  items:  Array<AuditTrails | null >,
  nextToken?: string | null,
};

export type ModelDocumentReviewSessionRecordFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  ownerAlias?: ModelStringInput | null,
  candidateAlias?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
  workdocsLink?: ModelStringInput | null,
  chimeLink?: ModelStringInput | null,
  sessionStart?: ModelStringInput | null,
  sessionState?: ModelDocumentReviewSessionStateInput | null,
  overrideReason?: ModelStringInput | null,
  and?: Array< ModelDocumentReviewSessionRecordFilterInput | null > | null,
  or?: Array< ModelDocumentReviewSessionRecordFilterInput | null > | null,
  not?: ModelDocumentReviewSessionRecordFilterInput | null,
};

export type ModelDocumentReviewSessionRecordConnection = {
  __typename: "ModelDocumentReviewSessionRecordConnection",
  items:  Array<DocumentReviewSessionRecord | null >,
  nextToken?: string | null,
};

export type ModelCalibratedDocumentReviewerRecordFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  completedSessions?: ModelIntInput | null,
  and?: Array< ModelCalibratedDocumentReviewerRecordFilterInput | null > | null,
  or?: Array< ModelCalibratedDocumentReviewerRecordFilterInput | null > | null,
  not?: ModelCalibratedDocumentReviewerRecordFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCalibratedDocumentReviewerRecordConnection = {
  __typename: "ModelCalibratedDocumentReviewerRecordConnection",
  items:  Array<CalibratedDocumentReviewerRecord | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDocumentReviewSessionVoteRecordFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  reviewerAlias?: ModelStringInput | null,
  documentReviewSession?: ModelIDInput | null,
  documentReady?: ModelDocumentReviewSessionVoteInput | null,
  candidateReady?: ModelDocumentReviewSessionVoteInput | null,
  and?: Array< ModelDocumentReviewSessionVoteRecordFilterInput | null > | null,
  or?: Array< ModelDocumentReviewSessionVoteRecordFilterInput | null > | null,
  not?: ModelDocumentReviewSessionVoteRecordFilterInput | null,
  owner?: ModelStringInput | null,
};

export type ModelDocumentReviewSessionToReviewerMappingFilterInput = {
  id?: ModelIDInput | null,
  documentReviewSessionRecordID?: ModelIDInput | null,
  calibratedDocumentReviewerRecordID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDocumentReviewSessionToReviewerMappingFilterInput | null > | null,
  or?: Array< ModelDocumentReviewSessionToReviewerMappingFilterInput | null > | null,
  not?: ModelDocumentReviewSessionToReviewerMappingFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelPanelReviewSessionToReviewerMappingFilterInput = {
  id?: ModelIDInput | null,
  panelReviewRecordID?: ModelIDInput | null,
  calibratedPanelReviewerRecordID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPanelReviewSessionToReviewerMappingFilterInput | null > | null,
  or?: Array< ModelPanelReviewSessionToReviewerMappingFilterInput | null > | null,
  not?: ModelPanelReviewSessionToReviewerMappingFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
};

export type ModelPanelReviewRecordFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  coOwners?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  ownerAlias?: ModelStringInput | null,
  candidateAlias?: ModelStringInput | null,
  areaLeadAlias?: ModelStringInput | null,
  documentReviewSession?: ModelIDInput | null,
  workdocsLink?: ModelStringInput | null,
  chimeLink?: ModelStringInput | null,
  sessionStart?: ModelStringInput | null,
  sessionState?: ModelPanelReviewStateInput | null,
  overrideReason?: ModelStringInput | null,
  and?: Array< ModelPanelReviewRecordFilterInput | null > | null,
  or?: Array< ModelPanelReviewRecordFilterInput | null > | null,
  not?: ModelPanelReviewRecordFilterInput | null,
};

export type ModelPanelReviewRecordConnection = {
  __typename: "ModelPanelReviewRecordConnection",
  items:  Array<PanelReviewRecord | null >,
  nextToken?: string | null,
};

export type ModelCalibratedPanelReviewerRecordFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  completedSessions?: ModelIntInput | null,
  and?: Array< ModelCalibratedPanelReviewerRecordFilterInput | null > | null,
  or?: Array< ModelCalibratedPanelReviewerRecordFilterInput | null > | null,
  not?: ModelCalibratedPanelReviewerRecordFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCalibratedPanelReviewerRecordConnection = {
  __typename: "ModelCalibratedPanelReviewerRecordConnection",
  items:  Array<CalibratedPanelReviewerRecord | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPanelReviewVoteRecordByPanelReviewerCompositeKeyConditionInput = {
  eq?: ModelPanelReviewVoteRecordByPanelReviewerCompositeKeyInput | null,
  le?: ModelPanelReviewVoteRecordByPanelReviewerCompositeKeyInput | null,
  lt?: ModelPanelReviewVoteRecordByPanelReviewerCompositeKeyInput | null,
  ge?: ModelPanelReviewVoteRecordByPanelReviewerCompositeKeyInput | null,
  gt?: ModelPanelReviewVoteRecordByPanelReviewerCompositeKeyInput | null,
  between?: Array< ModelPanelReviewVoteRecordByPanelReviewerCompositeKeyInput | null > | null,
  beginsWith?: ModelPanelReviewVoteRecordByPanelReviewerCompositeKeyInput | null,
};

export type ModelPanelReviewVoteRecordByPanelReviewerCompositeKeyInput = {
  panelReview?: string | null,
  voteStage?: PanelReviewState | null,
};

export type ModelPanelReviewVoteRecordFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  reviewerAlias?: ModelStringInput | null,
  panelReview?: ModelIDInput | null,
  vote?: ModelPanelReviewSessionVoteInput | null,
  voteStage?: ModelPanelReviewStateInput | null,
  and?: Array< ModelPanelReviewVoteRecordFilterInput | null > | null,
  or?: Array< ModelPanelReviewVoteRecordFilterInput | null > | null,
  not?: ModelPanelReviewVoteRecordFilterInput | null,
};

export type ModelPanelReviewVoteRecordByPanelReviewSessionCompositeKeyConditionInput = {
  eq?: ModelPanelReviewVoteRecordByPanelReviewSessionCompositeKeyInput | null,
  le?: ModelPanelReviewVoteRecordByPanelReviewSessionCompositeKeyInput | null,
  lt?: ModelPanelReviewVoteRecordByPanelReviewSessionCompositeKeyInput | null,
  ge?: ModelPanelReviewVoteRecordByPanelReviewSessionCompositeKeyInput | null,
  gt?: ModelPanelReviewVoteRecordByPanelReviewSessionCompositeKeyInput | null,
  between?: Array< ModelPanelReviewVoteRecordByPanelReviewSessionCompositeKeyInput | null > | null,
  beginsWith?: ModelPanelReviewVoteRecordByPanelReviewSessionCompositeKeyInput | null,
};

export type ModelPanelReviewVoteRecordByPanelReviewSessionCompositeKeyInput = {
  reviewerAlias?: string | null,
  voteStage?: PanelReviewState | null,
};

export type ModelPanelReviewCommentFilterInput = {
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  panelReviewId?: ModelIDInput | null,
  voteStage?: ModelPanelReviewStateInput | null,
  content?: ModelStringInput | null,
  and?: Array< ModelPanelReviewCommentFilterInput | null > | null,
  or?: Array< ModelPanelReviewCommentFilterInput | null > | null,
  not?: ModelPanelReviewCommentFilterInput | null,
};

export type ModelPanelReviewCommentConnection = {
  __typename: "ModelPanelReviewCommentConnection",
  items:  Array<PanelReviewComment | null >,
  nextToken?: string | null,
};

export type ModelDocumentReviewCommentRecordFilterInput = {
  createdAt?: ModelStringInput | null,
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  alias?: ModelStringInput | null,
  documentReviewId?: ModelIDInput | null,
  voteStage?: ModelDocumentReviewSessionStateInput | null,
  content?: ModelStringInput | null,
  and?: Array< ModelDocumentReviewCommentRecordFilterInput | null > | null,
  or?: Array< ModelDocumentReviewCommentRecordFilterInput | null > | null,
  not?: ModelDocumentReviewCommentRecordFilterInput | null,
};

export type ModelDocumentReviewCommentRecordConnection = {
  __typename: "ModelDocumentReviewCommentRecordConnection",
  items:  Array<DocumentReviewCommentRecord | null >,
  nextToken?: string | null,
};

export type CreateWorkSummaryMutationVariables = {
  input: CreateWorkSummaryInput,
  condition?: ModelWorkSummaryConditionInput | null,
};

export type CreateWorkSummaryMutation = {
  createWorkSummary?:  {
    __typename: "WorkSummary",
    createdAt: string,
    id: string,
    coOwners?: Array< string > | null,
    owner?: string | null,
    updatedAt: string,
    alias: string,
    candidatePathId?: string | null,
    content: string,
    leadershipPrinciples?: Array< string > | null,
    status?: WorkSummaryStatus | null,
    statusReason?: WorkSummaryStatusReason | null,
    submittedAt?: string | null,
    title: string,
    type: WorkSummaryType,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        name: string,
      } | null >,
    } | null,
    documents?:  {
      __typename: "ModelWorkSummaryFilesConnection",
      items:  Array< {
        __typename: "WorkSummaryFiles",
        id: string,
        fileRecord:  {
          __typename: "FileRecord",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          fileName: string,
          s3Key: string,
          status: FileRecordStatus,
          fileSizeBytes: number,
          fileDateModified: number,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    peerReviews?:  {
      __typename: "ModelPeerReviewConnection",
      items:  Array< {
        __typename: "PeerReview",
        createdAt: string,
        id: string,
        coOwners?: Array< string > | null,
        updatedAt: string,
        content?: string | null,
        reviewerAlias: string,
        reviewer:  {
          __typename: "UserProfile",
          name?: string | null,
        },
        reviewStatus?: PeerReviewStatus | null,
        reviewType: PeerReviewType,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateWorkSummaryMutationVariables = {
  input: UpdateWorkSummaryInput,
  condition?: ModelWorkSummaryConditionInput | null,
};

export type UpdateWorkSummaryMutation = {
  updateWorkSummary?:  {
    __typename: "WorkSummary",
    createdAt: string,
    id: string,
    coOwners?: Array< string > | null,
    updatedAt: string,
    alias: string,
    candidatePathId?: string | null,
    content: string,
    leadershipPrinciples?: Array< string > | null,
    status?: WorkSummaryStatus | null,
    statusReason?: WorkSummaryStatusReason | null,
    submittedAt?: string | null,
    title: string,
    type: WorkSummaryType,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        name: string,
      } | null >,
    } | null,
    documents?:  {
      __typename: "ModelWorkSummaryFilesConnection",
      items:  Array< {
        __typename: "WorkSummaryFiles",
        id: string,
        fileRecord:  {
          __typename: "FileRecord",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
          fileName: string,
          s3Key: string,
          status: FileRecordStatus,
          fileSizeBytes: number,
          fileDateModified: number,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    peerReviews?:  {
      __typename: "ModelPeerReviewConnection",
      items:  Array< {
        __typename: "PeerReview",
        createdAt: string,
        id: string,
        coOwners?: Array< string > | null,
        updatedAt: string,
        content?: string | null,
        reviewerAlias: string,
        reviewer:  {
          __typename: "UserProfile",
          name?: string | null,
        },
        reviewStatus?: PeerReviewStatus | null,
        reviewType: PeerReviewType,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteWorkSummaryMutationVariables = {
  input: DeleteWorkSummaryInput,
  condition?: ModelWorkSummaryConditionInput | null,
};

export type DeleteWorkSummaryMutation = {
  deleteWorkSummary?:  {
    __typename: "WorkSummary",
    id: string,
    title: string,
    alias: string,
    _version: number,
  } | null,
};

export type CreatePeerReviewMutationVariables = {
  input: CreatePeerReviewInput,
  condition?: ModelPeerReviewConditionInput | null,
};

export type CreatePeerReviewMutation = {
  createPeerReview?:  {
    __typename: "PeerReview",
    createdAt: string,
    id: string,
    coOwners?: Array< string > | null,
    updatedAt: string,
    content?: string | null,
    requiredBy: string,
    reviewerAlias: string,
    reviewer:  {
      __typename: "UserProfile",
      name?: string | null,
    },
    reviewedContentVersion?: number | null,
    reviewStatus?: PeerReviewStatus | null,
    reviewType: PeerReviewType,
    candidatePath?:  {
      __typename: "PromoPath",
      id: string,
      candidate?: string | null,
      manager?: string | null,
      name?: string | null,
      targetDate?: string | null,
      targetLevel?: number | null,
      template?:  {
        __typename: "PromoPath",
        id: string,
        name?: string | null,
        targetLevel?: number | null,
      } | null,
    } | null,
    workSummary?:  {
      __typename: "WorkSummary",
      id: string,
      alias: string,
      content: string,
      title: string,
      contentVersion?: number | null,
      type: WorkSummaryType,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          description: string,
        } | null >,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
        } | null >,
      } | null,
      documents?:  {
        __typename: "ModelWorkSummaryFilesConnection",
        items:  Array< {
          __typename: "WorkSummaryFiles",
          id: string,
          fileRecord:  {
            __typename: "FileRecord",
            createdAt: string,
            id: string,
            owner?: string | null,
            updatedAt: string,
            fileDateModified: number,
            fileName: string,
            fileSizeBytes: number,
            status: FileRecordStatus,
            s3Key: string,
            _deleted?: boolean | null,
          },
          _deleted?: boolean | null,
        } | null >,
      } | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePeerReviewMutationVariables = {
  input: UpdatePeerReviewInput,
  condition?: ModelPeerReviewConditionInput | null,
};

export type UpdatePeerReviewMutation = {
  updatePeerReview?:  {
    __typename: "PeerReview",
    createdAt: string,
    id: string,
    coOwners?: Array< string > | null,
    updatedAt: string,
    content?: string | null,
    requiredBy: string,
    reviewerAlias: string,
    reviewer:  {
      __typename: "UserProfile",
      name?: string | null,
    },
    reviewedContentVersion?: number | null,
    reviewStatus?: PeerReviewStatus | null,
    reviewType: PeerReviewType,
    candidatePath?:  {
      __typename: "PromoPath",
      id: string,
      candidate?: string | null,
      manager?: string | null,
      name?: string | null,
      targetDate?: string | null,
      targetLevel?: number | null,
      template?:  {
        __typename: "PromoPath",
        id: string,
        name?: string | null,
        targetLevel?: number | null,
      } | null,
    } | null,
    workSummary?:  {
      __typename: "WorkSummary",
      id: string,
      alias: string,
      content: string,
      title: string,
      contentVersion?: number | null,
      type: WorkSummaryType,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          description: string,
        } | null >,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
        } | null >,
      } | null,
      documents?:  {
        __typename: "ModelWorkSummaryFilesConnection",
        items:  Array< {
          __typename: "WorkSummaryFiles",
          id: string,
          fileRecord:  {
            __typename: "FileRecord",
            createdAt: string,
            id: string,
            owner?: string | null,
            updatedAt: string,
            fileDateModified: number,
            fileName: string,
            fileSizeBytes: number,
            status: FileRecordStatus,
            s3Key: string,
            _deleted?: boolean | null,
          },
          _deleted?: boolean | null,
        } | null >,
      } | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePeerReviewMutationVariables = {
  input: DeletePeerReviewInput,
  condition?: ModelPeerReviewConditionInput | null,
};

export type DeletePeerReviewMutation = {
  deletePeerReview?:  {
    __typename: "PeerReview",
    id: string,
    reviewerAlias: string,
    workSummary?:  {
      __typename: "WorkSummary",
      title: string,
    } | null,
    _version: number,
  } | null,
};

export type CreateFileRecordMutationVariables = {
  input: CreateFileRecordInput,
  condition?: ModelFileRecordConditionInput | null,
};

export type CreateFileRecordMutation = {
  createFileRecord?:  {
    __typename: "FileRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    fileName: string,
    s3Key: string,
    status: FileRecordStatus,
    fileSizeBytes: number,
    fileDateModified: number,
    workSummaries?:  {
      __typename: "ModelWorkSummaryFilesConnection",
      items:  Array< {
        __typename: "WorkSummaryFiles",
        id: string,
        workSummaryID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFileRecordMutationVariables = {
  input: UpdateFileRecordInput,
  condition?: ModelFileRecordConditionInput | null,
};

export type UpdateFileRecordMutation = {
  updateFileRecord?:  {
    __typename: "FileRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    fileName: string,
    s3Key: string,
    status: FileRecordStatus,
    fileSizeBytes: number,
    fileDateModified: number,
    workSummaries?:  {
      __typename: "ModelWorkSummaryFilesConnection",
      items:  Array< {
        __typename: "WorkSummaryFiles",
        id: string,
        workSummaryID: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFileRecordMutationVariables = {
  input: DeleteFileRecordInput,
  condition?: ModelFileRecordConditionInput | null,
};

export type DeleteFileRecordMutation = {
  deleteFileRecord?:  {
    __typename: "FileRecord",
    id: string,
    fileName: string,
    owner?: string | null,
    _version: number,
  } | null,
};

export type CreateCandidatePathMutationVariables = {
  input: CreatePromoPathInput,
  condition?: ModelPromoPathConditionInput | null,
};

export type CreateCandidatePathMutation = {
  createPromoPath?:  {
    __typename: "PromoPath",
    id: string,
    createdAt: string,
    updatedAt: string,
    categoryIds?: Array< string > | null,
    allowAttachments?: boolean | null,
    status: PromoPathStatus,
    name?: string | null,
    manager?: string | null,
    candidate?: string | null,
    role?: string | null,
    startLevel?: number | null,
    suggestedLPs?: Array< string > | null,
    targetLevel?: number | null,
    template?:  {
      __typename: "PromoPath",
      createdAt: string,
      id: string,
      updatedAt: string,
      allowAttachments?: boolean | null,
      categoryIds?: Array< string > | null,
      name?: string | null,
      status: PromoPathStatus,
      role?: string | null,
      startLevel?: number | null,
      suggestedLPs?: Array< string > | null,
      targetLevel?: number | null,
      totalRequired?: number | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          createdAt: string,
          id: string,
          updatedAt: string,
          description: string,
          isDefault?: string | null,
          name: string,
          role: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        createdAt: string,
        updatedAt: string,
        description: string,
        isDefault?: string | null,
        name: string,
        role: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCandidatePathMutationVariables = {
  input: UpdatePromoPathInput,
  condition?: ModelPromoPathConditionInput | null,
};

export type UpdateCandidatePathMutation = {
  updatePromoPath?:  {
    __typename: "PromoPath",
    id: string,
    createdAt: string,
    updatedAt: string,
    categoryIds?: Array< string > | null,
    allowAttachments?: boolean | null,
    status: PromoPathStatus,
    name?: string | null,
    manager?: string | null,
    candidate?: string | null,
    role?: string | null,
    startLevel?: number | null,
    suggestedLPs?: Array< string > | null,
    targetLevel?: number | null,
    template?:  {
      __typename: "PromoPath",
      createdAt: string,
      id: string,
      updatedAt: string,
      allowAttachments?: boolean | null,
      categoryIds?: Array< string > | null,
      name?: string | null,
      status: PromoPathStatus,
      role?: string | null,
      startLevel?: number | null,
      suggestedLPs?: Array< string > | null,
      targetLevel?: number | null,
      totalRequired?: number | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          createdAt: string,
          id: string,
          updatedAt: string,
          description: string,
          isDefault?: string | null,
          name: string,
          role: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        createdAt: string,
        updatedAt: string,
        description: string,
        isDefault?: string | null,
        name: string,
        role: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePromoPathMutationVariables = {
  input: CreatePromoPathInput,
  condition?: ModelPromoPathConditionInput | null,
};

export type CreatePromoPathMutation = {
  createPromoPath?:  {
    __typename: "PromoPath",
    id: string,
    createdAt: string,
    updatedAt: string,
    categoryIds?: Array< string > | null,
    allowAttachments?: boolean | null,
    status: PromoPathStatus,
    name?: string | null,
    manager?: string | null,
    candidate?: string | null,
    role?: string | null,
    startLevel?: number | null,
    suggestedLPs?: Array< string > | null,
    targetLevel?: number | null,
    descendants?:  {
      __typename: "ModelPromoPathConnection",
      items:  Array< {
        __typename: "PromoPath",
        id: string,
        candidate?: string | null,
        name?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        createdAt: string,
        updatedAt: string,
        description: string,
        isDefault?: string | null,
        name: string,
        role: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePromoPathMutationVariables = {
  input: UpdatePromoPathInput,
  condition?: ModelPromoPathConditionInput | null,
};

export type UpdatePromoPathMutation = {
  updatePromoPath?:  {
    __typename: "PromoPath",
    id: string,
    createdAt: string,
    updatedAt: string,
    categoryIds?: Array< string > | null,
    allowAttachments?: boolean | null,
    status: PromoPathStatus,
    name?: string | null,
    manager?: string | null,
    candidate?: string | null,
    role?: string | null,
    startLevel?: number | null,
    suggestedLPs?: Array< string > | null,
    targetLevel?: number | null,
    descendants?:  {
      __typename: "ModelPromoPathConnection",
      items:  Array< {
        __typename: "PromoPath",
        id: string,
        candidate?: string | null,
        name?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        createdAt: string,
        updatedAt: string,
        description: string,
        isDefault?: string | null,
        name: string,
        role: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCandidatePathMutationVariables = {
  input: DeletePromoPathInput,
  condition?: ModelPromoPathConditionInput | null,
};

export type DeleteCandidatePathMutation = {
  deletePromoPath?:  {
    __typename: "PromoPath",
    id: string,
    name?: string | null,
    template?:  {
      __typename: "PromoPath",
      name?: string | null,
    } | null,
    manager?: string | null,
    candidate?: string | null,
    _version: number,
  } | null,
};

export type DeletePromoPathMutationVariables = {
  input: DeletePromoPathInput,
  condition?: ModelPromoPathConditionInput | null,
};

export type DeletePromoPathMutation = {
  deletePromoPath?:  {
    __typename: "PromoPath",
    id: string,
    name?: string | null,
    manager?: string | null,
    candidate?: string | null,
    _version: number,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    createdAt: string,
    updatedAt: string,
    description: string,
    isDefault?: string | null,
    name: string,
    role: string,
    status: CategoryStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    createdAt: string,
    updatedAt: string,
    description: string,
    isDefault?: string | null,
    name: string,
    role: string,
    status: CategoryStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    _version: number,
  } | null,
};

export type CreateUserProfileMutationVariables = {
  input: CreateUserProfileInput,
  condition?: ModelUserProfileConditionInput | null,
};

export type CreateUserProfileMutation = {
  createUserProfile?:  {
    __typename: "UserProfile",
    alias: string,
    createdAt: string,
    updatedAt: string,
    personId?: string | null,
    email: string,
    hasLoggedIn?: boolean | null,
    name?: string | null,
    jobTitle?: string | null,
    jobLevel: number,
    manager: string,
    managerProfile?:  {
      __typename: "UserProfile",
      alias: string,
      email: string,
      name?: string | null,
    } | null,
    isManager: boolean,
    orgName?: string | null,
    preferences?:  {
      __typename: "UserPreferences",
      landingPage?: string | null,
      visualMode?: VisualMode | null,
      density?: Density | null,
    } | null,
    status?: UserProfileStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateUserProfileMutationVariables = {
  input: UpdateUserProfileInput,
  condition?: ModelUserProfileConditionInput | null,
};

export type UpdateUserProfileMutation = {
  updateUserProfile?:  {
    __typename: "UserProfile",
    alias: string,
    createdAt: string,
    updatedAt: string,
    personId?: string | null,
    email: string,
    hasLoggedIn?: boolean | null,
    name?: string | null,
    jobTitle?: string | null,
    jobLevel: number,
    manager: string,
    managerProfile?:  {
      __typename: "UserProfile",
      alias: string,
      email: string,
      name?: string | null,
    } | null,
    isManager: boolean,
    orgName?: string | null,
    preferences?:  {
      __typename: "UserPreferences",
      landingPage?: string | null,
      visualMode?: VisualMode | null,
      density?: Density | null,
    } | null,
    status?: UserProfileStatus | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteUserProfileMutationVariables = {
  input: DeleteUserProfileInput,
  condition?: ModelUserProfileConditionInput | null,
};

export type DeleteUserProfileMutation = {
  deleteUserProfile?:  {
    __typename: "UserProfile",
    alias: string,
    _version: number,
  } | null,
};

export type CreateWorkSummaryFilesMutationVariables = {
  input: CreateWorkSummaryFilesInput,
  condition?: ModelWorkSummaryFilesConditionInput | null,
};

export type CreateWorkSummaryFilesMutation = {
  createWorkSummaryFiles?:  {
    __typename: "WorkSummaryFiles",
    id: string,
    workSummaryID: string,
    fileRecordID: string,
    workSummary:  {
      __typename: "WorkSummary",
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      coOwners?: Array< string > | null,
      alias: string,
      categoryIds?: Array< string > | null,
      content: string,
      leadershipPrinciples?: Array< string > | null,
      status?: WorkSummaryStatus | null,
      statusReason?: WorkSummaryStatusReason | null,
      submittedAt?: string | null,
      title: string,
      type: WorkSummaryType,
      candidatePathId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    fileRecord:  {
      __typename: "FileRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      fileName: string,
      s3Key: string,
      status: FileRecordStatus,
      fileSizeBytes: number,
      fileDateModified: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateWorkSummaryFilesMutationVariables = {
  input: UpdateWorkSummaryFilesInput,
  condition?: ModelWorkSummaryFilesConditionInput | null,
};

export type UpdateWorkSummaryFilesMutation = {
  updateWorkSummaryFiles?:  {
    __typename: "WorkSummaryFiles",
    id: string,
    workSummaryID: string,
    fileRecordID: string,
    workSummary:  {
      __typename: "WorkSummary",
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      coOwners?: Array< string > | null,
      alias: string,
      categoryIds?: Array< string > | null,
      content: string,
      leadershipPrinciples?: Array< string > | null,
      status?: WorkSummaryStatus | null,
      statusReason?: WorkSummaryStatusReason | null,
      submittedAt?: string | null,
      title: string,
      type: WorkSummaryType,
      candidatePathId?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    fileRecord:  {
      __typename: "FileRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      fileName: string,
      s3Key: string,
      status: FileRecordStatus,
      fileSizeBytes: number,
      fileDateModified: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteWorkSummaryFilesMutationVariables = {
  input: DeleteWorkSummaryFilesInput,
  condition?: ModelWorkSummaryFilesConditionInput | null,
};

export type DeleteWorkSummaryFilesMutation = {
  deleteWorkSummaryFiles?:  {
    __typename: "WorkSummaryFiles",
    id: string,
    workSummaryID: string,
    fileRecordID: string,
    _version: number,
  } | null,
};

export type CreateSharedUserFilesMutationVariables = {
  input: CreateSharedUserFilesInput,
  condition?: ModelSharedUserFilesConditionInput | null,
};

export type CreateSharedUserFilesMutation = {
  createSharedUserFiles?:  {
    __typename: "SharedUserFiles",
    id: string,
    fileRecordID: string,
    userProfileAlias: string,
    fileRecord:  {
      __typename: "FileRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      fileName: string,
      s3Key: string,
      status: FileRecordStatus,
      fileSizeBytes: number,
      fileDateModified: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    userProfile:  {
      __typename: "UserProfile",
      alias: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdateSharedUserFilesMutationVariables = {
  input: UpdateSharedUserFilesInput,
  condition?: ModelSharedUserFilesConditionInput | null,
};

export type UpdateSharedUserFilesMutation = {
  updateSharedUserFiles?:  {
    __typename: "SharedUserFiles",
    id: string,
    fileRecordID: string,
    userProfileAlias: string,
    fileRecord:  {
      __typename: "FileRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      fileName: string,
      s3Key: string,
      status: FileRecordStatus,
      fileSizeBytes: number,
      fileDateModified: number,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    userProfile:  {
      __typename: "UserProfile",
      alias: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteSharedUserFilesMutationVariables = {
  input: DeleteSharedUserFilesInput,
  condition?: ModelSharedUserFilesConditionInput | null,
};

export type DeleteSharedUserFilesMutation = {
  deleteSharedUserFiles?:  {
    __typename: "SharedUserFiles",
    id: string,
    fileRecordID: string,
    userProfileAlias: string,
    _version: number,
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    createdAt: string,
    id: string,
    owner?: string | null,
    updatedAt: string,
    type: CommentType,
    content: string,
    workSummaryId?: string | null,
    author:  {
      __typename: "UserProfile",
      alias: string,
      name?: string | null,
    },
    status: CommentStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    createdAt: string,
    id: string,
    owner?: string | null,
    updatedAt: string,
    type: CommentType,
    content: string,
    workSummaryId?: string | null,
    author:  {
      __typename: "UserProfile",
      alias: string,
      name?: string | null,
    },
    status: CommentStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type DeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    id: string,
    _version: number,
    workSummaryId?: string | null,
  } | null,
};

export type CreateAuditTrailsMutationVariables = {
  input: CreateAuditTrailsInput,
  condition?: ModelAuditTrailsConditionInput | null,
};

export type CreateAuditTrailsMutation = {
  createAuditTrails?:  {
    __typename: "AuditTrails",
    id: string,
    modifiedBy?: string | null,
    changeJustification?: string | null,
    recordId: string,
    recordType: AuditTrailRecordType,
    rawOldRecord?: string | null,
    rawNewRecord: string,
    humanReadableLog: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAuditTrailsMutationVariables = {
  input: UpdateAuditTrailsInput,
  condition?: ModelAuditTrailsConditionInput | null,
};

export type UpdateAuditTrailsMutation = {
  updateAuditTrails?:  {
    __typename: "AuditTrails",
    id: string,
    modifiedBy?: string | null,
    changeJustification?: string | null,
    recordId: string,
    recordType: AuditTrailRecordType,
    rawOldRecord?: string | null,
    rawNewRecord: string,
    humanReadableLog: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAuditTrailsMutationVariables = {
  input: DeleteAuditTrailsInput,
  condition?: ModelAuditTrailsConditionInput | null,
};

export type DeleteAuditTrailsMutation = {
  deleteAuditTrails?:  {
    __typename: "AuditTrails",
    id: string,
    modifiedBy?: string | null,
    changeJustification?: string | null,
    recordId: string,
    recordType: AuditTrailRecordType,
    rawOldRecord?: string | null,
    rawNewRecord: string,
    humanReadableLog: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePromoReadinessRecordMutationVariables = {
  input: CreatePromoReadinessRecordInput,
  condition?: ModelPromoReadinessRecordConditionInput | null,
};

export type CreatePromoReadinessRecordMutation = {
  createPromoReadinessRecord?:  {
    __typename: "PromoReadinessRecord",
    alias: string,
    createdAt: string,
    updatedAt: string,
    id: string,
    certList?: Array< string > | null,
    techPillar?: Array< string > | null,
    tfcMembership: string,
    rating: string,
    feedbackProviders?: Array< string > | null,
    otherContributions: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type UpdatePromoReadinessRecordMutationVariables = {
  input: UpdatePromoReadinessRecordInput,
  condition?: ModelPromoReadinessRecordConditionInput | null,
};

export type UpdatePromoReadinessRecordMutation = {
  updatePromoReadinessRecord?:  {
    __typename: "PromoReadinessRecord",
    alias: string,
    createdAt: string,
    updatedAt: string,
    id: string,
    certList?: Array< string > | null,
    techPillar?: Array< string > | null,
    tfcMembership: string,
    rating: string,
    feedbackProviders?: Array< string > | null,
    otherContributions: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type DeletePromoReadinessRecordMutationVariables = {
  input: DeletePromoReadinessRecordInput,
  condition?: ModelPromoReadinessRecordConditionInput | null,
};

export type DeletePromoReadinessRecordMutation = {
  deletePromoReadinessRecord?:  {
    __typename: "PromoReadinessRecord",
    alias: string,
    createdAt: string,
    updatedAt: string,
    id: string,
    certList?: Array< string > | null,
    techPillar?: Array< string > | null,
    tfcMembership: string,
    rating: string,
    feedbackProviders?: Array< string > | null,
    otherContributions: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type CreateDocumentReviewSessionRecordMutationVariables = {
  input: CreateDocumentReviewSessionRecordInput,
  condition?: ModelDocumentReviewSessionRecordConditionInput | null,
};

export type CreateDocumentReviewSessionRecordMutation = {
  createDocumentReviewSessionRecord?:  {
    __typename: "DocumentReviewSessionRecord",
    id: string,
    candidateAlias: string,
  } | null,
};

export type UpdateDocumentReviewSessionRecordMutationVariables = {
  input: UpdateDocumentReviewSessionRecordInput,
  condition?: ModelDocumentReviewSessionRecordConditionInput | null,
};

export type UpdateDocumentReviewSessionRecordMutation = {
  updateDocumentReviewSessionRecord?:  {
    __typename: "DocumentReviewSessionRecord",
    id: string,
    candidateAlias: string,
    sessionState: DocumentReviewSessionState,
    documentReviewers?:  {
      __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
      items:  Array< {
        __typename: "DocumentReviewSessionToReviewerMapping",
        id: string,
        calibratedDocumentReviewerRecord:  {
          __typename: "CalibratedDocumentReviewerRecord",
          alias: string,
        },
        _version: number,
        _deleted?: boolean | null,
      } | null >,
    } | null,
    _version: number,
    _deleted?: boolean | null,
  } | null,
};

export type DeleteDocumentReviewSessionRecordMutationVariables = {
  input: DeleteDocumentReviewSessionRecordInput,
  condition?: ModelDocumentReviewSessionRecordConditionInput | null,
};

export type DeleteDocumentReviewSessionRecordMutation = {
  deleteDocumentReviewSessionRecord?:  {
    __typename: "DocumentReviewSessionRecord",
    id: string,
    candidateAlias: string,
    _version: number,
    _deleted?: boolean | null,
  } | null,
};

export type CreateCalibratedDocumentReviewerRecordMutationVariables = {
  input: CreateCalibratedDocumentReviewerRecordInput,
  condition?: ModelCalibratedDocumentReviewerRecordConditionInput | null,
};

export type CreateCalibratedDocumentReviewerRecordMutation = {
  createCalibratedDocumentReviewerRecord?:  {
    __typename: "CalibratedDocumentReviewerRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    alias: string,
    completedSessions?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type UpdateCalibratedDocumentReviewerRecordMutationVariables = {
  input: UpdateCalibratedDocumentReviewerRecordInput,
  condition?: ModelCalibratedDocumentReviewerRecordConditionInput | null,
};

export type UpdateCalibratedDocumentReviewerRecordMutation = {
  updateCalibratedDocumentReviewerRecord?:  {
    __typename: "CalibratedDocumentReviewerRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    alias: string,
    completedSessions?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type DeleteCalibratedDocumentReviewerRecordMutationVariables = {
  input: DeleteCalibratedDocumentReviewerRecordInput,
  condition?: ModelCalibratedDocumentReviewerRecordConditionInput | null,
};

export type DeleteCalibratedDocumentReviewerRecordMutation = {
  deleteCalibratedDocumentReviewerRecord?:  {
    __typename: "CalibratedDocumentReviewerRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    alias: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type CreateDocumentReviewSessionVoteRecordMutationVariables = {
  input: CreateDocumentReviewSessionVoteRecordInput,
  condition?: ModelDocumentReviewSessionVoteRecordConditionInput | null,
};

export type CreateDocumentReviewSessionVoteRecordMutation = {
  createDocumentReviewSessionVoteRecord?:  {
    __typename: "DocumentReviewSessionVoteRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    reviewerAlias: string,
    documentReviewSession: string,
    documentReady?: DocumentReviewSessionVote | null,
    candidateReady?: DocumentReviewSessionVote | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type UpdateDocumentReviewSessionVoteRecordMutationVariables = {
  input: UpdateDocumentReviewSessionVoteRecordInput,
  condition?: ModelDocumentReviewSessionVoteRecordConditionInput | null,
};

export type UpdateDocumentReviewSessionVoteRecordMutation = {
  updateDocumentReviewSessionVoteRecord?:  {
    __typename: "DocumentReviewSessionVoteRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    reviewerAlias: string,
    documentReviewSession: string,
    documentReady?: DocumentReviewSessionVote | null,
    candidateReady?: DocumentReviewSessionVote | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type DeleteDocumentReviewSessionVoteRecordMutationVariables = {
  input: DeleteDocumentReviewSessionVoteRecordInput,
  condition?: ModelDocumentReviewSessionVoteRecordConditionInput | null,
};

export type DeleteDocumentReviewSessionVoteRecordMutation = {
  deleteDocumentReviewSessionVoteRecord?:  {
    __typename: "DocumentReviewSessionVoteRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    reviewerAlias: string,
    documentReviewSession: string,
    documentReady?: DocumentReviewSessionVote | null,
    candidateReady?: DocumentReviewSessionVote | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type CreateDocumentReviewSessionToReviewerMappingMutationVariables = {
  input: CreateDocumentReviewSessionToReviewerMappingInput,
  condition?: ModelDocumentReviewSessionToReviewerMappingConditionInput | null,
};

export type CreateDocumentReviewSessionToReviewerMappingMutation = {
  createDocumentReviewSessionToReviewerMapping?:  {
    __typename: "DocumentReviewSessionToReviewerMapping",
    id: string,
  } | null,
};

export type UpdateDocumentReviewSessionToReviewerMappingMutationVariables = {
  input: UpdateDocumentReviewSessionToReviewerMappingInput,
  condition?: ModelDocumentReviewSessionToReviewerMappingConditionInput | null,
};

export type UpdateDocumentReviewSessionToReviewerMappingMutation = {
  updateDocumentReviewSessionToReviewerMapping?:  {
    __typename: "DocumentReviewSessionToReviewerMapping",
    id: string,
    documentReviewSessionRecordID: string,
    calibratedDocumentReviewerRecordID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeleteDocumentReviewSessionToReviewerMappingMutationVariables = {
  input: DeleteDocumentReviewSessionToReviewerMappingInput,
  condition?: ModelDocumentReviewSessionToReviewerMappingConditionInput | null,
};

export type DeleteDocumentReviewSessionToReviewerMappingMutation = {
  deleteDocumentReviewSessionToReviewerMapping?:  {
    __typename: "DocumentReviewSessionToReviewerMapping",
    id: string,
    documentReviewSessionRecordID: string,
    calibratedDocumentReviewerRecordID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreatePanelReviewSessionToReviewerMappingMutationVariables = {
  input: CreatePanelReviewSessionToReviewerMappingInput,
  condition?: ModelPanelReviewSessionToReviewerMappingConditionInput | null,
};

export type CreatePanelReviewSessionToReviewerMappingMutation = {
  createPanelReviewSessionToReviewerMapping?:  {
    __typename: "PanelReviewSessionToReviewerMapping",
    id: string,
    panelReviewRecordID: string,
    calibratedPanelReviewerRecordID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type UpdatePanelReviewSessionToReviewerMappingMutationVariables = {
  input: UpdatePanelReviewSessionToReviewerMappingInput,
  condition?: ModelPanelReviewSessionToReviewerMappingConditionInput | null,
};

export type UpdatePanelReviewSessionToReviewerMappingMutation = {
  updatePanelReviewSessionToReviewerMapping?:  {
    __typename: "PanelReviewSessionToReviewerMapping",
    id: string,
    panelReviewRecordID: string,
    calibratedPanelReviewerRecordID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type DeletePanelReviewSessionToReviewerMappingMutationVariables = {
  input: DeletePanelReviewSessionToReviewerMappingInput,
  condition?: ModelPanelReviewSessionToReviewerMappingConditionInput | null,
};

export type DeletePanelReviewSessionToReviewerMappingMutation = {
  deletePanelReviewSessionToReviewerMapping?:  {
    __typename: "PanelReviewSessionToReviewerMapping",
    id: string,
    panelReviewRecordID: string,
    calibratedPanelReviewerRecordID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type CreatePanelReviewRecordMutationVariables = {
  input: CreatePanelReviewRecordInput,
  condition?: ModelPanelReviewRecordConditionInput | null,
};

export type CreatePanelReviewRecordMutation = {
  createPanelReviewRecord?:  {
    __typename: "PanelReviewRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    ownerAlias: string,
    candidateAlias: string,
    areaLeadAlias: string,
    documentReviewSession: string,
    workdocsLink: string,
    chimeLink: string,
    sessionStart: string,
    sessionState: PanelReviewState,
    overrideReason?: string | null,
    panelReviewers?:  {
      __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
      items:  Array< {
        __typename: "PanelReviewSessionToReviewerMapping",
        id: string,
        panelReviewRecordID: string,
        calibratedPanelReviewerRecordID: string,
        panelReviewRecord:  {
          __typename: "PanelReviewRecord",
          id: string,
          createdAt: string,
          updatedAt: string,
          ownerAlias: string,
          candidateAlias: string,
          areaLeadAlias: string,
          documentReviewSession: string,
          workdocsLink: string,
          chimeLink: string,
          sessionStart: string,
          sessionState: PanelReviewState,
          overrideReason?: string | null,
          panelReviewers?:  {
            __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
            items:  Array< {
              __typename: "PanelReviewSessionToReviewerMapping",
              id: string,
              panelReviewRecordID: string,
              calibratedPanelReviewerRecordID: string,
              createdAt: string,
              updatedAt: string,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          votes?:  {
            __typename: "ModelPanelReviewVoteRecordConnection",
            items:  Array< {
              __typename: "PanelReviewVoteRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              reviewerAlias: string,
              panelReview: string,
              vote: PanelReviewSessionVote,
              voteStage: PanelReviewState,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          lastModifiedBy: string,
          owner?: string | null,
        },
        calibratedPanelReviewerRecord:  {
          __typename: "CalibratedPanelReviewerRecord",
          id: string,
          createdAt: string,
          updatedAt: string,
          alias: string,
          completedSessions?: number | null,
          panelReviews?:  {
            __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
            items:  Array< {
              __typename: "PanelReviewSessionToReviewerMapping",
              id: string,
              panelReviewRecordID: string,
              calibratedPanelReviewerRecordID: string,
              createdAt: string,
              updatedAt: string,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          lastModifiedBy: string,
          owner?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    votes?:  {
      __typename: "ModelPanelReviewVoteRecordConnection",
      items:  Array< {
        __typename: "PanelReviewVoteRecord",
        id: string,
        createdAt: string,
        updatedAt: string,
        reviewerAlias: string,
        panelReview: string,
        vote: PanelReviewSessionVote,
        voteStage: PanelReviewState,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        lastModifiedBy: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
    coOwners?: Array< string > | null,
  } | null,
};

export type UpdatePanelReviewRecordMutationVariables = {
  input: UpdatePanelReviewRecordInput,
  condition?: ModelPanelReviewRecordConditionInput | null,
};

export type UpdatePanelReviewRecordMutation = {
  updatePanelReviewRecord?:  {
    __typename: "PanelReviewRecord",
    id: string,
    ownerAlias: string,
    candidateAlias: string,
    areaLeadAlias: string,
    documentReviewSession: string,
    workdocsLink: string,
    chimeLink: string,
    sessionStart: string,
    sessionState: PanelReviewState,
    _version: number,
    _deleted?: boolean | null,
    coOwners?: Array< string > | null,
  } | null,
};

export type UpdatePanelReviewStateMutationVariables = {
  input: UpdatePanelReviewRecordInput,
  condition?: ModelPanelReviewRecordConditionInput | null,
};

export type UpdatePanelReviewStateMutation = {
  updatePanelReviewRecord?:  {
    __typename: "PanelReviewRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    ownerAlias: string,
    candidateAlias: string,
    areaLeadAlias: string,
    documentReviewSession: string,
    workdocsLink: string,
    chimeLink: string,
    sessionStart: string,
    sessionState: PanelReviewState,
    overrideReason?: string | null,
    panelReviewers?:  {
      __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
      items:  Array< {
        __typename: "PanelReviewSessionToReviewerMapping",
        id: string,
        panelReviewRecordID: string,
        calibratedPanelReviewerRecordID: string,
        calibratedPanelReviewerRecord:  {
          __typename: "CalibratedPanelReviewerRecord",
          id: string,
          createdAt: string,
          updatedAt: string,
          alias: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          lastModifiedBy: string,
          owner?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
    coOwners?: Array< string > | null,
  } | null,
};

export type DeletePanelReviewRecordMutationVariables = {
  input: DeletePanelReviewRecordInput,
  condition?: ModelPanelReviewRecordConditionInput | null,
};

export type DeletePanelReviewRecordMutation = {
  deletePanelReviewRecord?:  {
    __typename: "PanelReviewRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    ownerAlias: string,
    candidateAlias: string,
    areaLeadAlias: string,
    documentReviewSession: string,
    workdocsLink: string,
    chimeLink: string,
    sessionStart: string,
    sessionState: PanelReviewState,
    overrideReason?: string | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
    coOwners?: Array< string > | null,
  } | null,
};

export type CreateCalibratedPanelReviewerRecordMutationVariables = {
  input: CreateCalibratedPanelReviewerRecordInput,
  condition?: ModelCalibratedPanelReviewerRecordConditionInput | null,
};

export type CreateCalibratedPanelReviewerRecordMutation = {
  createCalibratedPanelReviewerRecord?:  {
    __typename: "CalibratedPanelReviewerRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    alias: string,
    completedSessions?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type UpdateCalibratedPanelReviewerRecordMutationVariables = {
  input: UpdateCalibratedPanelReviewerRecordInput,
  condition?: ModelCalibratedPanelReviewerRecordConditionInput | null,
};

export type UpdateCalibratedPanelReviewerRecordMutation = {
  updateCalibratedPanelReviewerRecord?:  {
    __typename: "CalibratedPanelReviewerRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    alias: string,
    completedSessions?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type DeleteCalibratedPanelReviewerRecordMutationVariables = {
  input: DeleteCalibratedPanelReviewerRecordInput,
  condition?: ModelCalibratedPanelReviewerRecordConditionInput | null,
};

export type DeleteCalibratedPanelReviewerRecordMutation = {
  deleteCalibratedPanelReviewerRecord?:  {
    __typename: "CalibratedPanelReviewerRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    alias: string,
    completedSessions?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type DeletePanelReviewVoteRecordMutationVariables = {
  input: DeletePanelReviewVoteRecordInput,
  condition?: ModelPanelReviewVoteRecordConditionInput | null,
};

export type DeletePanelReviewVoteRecordMutation = {
  deletePanelReviewVoteRecord?:  {
    __typename: "PanelReviewVoteRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    reviewerAlias: string,
    panelReview: string,
    vote: PanelReviewSessionVote,
    voteStage: PanelReviewState,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type UpdatePanelReviewVoteRecordMutationVariables = {
  input: UpdatePanelReviewVoteRecordInput,
  condition?: ModelPanelReviewVoteRecordConditionInput | null,
};

export type UpdatePanelReviewVoteRecordMutation = {
  updatePanelReviewVoteRecord?:  {
    __typename: "PanelReviewVoteRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    reviewerAlias: string,
    panelReview: string,
    vote: PanelReviewSessionVote,
    voteStage: PanelReviewState,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type CreatePanelReviewVoteRecordMutationVariables = {
  input: CreatePanelReviewVoteRecordInput,
  condition?: ModelPanelReviewVoteRecordConditionInput | null,
};

export type CreatePanelReviewVoteRecordMutation = {
  createPanelReviewVoteRecord?:  {
    __typename: "PanelReviewVoteRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    reviewerAlias: string,
    panelReview: string,
    vote: PanelReviewSessionVote,
    voteStage: PanelReviewState,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type CreatePanelReviewCommentMutationVariables = {
  input: CreatePanelReviewCommentInput,
  condition?: ModelPanelReviewCommentConditionInput | null,
};

export type CreatePanelReviewCommentMutation = {
  createPanelReviewComment?:  {
    __typename: "PanelReviewComment",
    createdAt: string,
    id: string,
    owner?: string | null,
    updatedAt: string,
    alias: string,
    panelReviewId: string,
    voteStage: PanelReviewState,
    content: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
  } | null,
};

export type UpdatePanelReviewCommentMutationVariables = {
  input: UpdatePanelReviewCommentInput,
  condition?: ModelPanelReviewCommentConditionInput | null,
};

export type UpdatePanelReviewCommentMutation = {
  updatePanelReviewComment?:  {
    __typename: "PanelReviewComment",
    createdAt: string,
    id: string,
    owner?: string | null,
    updatedAt: string,
    alias: string,
    panelReviewId: string,
    voteStage: PanelReviewState,
    content: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
  } | null,
};

export type DeletePanelReviewCommentMutationVariables = {
  input: DeletePanelReviewCommentInput,
  condition?: ModelPanelReviewCommentConditionInput | null,
};

export type DeletePanelReviewCommentMutation = {
  deletePanelReviewComment?:  {
    __typename: "PanelReviewComment",
    createdAt: string,
    id: string,
    owner?: string | null,
    updatedAt: string,
    alias: string,
    panelReviewId: string,
    voteStage: PanelReviewState,
    content: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
  } | null,
};

export type CreateDocumentReviewCommentRecordMutationVariables = {
  input: CreateDocumentReviewCommentRecordInput,
  condition?: ModelDocumentReviewCommentRecordConditionInput | null,
};

export type CreateDocumentReviewCommentRecordMutation = {
  createDocumentReviewCommentRecord?:  {
    __typename: "DocumentReviewCommentRecord",
    createdAt: string,
    id: string,
    owner?: string | null,
    updatedAt: string,
    alias: string,
    documentReviewId: string,
    voteStage: DocumentReviewSessionState,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
  } | null,
};

export type UpdateDocumentReviewCommentRecordMutationVariables = {
  input: UpdateDocumentReviewCommentRecordInput,
  condition?: ModelDocumentReviewCommentRecordConditionInput | null,
};

export type UpdateDocumentReviewCommentRecordMutation = {
  updateDocumentReviewCommentRecord?:  {
    __typename: "DocumentReviewCommentRecord",
    createdAt: string,
    id: string,
    owner?: string | null,
    updatedAt: string,
    alias: string,
    documentReviewId: string,
    voteStage: DocumentReviewSessionState,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
  } | null,
};

export type DeleteDocumentReviewCommentRecordMutationVariables = {
  input: DeleteDocumentReviewCommentRecordInput,
  condition?: ModelDocumentReviewCommentRecordConditionInput | null,
};

export type DeleteDocumentReviewCommentRecordMutation = {
  deleteDocumentReviewCommentRecord?:  {
    __typename: "DocumentReviewCommentRecord",
    createdAt: string,
    id: string,
    owner?: string | null,
    updatedAt: string,
    alias: string,
    documentReviewId: string,
    voteStage: DocumentReviewSessionState,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
  } | null,
};

export type GetDirectReportsExternalQueryVariables = {
  alias: string,
};

export type GetDirectReportsExternalQuery = {
  getDirectReports:  {
    __typename: "ManagerProfile",
    alias: string,
    directReports?:  Array< {
      __typename: "DirectReport",
      alias: string,
      hireDate: string,
      personId: string,
      jobLevel: number,
      isManager: boolean,
      firstName: string,
      lastName: string,
      email: string,
    } > | null,
  },
};

export type GetPapiDirectReportsQueryVariables = {
  alias: string,
};

export type GetPapiDirectReportsQuery = {
  getPapiDirectReports?:  {
    __typename: "ManagerPapiProfile",
    alias?: string | null,
    directReports?:  Array< {
      __typename: "PapiDirectReportProfile",
      username?: string | null,
      personId?: string | null,
      jobLevel?: number | null,
      isManager?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      hrJobTitle?: string | null,
    } > | null,
  } | null,
};

export type getPromoReadinessRecordQueryVariables = {
  alias: string,
};

export type getPromoReadinessRecordQuery = {
  getPromoReadinessRecord?:  {
    __typename: "PromoReadinessRecord",
    alias: string,
    createdAt: string,
    updatedAt: string,
    id: string,
    certList?: Array< string > | null,
    techPillar?: Array< string > | null,
    tfcMembership: string,
    rating: string,
    feedbackProviders?: Array< string > | null,
    otherContributions: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type GetDirectReportsQueryVariables = {
  manager: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserProfileFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetDirectReportsQuery = {
  listProfilesByManager?:  {
    __typename: "ModelUserProfileConnection",
    items:  Array< {
      __typename: "UserProfile",
      alias: string,
      email: string,
      hireDate?: string | null,
      isManager: boolean,
      jobLevel: number,
      name?: string | null,
      _deleted?: boolean | null,
      _version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  alias: string,
};

export type GetUserQuery = {
  getUser:  {
    __typename: "PeopleProfile",
    alias: string,
    jobLevel: number,
    hrJobTitle: string,
    isManager: boolean,
    firstName: string,
    lastName: string,
    email: string,
    managerAlias?: string | null,
    managerName?: string | null,
  },
};

export type getPeopleProfileQueryVariables = {
  alias: string,
};

export type getPeopleProfileQuery = {
  getPeopleprofile?:  {
    __typename: "PapiProfile",
    username: string,
    personId: string,
    firstName: string,
    lastName?: string | null,
    jobLevel?: string | null,
    job?:  Array< {
      __typename: "PapiJobProfile",
      hireDate?: string | null,
      lastHireDate?: string | null,
      costCenterEntryDate?: string | null,
      jobEntryDate?: string | null,
      departmentEntryDate?: string | null,
    } > | null,
    emailAddress?: string | null,
    isManager?: string | null,
    businessTitle?: string | null,
    jobFamilyName?: string | null,
    manager?: string | null,
    skipManager?: string | null,
    employeeId?: string | null,
    costCenterId: number,
    lastHireDate?: string | null,
    location?:  Array< {
      __typename: "PapiLocationProfile",
      city?: string | null,
      name?: string | null,
    } > | null,
  } | null,
};

export type FindUserQueryVariables = {
  alias: string,
};

export type FindUserQuery = {
  findUser?:  {
    __typename: "PeopleProfile",
    alias: string,
    jobLevel: number,
    hrJobTitle: string,
    isManager: boolean,
    firstName: string,
    lastName: string,
    email: string,
    managerAlias?: string | null,
    managerName?: string | null,
  } | null,
};

export type GetFileDownloadUrlQueryVariables = {
  referenceId?: string | null,
  fileRecordType?: FileRecordType | null,
  fileRecordId?: string | null,
};

export type GetFileDownloadUrlQuery = {
  getFileDownloadUrl:  {
    __typename: "DownloadableFile",
    referenceId?: string | null,
    fileRecordType?: FileRecordType | null,
    fileRecordId?: string | null,
    presignedURL?: string | null,
  },
};

export type ListBaselineRoleDimensionQueryVariables = {
  filter?: ModelBaselineRoleDimensionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBaselineRoleDimensionQuery = {
  listBaselineRoleDimension?:  {
    __typename: "ModelBaselineRoleDimensionConnection",
    items:  Array< {
      __typename: "BaselineRoleDimension",
      createdAt: string,
      id: string,
      updatedAt: string,
      jobLevel: number,
      title: string,
      description: string,
      jobRole: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      lastModifiedBy: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListBaselineRoleDimensionsByLevelQueryVariables = {
  jobLevel: number,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBaselineRoleDimensionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBaselineRoleDimensionsByLevelQuery = {
  listBaselineRoleDimensionsByLevel?:  {
    __typename: "ModelBaselineRoleDimensionConnection",
    items:  Array< {
      __typename: "BaselineRoleDimension",
      createdAt: string,
      id: string,
      updatedAt: string,
      jobLevel: number,
      title: string,
      description: string,
      jobRole: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      lastModifiedBy: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetBaselineRoleDimensionQueryVariables = {
  id: string,
};

export type GetBaselineRoleDimensionQuery = {
  getBaselineRoleDimension?:  {
    __typename: "BaselineRoleDimension",
    createdAt: string,
    id: string,
    updatedAt: string,
    jobLevel: number,
    title: string,
    description: string,
    jobRole: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type GetWorkSummaryQueryVariables = {
  id: string,
  peerReviewFilter?: ModelPeerReviewFilterInput | null,
};

export type GetWorkSummaryQuery = {
  getWorkSummary?:  {
    __typename: "WorkSummary",
    createdAt: string,
    id: string,
    coOwners?: Array< string > | null,
    updatedAt: string,
    alias: string,
    candidatePathId?: string | null,
    content: string,
    contentVersion?: number | null,
    leadershipPrinciples?: Array< string > | null,
    status?: WorkSummaryStatus | null,
    statusReason?: WorkSummaryStatusReason | null,
    baselineRoleDimensionIds?: Array< string > | null,
    submittedAt?: string | null,
    title: string,
    type: WorkSummaryType,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        name: string,
      } | null >,
    } | null,
    documents?:  {
      __typename: "ModelWorkSummaryFilesConnection",
      items:  Array< {
        __typename: "WorkSummaryFiles",
        id: string,
        fileRecord:  {
          __typename: "FileRecord",
          createdAt: string,
          id: string,
          owner?: string | null,
          updatedAt: string,
          fileDateModified: number,
          fileName: string,
          fileSizeBytes: number,
          status: FileRecordStatus,
          s3Key: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    peerReviews?:  {
      __typename: "ModelPeerReviewConnection",
      items:  Array< {
        __typename: "PeerReview",
        createdAt: string,
        id: string,
        coOwners?: Array< string > | null,
        updatedAt: string,
        reviewerAlias: string,
        reviewer:  {
          __typename: "UserProfile",
          name?: string | null,
        },
        reviewStatus?: PeerReviewStatus | null,
        reviewType: PeerReviewType,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListWorkSummariesByAliasQueryVariables = {
  alias: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorkSummaryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkSummariesByAliasQuery = {
  listWorkSummariesByAlias?:  {
    __typename: "ModelWorkSummaryConnection",
    items:  Array< {
      __typename: "WorkSummary",
      createdAt: string,
      id: string,
      coOwners?: Array< string > | null,
      updatedAt: string,
      alias: string,
      baselineRoleDimensionIds?: Array< string > | null,
      candidatePathId?: string | null,
      leadershipPrinciples?: Array< string > | null,
      status?: WorkSummaryStatus | null,
      statusReason?: WorkSummaryStatusReason | null,
      submittedAt?: string | null,
      title: string,
      type: WorkSummaryType,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
        } | null >,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
        } | null >,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListWorkSummariesByAliasAdminQueryVariables = {
  alias: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorkSummaryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkSummariesByAliasAdminQuery = {
  listWorkSummariesByAlias?:  {
    __typename: "ModelWorkSummaryConnection",
    items:  Array< {
      __typename: "WorkSummary",
      createdAt: string,
      id: string,
      coOwners?: Array< string > | null,
      updatedAt: string,
      alias: string,
      candidatePathId?: string | null,
      status?: WorkSummaryStatus | null,
      statusReason?: WorkSummaryStatusReason | null,
      submittedAt?: string | null,
      title: string,
      type: WorkSummaryType,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
        } | null >,
      } | null,
      peerReviews?:  {
        __typename: "ModelPeerReviewConnection",
        items:  Array< {
          __typename: "PeerReview",
          id: string,
          reviewerAlias: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      candidatePath?:  {
        __typename: "PromoPath",
        id: string,
        name?: string | null,
        manager?: string | null,
        _version: number,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListWorkSummariesByAliasAndCategoryQueryVariables = {
  alias: string,
  categoryId: string,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkSummariesByAliasAndCategoryQuery = {
  listWorkSummariesByAlias?:  {
    __typename: "ModelWorkSummaryConnection",
    items:  Array< {
      __typename: "WorkSummary",
      createdAt: string,
      id: string,
      coOwners?: Array< string > | null,
      updatedAt: string,
      alias: string,
      candidatePathId?: string | null,
      leadershipPrinciples?: Array< string > | null,
      status?: WorkSummaryStatus | null,
      statusReason?: WorkSummaryStatusReason | null,
      submittedAt?: string | null,
      title: string,
      type: WorkSummaryType,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          status: CategoryStatus,
        } | null >,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
        } | null >,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListWorkSummariesByCandidatePathQueryVariables = {
  candidatePathId: string,
  submittedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorkSummaryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkSummariesByCandidatePathQuery = {
  listWorkSummariesByCandidatePath?:  {
    __typename: "ModelWorkSummaryConnection",
    items:  Array< {
      __typename: "WorkSummary",
      createdAt: string,
      id: string,
      coOwners?: Array< string > | null,
      updatedAt: string,
      alias: string,
      candidatePathId?: string | null,
      leadershipPrinciples?: Array< string > | null,
      status?: WorkSummaryStatus | null,
      statusReason?: WorkSummaryStatusReason | null,
      submittedAt?: string | null,
      title: string,
      type: WorkSummaryType,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
        } | null >,
      } | null,
      comments?:  {
        __typename: "ModelCommentConnection",
        items:  Array< {
          __typename: "Comment",
          id: string,
        } | null >,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFileInfoQueryVariables = {
  id: string,
};

export type GetFileInfoQuery = {
  getFileRecord?:  {
    __typename: "FileRecord",
    createdAt: string,
    id: string,
    owner?: string | null,
    updatedAt: string,
    fileDateModified: number,
    fileName: string,
    fileSizeBytes: number,
    status: FileRecordStatus,
    s3Key: string,
    storageIdKey: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetFileRecordQueryVariables = {
  id: string,
};

export type GetFileRecordQuery = {
  getFileRecord?:  {
    __typename: "FileRecord",
    createdAt: string,
    id: string,
    owner?: string | null,
    updatedAt: string,
    fileDateModified: number,
    fileName: string,
    fileSizeBytes: number,
    status: FileRecordStatus,
    s3Key: string,
    storageIdKey: string,
    workSummaries?:  {
      __typename: "ModelWorkSummaryFilesConnection",
      items:  Array< {
        __typename: "WorkSummaryFiles",
        id: string,
        workSummaryID: string,
        workSummary:  {
          __typename: "WorkSummary",
          title: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    users?:  {
      __typename: "ModelSharedUserFilesConnection",
      items:  Array< {
        __typename: "SharedUserFiles",
        id: string,
        userProfileAlias: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFilesByOwnerQueryVariables = {
  owner: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFileRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFilesByOwnerQuery = {
  listFilesByOwner?:  {
    __typename: "ModelFileRecordConnection",
    items:  Array< {
      __typename: "FileRecord",
      createdAt: string,
      id: string,
      owner?: string | null,
      updatedAt: string,
      fileDateModified: number,
      fileName: string,
      fileSizeBytes: number,
      status: FileRecordStatus,
      s3Key: string,
      storageIdKey: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCandidatePromoPathQueryVariables = {
  id: string,
};

export type GetCandidatePromoPathQuery = {
  getPromoPath?:  {
    __typename: "PromoPath",
    id: string,
    createdAt: string,
    updatedAt: string,
    allowAttachments?: boolean | null,
    candidate?: string | null,
    categoryIds?: Array< string > | null,
    status: PromoPathStatus,
    manager?: string | null,
    name?: string | null,
    role?: string | null,
    startLevel?: number | null,
    suggestedLPs?: Array< string > | null,
    targetDate?: string | null,
    targetLevel?: number | null,
    templateId?: string | null,
    totalCompleted?: number | null,
    totalRequired?: number | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        createdAt: string,
        id: string,
        updatedAt: string,
        description: string,
        isDefault?: string | null,
        name: string,
        role: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    template?:  {
      __typename: "PromoPath",
      createdAt: string,
      id: string,
      updatedAt: string,
      allowAttachments?: boolean | null,
      categoryIds?: Array< string > | null,
      name?: string | null,
      status: PromoPathStatus,
      role?: string | null,
      startLevel?: number | null,
      suggestedLPs?: Array< string > | null,
      targetLevel?: number | null,
      totalRequired?: number | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          createdAt: string,
          id: string,
          updatedAt: string,
          description: string,
          isDefault?: string | null,
          name: string,
          role: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    workSummaries?:  {
      __typename: "ModelWorkSummaryConnection",
      items:  Array< {
        __typename: "WorkSummary",
        id: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPromoPathsByCandidateQueryVariables = {
  candidate: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPromoPathFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPromoPathsByCandidateQuery = {
  listPromoPathsByCandidate?:  {
    __typename: "ModelPromoPathConnection",
    items:  Array< {
      __typename: "PromoPath",
      id: string,
      createdAt: string,
      updatedAt: string,
      allowAttachments?: boolean | null,
      candidate?: string | null,
      status: PromoPathStatus,
      manager?: string | null,
      name?: string | null,
      role?: string | null,
      startLevel?: number | null,
      suggestedLPs?: Array< string > | null,
      targetDate?: string | null,
      targetLevel?: number | null,
      templateId?: string | null,
      totalCompleted?: number | null,
      totalRequired?: number | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          createdAt: string,
          id: string,
          updatedAt: string,
          description: string,
          isDefault?: string | null,
          name: string,
          role: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      template?:  {
        __typename: "PromoPath",
        createdAt: string,
        id: string,
        updatedAt: string,
        allowAttachments?: boolean | null,
        categoryIds?: Array< string > | null,
        status: PromoPathStatus,
        name?: string | null,
        role?: string | null,
        startLevel?: number | null,
        suggestedLPs?: Array< string > | null,
        targetLevel?: number | null,
        totalRequired?: number | null,
        categories?:  {
          __typename: "ModelCategoryConnection",
          items:  Array< {
            __typename: "Category",
            createdAt: string,
            id: string,
            updatedAt: string,
            description: string,
            isDefault?: string | null,
            name: string,
            role: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      workSummaries?:  {
        __typename: "ModelWorkSummaryConnection",
        items:  Array< {
          __typename: "WorkSummary",
          id: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCandidatePathsByManagerQueryVariables = {
  manager: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPromoPathFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCandidatePathsByManagerQuery = {
  listPromoPathsByManagerAndType?:  {
    __typename: "ModelPromoPathConnection",
    items:  Array< {
      __typename: "PromoPath",
      id: string,
      createdAt: string,
      updatedAt: string,
      allowAttachments?: boolean | null,
      candidate?: string | null,
      status: PromoPathStatus,
      manager?: string | null,
      name?: string | null,
      role?: string | null,
      startLevel?: number | null,
      suggestedLPs?: Array< string > | null,
      targetDate?: string | null,
      targetLevel?: number | null,
      templateId?: string | null,
      totalCompleted?: number | null,
      totalRequired?: number | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          createdAt: string,
          id: string,
          updatedAt: string,
          description: string,
          isDefault?: string | null,
          name: string,
          role: string,
          status: CategoryStatus,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      template?:  {
        __typename: "PromoPath",
        createdAt: string,
        id: string,
        updatedAt: string,
        allowAttachments?: boolean | null,
        status: PromoPathStatus,
        name?: string | null,
        role?: string | null,
        startLevel?: number | null,
        suggestedLPs?: Array< string > | null,
        targetLevel?: number | null,
        totalRequired?: number | null,
        categories?:  {
          __typename: "ModelCategoryConnection",
          items:  Array< {
            __typename: "Category",
            createdAt: string,
            id: string,
            updatedAt: string,
            description: string,
            isDefault?: string | null,
            name: string,
            role: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          } | null >,
          nextToken?: string | null,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      workSummaries?:  {
        __typename: "ModelWorkSummaryConnection",
        items:  Array< {
          __typename: "WorkSummary",
          createdAt: string,
          id: string,
          coOwners?: Array< string > | null,
          updatedAt: string,
          alias: string,
          candidatePathId?: string | null,
          leadershipPrinciples?: Array< string > | null,
          status?: WorkSummaryStatus | null,
          statusReason?: WorkSummaryStatusReason | null,
          submittedAt?: string | null,
          title: string,
          type: WorkSummaryType,
          categories?:  {
            __typename: "ModelCategoryConnection",
            items:  Array< {
              __typename: "Category",
              id: string,
              name: string,
              status: CategoryStatus,
            } | null >,
          } | null,
          comments?:  {
            __typename: "ModelCommentConnection",
            items:  Array< {
              __typename: "Comment",
              id: string,
            } | null >,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCandidatePathsByTemplateQueryVariables = {
  templateId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPromoPathFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCandidatePathsByTemplateQuery = {
  listPromoPathsByTemplateAndType?:  {
    __typename: "ModelPromoPathConnection",
    items:  Array< {
      __typename: "PromoPath",
      id: string,
      createdAt: string,
      updatedAt: string,
      allowAttachments?: boolean | null,
      candidate?: string | null,
      status: PromoPathStatus,
      manager?: string | null,
      name?: string | null,
      role?: string | null,
      startLevel?: number | null,
      suggestedLPs?: Array< string > | null,
      targetDate?: string | null,
      targetLevel?: number | null,
      templateId?: string | null,
      totalCompleted?: number | null,
      totalRequired?: number | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          createdAt: string,
          id: string,
          updatedAt: string,
          description: string,
          isDefault?: string | null,
          name: string,
          role: string,
          status: CategoryStatus,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      workSummaries?:  {
        __typename: "ModelWorkSummaryConnection",
        items:  Array< {
          __typename: "WorkSummary",
          createdAt: string,
          id: string,
          coOwners?: Array< string > | null,
          updatedAt: string,
          alias: string,
          candidatePathId?: string | null,
          leadershipPrinciples?: Array< string > | null,
          status?: WorkSummaryStatus | null,
          statusReason?: WorkSummaryStatusReason | null,
          submittedAt?: string | null,
          title: string,
          type: WorkSummaryType,
          categories?:  {
            __typename: "ModelCategoryConnection",
            items:  Array< {
              __typename: "Category",
              id: string,
              name: string,
            } | null >,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPromoPathQueryVariables = {
  id: string,
};

export type GetPromoPathQuery = {
  getPromoPath?:  {
    __typename: "PromoPath",
    createdAt: string,
    id: string,
    updatedAt: string,
    allowAttachments?: boolean | null,
    status: PromoPathStatus,
    name?: string | null,
    role?: string | null,
    startLevel?: number | null,
    suggestedLPs?: Array< string > | null,
    targetLevel?: number | null,
    totalRequired?: number | null,
    descendants?:  {
      __typename: "ModelPromoPathConnection",
      items:  Array< {
        __typename: "PromoPath",
        id: string,
        candidate?: string | null,
        name?: string | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        createdAt: string,
        id: string,
        updatedAt: string,
        description: string,
        isDefault?: string | null,
        name: string,
        role: string,
        status: CategoryStatus,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPromoPathsByOwnerQueryVariables = {
  manager: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPromoPathFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPromoPathsByOwnerQuery = {
  listPromoPathsByManagerAndType?:  {
    __typename: "ModelPromoPathConnection",
    items:  Array< {
      __typename: "PromoPath",
      createdAt: string,
      id: string,
      updatedAt: string,
      allowAttachments?: boolean | null,
      categoryIds?: Array< string > | null,
      status: PromoPathStatus,
      name?: string | null,
      role?: string | null,
      startLevel?: number | null,
      suggestedLPs?: Array< string > | null,
      targetLevel?: number | null,
      descendants?:  {
        __typename: "ModelPromoPathConnection",
        items:  Array< {
          __typename: "PromoPath",
          id: string,
          candidate?: string | null,
          name?: string | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          createdAt: string,
          id: string,
          updatedAt: string,
          description: string,
          isDefault?: string | null,
          name: string,
          role: string,
          status: CategoryStatus,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    createdAt: string,
    id: string,
    updatedAt: string,
    description: string,
    isDefault?: string | null,
    name: string,
    role: string,
    status: CategoryStatus,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCategoriesByRoleQueryVariables = {
  role: string,
  isDefault?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoriesByRoleQuery = {
  listCategoriesByRole?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      createdAt: string,
      id: string,
      updatedAt: string,
      description: string,
      isDefault?: string | null,
      name: string,
      role: string,
      status: CategoryStatus,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserIdentityIdQueryVariables = {
  alias: string,
};

export type GetUserIdentityIdQuery = {
  getUserProfile?:  {
    __typename: "UserProfile",
    identityId?: string | null,
  } | null,
};

export type GetUserProfileWithIdentityQueryVariables = {
  alias: string,
};

export type GetUserProfileWithIdentityQuery = {
  getUserProfile?:  {
    __typename: "UserProfile",
    alias: string,
    createdAt: string,
    updatedAt: string,
    email: string,
    hasLoggedIn?: boolean | null,
    hireDate?: string | null,
    identityId?: string | null,
    isManager: boolean,
    jobLevel: number,
    jobTitle?: string | null,
    manager: string,
    managerProfile?:  {
      __typename: "UserProfile",
      alias: string,
      email: string,
      name?: string | null,
    } | null,
    name?: string | null,
    orgName?: string | null,
    personId?: string | null,
    preferences?:  {
      __typename: "UserPreferences",
      landingPage?: string | null,
      visualMode?: VisualMode | null,
      density?: Density | null,
    } | null,
    status?: UserProfileStatus | null,
    sharedFiles?:  {
      __typename: "ModelSharedUserFilesConnection",
      items:  Array< {
        __typename: "SharedUserFiles",
        id: string,
        fileRecord:  {
          __typename: "FileRecord",
          createdAt: string,
          id: string,
          owner?: string | null,
          updatedAt: string,
          fileDateModified: number,
          fileName: string,
          fileSizeBytes: number,
          status: FileRecordStatus,
          s3Key: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetUserProfileQueryVariables = {
  alias: string,
};

export type GetUserProfileQuery = {
  getUserProfile?:  {
    __typename: "UserProfile",
    alias: string,
    createdAt: string,
    updatedAt: string,
    email: string,
    hasLoggedIn?: boolean | null,
    hireDate?: string | null,
    isManager: boolean,
    jobLevel: number,
    jobTitle?: string | null,
    manager: string,
    managerProfile?:  {
      __typename: "UserProfile",
      alias: string,
      email: string,
      name?: string | null,
    } | null,
    name?: string | null,
    orgName?: string | null,
    personId?: string | null,
    preferences?:  {
      __typename: "UserPreferences",
      landingPage?: string | null,
      visualMode?: VisualMode | null,
      density?: Density | null,
    } | null,
    status?: UserProfileStatus | null,
    sharedFiles?:  {
      __typename: "ModelSharedUserFilesConnection",
      items:  Array< {
        __typename: "SharedUserFiles",
        id: string,
        fileRecord:  {
          __typename: "FileRecord",
          createdAt: string,
          id: string,
          owner?: string | null,
          updatedAt: string,
          fileDateModified: number,
          fileName: string,
          fileSizeBytes: number,
          status: FileRecordStatus,
          s3Key: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetWorkSummaryFilesQueryVariables = {
  id: string,
};

export type GetWorkSummaryFilesQuery = {
  getWorkSummaryFiles?:  {
    __typename: "WorkSummaryFiles",
    id: string,
    workSummaryID: string,
    fileRecordID: string,
    workSummary:  {
      __typename: "WorkSummary",
      id: string,
      title: string,
      documents?:  {
        __typename: "ModelWorkSummaryFilesConnection",
        items:  Array< {
          __typename: "WorkSummaryFiles",
          id: string,
          fileRecordID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    fileRecord:  {
      __typename: "FileRecord",
      id: string,
      storageIdKey: string,
      workSummaries?:  {
        __typename: "ModelWorkSummaryFilesConnection",
        items:  Array< {
          __typename: "WorkSummaryFiles",
          id: string,
          workSummaryID: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelSharedUserFilesConnection",
        items:  Array< {
          __typename: "SharedUserFiles",
          id: string,
          userProfileAlias: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type GetSharedUserFilesQueryVariables = {
  id: string,
};

export type GetSharedUserFilesQuery = {
  getSharedUserFiles?:  {
    __typename: "SharedUserFiles",
    id: string,
    fileRecordID: string,
    userProfileAlias: string,
    fileRecord:  {
      __typename: "FileRecord",
      createdAt: string,
      id: string,
      owner?: string | null,
      updatedAt: string,
      fileDateModified: number,
      fileName: string,
      fileSizeBytes: number,
      status: FileRecordStatus,
      s3Key: string,
      workSummaries?:  {
        __typename: "ModelWorkSummaryFilesConnection",
        items:  Array< {
          __typename: "WorkSummaryFiles",
          id: string,
          workSummaryID: string,
          fileRecordID: string,
          workSummary:  {
            __typename: "WorkSummary",
            createdAt: string,
            id: string,
            coOwners?: Array< string > | null,
            updatedAt: string,
            alias: string,
            candidatePathId?: string | null,
            categoryIds?: Array< string > | null,
            content: string,
            leadershipPrinciples?: Array< string > | null,
            status?: WorkSummaryStatus | null,
            statusReason?: WorkSummaryStatusReason | null,
            submittedAt?: string | null,
            title: string,
            type: WorkSummaryType,
            categories?:  {
              __typename: "ModelCategoryConnection",
              items:  Array< {
                __typename: "Category",
                createdAt: string,
                id: string,
                updatedAt: string,
                description: string,
                isDefault?: string | null,
                name: string,
                role: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
            } | null,
            documents?:  {
              __typename: "ModelWorkSummaryFilesConnection",
              items:  Array< {
                __typename: "WorkSummaryFiles",
                id: string,
                workSummaryID: string,
                fileRecordID: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          fileRecord:  {
            __typename: "FileRecord",
            createdAt: string,
            id: string,
            owner?: string | null,
            updatedAt: string,
            fileDateModified: number,
            fileName: string,
            fileSizeBytes: number,
            status: FileRecordStatus,
            s3Key: string,
            workSummaries?:  {
              __typename: "ModelWorkSummaryFilesConnection",
              items:  Array< {
                __typename: "WorkSummaryFiles",
                id: string,
                workSummaryID: string,
                fileRecordID: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            users?:  {
              __typename: "ModelSharedUserFilesConnection",
              items:  Array< {
                __typename: "SharedUserFiles",
                id: string,
                fileRecordID: string,
                userProfileAlias: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      users?:  {
        __typename: "ModelSharedUserFilesConnection",
        items:  Array< {
          __typename: "SharedUserFiles",
          id: string,
          fileRecordID: string,
          userProfileAlias: string,
          fileRecord:  {
            __typename: "FileRecord",
            createdAt: string,
            id: string,
            owner?: string | null,
            updatedAt: string,
            fileDateModified: number,
            fileName: string,
            fileSizeBytes: number,
            status: FileRecordStatus,
            s3Key: string,
            workSummaries?:  {
              __typename: "ModelWorkSummaryFilesConnection",
              items:  Array< {
                __typename: "WorkSummaryFiles",
                id: string,
                workSummaryID: string,
                fileRecordID: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            users?:  {
              __typename: "ModelSharedUserFilesConnection",
              items:  Array< {
                __typename: "SharedUserFiles",
                id: string,
                fileRecordID: string,
                userProfileAlias: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          userProfile:  {
            __typename: "UserProfile",
            alias: string,
            createdAt: string,
            owner?: string | null,
            updatedAt: string,
            email: string,
            hasLoggedIn?: boolean | null,
            personId?: string | null,
            preferences?:  {
              __typename: "UserPreferences",
              landingPage?: string | null,
              visualMode?: VisualMode | null,
              density?: Density | null,
            } | null,
            sharedFiles?:  {
              __typename: "ModelSharedUserFilesConnection",
              items:  Array< {
                __typename: "SharedUserFiles",
                id: string,
                fileRecordID: string,
                userProfileAlias: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    userProfile:  {
      __typename: "UserProfile",
      alias: string,
      createdAt: string,
      owner?: string | null,
      updatedAt: string,
      email: string,
      hasLoggedIn?: boolean | null,
      personId?: string | null,
      preferences?:  {
        __typename: "UserPreferences",
        landingPage?: string | null,
        visualMode?: VisualMode | null,
        density?: Density | null,
      } | null,
      sharedFiles?:  {
        __typename: "ModelSharedUserFilesConnection",
        items:  Array< {
          __typename: "SharedUserFiles",
          id: string,
          fileRecordID: string,
          userProfileAlias: string,
          fileRecord:  {
            __typename: "FileRecord",
            createdAt: string,
            id: string,
            owner?: string | null,
            updatedAt: string,
            fileDateModified: number,
            fileName: string,
            fileSizeBytes: number,
            status: FileRecordStatus,
            s3Key: string,
            workSummaries?:  {
              __typename: "ModelWorkSummaryFilesConnection",
              items:  Array< {
                __typename: "WorkSummaryFiles",
                id: string,
                workSummaryID: string,
                fileRecordID: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            users?:  {
              __typename: "ModelSharedUserFilesConnection",
              items:  Array< {
                __typename: "SharedUserFiles",
                id: string,
                fileRecordID: string,
                userProfileAlias: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          userProfile:  {
            __typename: "UserProfile",
            alias: string,
            createdAt: string,
            owner?: string | null,
            updatedAt: string,
            email: string,
            hasLoggedIn?: boolean | null,
            personId?: string | null,
            preferences?:  {
              __typename: "UserPreferences",
              landingPage?: string | null,
              visualMode?: VisualMode | null,
              density?: Density | null,
            } | null,
            sharedFiles?:  {
              __typename: "ModelSharedUserFilesConnection",
              items:  Array< {
                __typename: "SharedUserFiles",
                id: string,
                fileRecordID: string,
                userProfileAlias: string,
                createdAt: string,
                updatedAt: string,
                _version: number,
                _deleted?: boolean | null,
                _lastChangedAt: number,
                owner?: string | null,
              } | null >,
              nextToken?: string | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type GetPeerReviewQueryVariables = {
  id: string,
};

export type GetPeerReviewQuery = {
  getPeerReview?:  {
    __typename: "PeerReview",
    createdAt: string,
    id: string,
    coOwners?: Array< string > | null,
    updatedAt: string,
    requiredBy: string,
    candidatePathId?: string | null,
    reviewerAlias: string,
    reviewer:  {
      __typename: "UserProfile",
      name?: string | null,
    },
    reviewStatus?: PeerReviewStatus | null,
    reviewType: PeerReviewType,
    content?: string | null,
    reviewedContentVersion?: number | null,
    candidatePath?:  {
      __typename: "PromoPath",
      id: string,
      candidate?: string | null,
      manager?: string | null,
      name?: string | null,
      targetDate?: string | null,
      targetLevel?: number | null,
      template?:  {
        __typename: "PromoPath",
        id: string,
        name?: string | null,
        targetLevel?: number | null,
      } | null,
    } | null,
    workSummary?:  {
      __typename: "WorkSummary",
      id: string,
      alias: string,
      content: string,
      contentVersion?: number | null,
      status?: WorkSummaryStatus | null,
      title: string,
      type: WorkSummaryType,
      categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          description: string,
        } | null >,
      } | null,
      documents?:  {
        __typename: "ModelWorkSummaryFilesConnection",
        items:  Array< {
          __typename: "WorkSummaryFiles",
          id: string,
          fileRecord:  {
            __typename: "FileRecord",
            createdAt: string,
            id: string,
            owner?: string | null,
            updatedAt: string,
            fileDateModified: number,
            fileName: string,
            fileSizeBytes: number,
            status: FileRecordStatus,
            s3Key: string,
            storageIdKey: string,
            _deleted?: boolean | null,
          },
          _deleted?: boolean | null,
        } | null >,
      } | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPeerReviewsByWorkSummaryQueryVariables = {
  workSummaryId: string,
  reviewType: ModelPeerReviewTypeInput,
  sortDirection?: ModelSortDirection | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPeerReviewsByWorkSummaryQuery = {
  getWorkSummary?:  {
    __typename: "WorkSummary",
    contentVersion?: number | null,
    peerReviews?:  {
      __typename: "ModelPeerReviewConnection",
      items:  Array< {
        __typename: "PeerReview",
        createdAt: string,
        id: string,
        coOwners?: Array< string > | null,
        updatedAt: string,
        reviewerAlias: string,
        reviewer:  {
          __typename: "UserProfile",
          name?: string | null,
        },
        reviewStatus?: PeerReviewStatus | null,
        reviewType: PeerReviewType,
        content?: string | null,
        reviewedContentVersion?: number | null,
        requiredBy: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListPeerReviewsByCandidatePathQueryVariables = {
  candidatePathId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPeerReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPeerReviewsByCandidatePathQuery = {
  listPeerReviewsByCandidatePath?:  {
    __typename: "ModelPeerReviewConnection",
    items:  Array< {
      __typename: "PeerReview",
      createdAt: string,
      id: string,
      coOwners?: Array< string > | null,
      updatedAt: string,
      content?: string | null,
      reviewerAlias: string,
      reviewStatus?: PeerReviewStatus | null,
      reviewType: PeerReviewType,
      reviewedContentVersion?: number | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPeerReviewsByReviewerQueryVariables = {
  reviewerAlias: string,
  reviewStatus?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPeerReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPeerReviewsByReviewerQuery = {
  listPeerReviewsByReviewer?:  {
    __typename: "ModelPeerReviewConnection",
    items:  Array< {
      __typename: "PeerReview",
      createdAt: string,
      id: string,
      coOwners?: Array< string > | null,
      updatedAt: string,
      requiredBy: string,
      content?: string | null,
      reviewerAlias: string,
      reviewer:  {
        __typename: "UserProfile",
        name?: string | null,
      },
      reviewStatus?: PeerReviewStatus | null,
      reviewType: PeerReviewType,
      reviewedContentVersion?: number | null,
      candidatePath?:  {
        __typename: "PromoPath",
        id: string,
        candidate?: string | null,
        name?: string | null,
        manager?: string | null,
        targetLevel?: number | null,
        template?:  {
          __typename: "PromoPath",
          id: string,
          name?: string | null,
          targetLevel?: number | null,
        } | null,
      } | null,
      workSummary?:  {
        __typename: "WorkSummary",
        id: string,
        title: string,
        alias: string,
        content: string,
        type: WorkSummaryType,
        categories?:  {
          __typename: "ModelCategoryConnection",
          items:  Array< {
            __typename: "Category",
            id: string,
            name: string,
          } | null >,
        } | null,
        comments?:  {
          __typename: "ModelCommentConnection",
          items:  Array< {
            __typename: "Comment",
            id: string,
          } | null >,
        } | null,
        contentVersion?: number | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    createdAt: string,
    id: string,
    updatedAt: string,
    type: CommentType,
    content: string,
    workSummaryId?: string | null,
    status: CommentStatus,
    author:  {
      __typename: "UserProfile",
      alias: string,
      name?: string | null,
    },
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCommentsByWorkSummaryAndTypeQueryVariables = {
  workSummaryId: string,
  type: CommentType,
  status: CommentStatus,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsByWorkSummaryAndTypeQuery = {
  listCommentsByWorkSummary?:  {
    __typename: "ModelCommentConnection",
    items:  Array< {
      __typename: "Comment",
      createdAt: string,
      id: string,
      updatedAt: string,
      type: CommentType,
      content: string,
      status: CommentStatus,
      workSummaryId?: string | null,
      author:  {
        __typename: "UserProfile",
        alias: string,
        name?: string | null,
      },
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListAuditTrailsByTypeAndIdQueryVariables = {
  recordId: string,
  recordType: AuditTrailRecordType,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAuditTrailsByTypeAndIdQuery = {
  listAuditTrailsByTypeAndId?:  {
    __typename: "ModelAuditTrailsConnection",
    items:  Array< {
      __typename: "AuditTrails",
      id: string,
      modifiedBy?: string | null,
      recordId: string,
      recordType: AuditTrailRecordType,
      humanReadableLog: Array< string >,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
  } | null,
};

export type GetDocumentReviewSessionRecordQueryVariables = {
  id: string,
};

export type GetDocumentReviewSessionRecordQuery = {
  getDocumentReviewSessionRecord?:  {
    __typename: "DocumentReviewSessionRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    ownerAlias: string,
    candidateAlias: string,
    workdocsLink: string,
    chimeLink?: string | null,
    sessionStart: string,
    sessionState: DocumentReviewSessionState,
    overrideReason?: string | null,
    documentReviewers?:  {
      __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
      items:  Array< {
        __typename: "DocumentReviewSessionToReviewerMapping",
        id: string,
        documentReviewSessionRecordID: string,
        calibratedDocumentReviewerRecordID: string,
        documentReviewSessionRecord:  {
          __typename: "DocumentReviewSessionRecord",
          id: string,
          createdAt: string,
          updatedAt: string,
          ownerAlias: string,
          candidateAlias: string,
          workdocsLink: string,
          chimeLink?: string | null,
          sessionStart: string,
          sessionState: DocumentReviewSessionState,
          overrideReason?: string | null,
          documentReviewers?:  {
            __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
            items:  Array< {
              __typename: "DocumentReviewSessionToReviewerMapping",
              id: string,
              documentReviewSessionRecordID: string,
              calibratedDocumentReviewerRecordID: string,
              createdAt: string,
              updatedAt: string,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          votes?:  {
            __typename: "ModelDocumentReviewSessionVoteRecordConnection",
            items:  Array< {
              __typename: "DocumentReviewSessionVoteRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              reviewerAlias: string,
              documentReviewSession: string,
              documentReady?: DocumentReviewSessionVote | null,
              candidateReady?: DocumentReviewSessionVote | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          lastModifiedBy: string,
          owner?: string | null,
        },
        calibratedDocumentReviewerRecord:  {
          __typename: "CalibratedDocumentReviewerRecord",
          id: string,
          createdAt: string,
          updatedAt: string,
          alias: string,
          completedSessions?: number | null,
          documentReviews?:  {
            __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
            items:  Array< {
              __typename: "DocumentReviewSessionToReviewerMapping",
              id: string,
              documentReviewSessionRecordID: string,
              calibratedDocumentReviewerRecordID: string,
              createdAt: string,
              updatedAt: string,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          lastModifiedBy: string,
          owner?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    votes?:  {
      __typename: "ModelDocumentReviewSessionVoteRecordConnection",
      items:  Array< {
        __typename: "DocumentReviewSessionVoteRecord",
        id: string,
        createdAt: string,
        updatedAt: string,
        reviewerAlias: string,
        documentReviewSession: string,
        documentReady?: DocumentReviewSessionVote | null,
        candidateReady?: DocumentReviewSessionVote | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        lastModifiedBy: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
    coOwners?: Array< string > | null,
  } | null,
};

export type GetDocumentReviewExistsQueryVariables = {
  id: string,
};

export type GetDocumentReviewExistsQuery = {
  getDocumentReviewSessionRecord?:  {
    __typename: "DocumentReviewSessionRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
  } | null,
};

export type GetMinimalDocumentReviewRecordQueryVariables = {
  id: string,
};

export type GetMinimalDocumentReviewRecordQuery = {
  getDocumentReviewSessionRecord?:  {
    __typename: "DocumentReviewSessionRecord",
    id: string,
    ownerAlias: string,
    candidateAlias: string,
    sessionState: DocumentReviewSessionState,
    sessionStart: string,
    workdocsLink: string,
    chimeLink?: string | null,
    coOwners?: Array< string > | null,
    documentReviewers?:  {
      __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
      items:  Array< {
        __typename: "DocumentReviewSessionToReviewerMapping",
        id: string,
        calibratedDocumentReviewerRecord:  {
          __typename: "CalibratedDocumentReviewerRecord",
          id: string,
          alias: string,
        },
      } | null >,
    } | null,
    _version: number,
  } | null,
};

export type GetDocumentReviewersQueryVariables = {
  id: string,
};

export type GetDocumentReviewersQuery = {
  getDocumentReviewSessionRecord?:  {
    __typename: "DocumentReviewSessionRecord",
    id: string,
    sessionState: DocumentReviewSessionState,
    documentReviewers?:  {
      __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
      items:  Array< {
        __typename: "DocumentReviewSessionToReviewerMapping",
        id: string,
        calibratedDocumentReviewerRecord:  {
          __typename: "CalibratedDocumentReviewerRecord",
          id: string,
          alias: string,
        },
        _version: number,
      } | null >,
    } | null,
    _version: number,
  } | null,
};

export type GetDocumentReviewStateQueryVariables = {
  id: string,
};

export type GetDocumentReviewStateQuery = {
  getDocumentReviewSessionRecord?:  {
    __typename: "DocumentReviewSessionRecord",
    id: string,
    sessionState: DocumentReviewSessionState,
    _version: number,
  } | null,
};

export type ListDocumentReviewSessionsByOwnerQueryVariables = {
  ownerAlias: string,
  sessionState?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocumentReviewSessionRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentReviewSessionsByOwnerQuery = {
  listDocumentReviewSessionsByOwner?:  {
    __typename: "ModelDocumentReviewSessionRecordConnection",
    items:  Array< {
      __typename: "DocumentReviewSessionRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      ownerAlias: string,
      candidateAlias: string,
      workdocsLink: string,
      chimeLink?: string | null,
      sessionStart: string,
      sessionState: DocumentReviewSessionState,
      overrideReason?: string | null,
      documentReviewers?:  {
        __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
        items:  Array< {
          __typename: "DocumentReviewSessionToReviewerMapping",
          id: string,
          documentReviewSessionRecordID: string,
          calibratedDocumentReviewerRecordID: string,
          documentReviewSessionRecord:  {
            __typename: "DocumentReviewSessionRecord",
            id: string,
            createdAt: string,
            updatedAt: string,
            ownerAlias: string,
            candidateAlias: string,
            workdocsLink: string,
            chimeLink?: string | null,
            sessionStart: string,
            sessionState: DocumentReviewSessionState,
            overrideReason?: string | null,
            documentReviewers?:  {
              __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
              nextToken?: string | null,
              startedAt?: number | null,
            } | null,
            votes?:  {
              __typename: "ModelDocumentReviewSessionVoteRecordConnection",
              nextToken?: string | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            lastModifiedBy: string,
            owner?: string | null,
          },
          calibratedDocumentReviewerRecord:  {
            __typename: "CalibratedDocumentReviewerRecord",
            id: string,
            createdAt: string,
            updatedAt: string,
            alias: string,
            completedSessions?: number | null,
            documentReviews?:  {
              __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
              nextToken?: string | null,
              startedAt?: number | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            lastModifiedBy: string,
            owner?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      votes?:  {
        __typename: "ModelDocumentReviewSessionVoteRecordConnection",
        items:  Array< {
          __typename: "DocumentReviewSessionVoteRecord",
          id: string,
          createdAt: string,
          updatedAt: string,
          reviewerAlias: string,
          documentReviewSession: string,
          documentReady?: DocumentReviewSessionVote | null,
          candidateReady?: DocumentReviewSessionVote | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          lastModifiedBy: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      lastModifiedBy: string,
      owner?: string | null,
      coOwners?: Array< string > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListDocumentReviewSessionItemsByOwnerQueryVariables = {
  ownerAlias: string,
  sessionState?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocumentReviewSessionRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentReviewSessionItemsByOwnerQuery = {
  listDocumentReviewSessionsByOwner?:  {
    __typename: "ModelDocumentReviewSessionRecordConnection",
    items:  Array< {
      __typename: "DocumentReviewSessionRecord",
      id: string,
      ownerAlias: string,
      candidateAlias: string,
      sessionStart: string,
      sessionState: DocumentReviewSessionState,
      _version: number,
    } | null >,
  } | null,
};

export type GetCalibratedDocumentReviewerRecordQueryVariables = {
  id: string,
};

export type GetCalibratedDocumentReviewerRecordQuery = {
  getCalibratedDocumentReviewerRecord?:  {
    __typename: "CalibratedDocumentReviewerRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    alias: string,
    completedSessions?: number | null,
    documentReviews?:  {
      __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
      items:  Array< {
        __typename: "DocumentReviewSessionToReviewerMapping",
        id: string,
        documentReviewSessionRecordID: string,
        calibratedDocumentReviewerRecordID: string,
        documentReviewSessionRecord:  {
          __typename: "DocumentReviewSessionRecord",
          id: string,
          createdAt: string,
          updatedAt: string,
          ownerAlias: string,
          candidateAlias: string,
          workdocsLink: string,
          chimeLink?: string | null,
          sessionStart: string,
          sessionState: DocumentReviewSessionState,
          overrideReason?: string | null,
          documentReviewers?:  {
            __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
            items:  Array< {
              __typename: "DocumentReviewSessionToReviewerMapping",
              id: string,
              documentReviewSessionRecordID: string,
              calibratedDocumentReviewerRecordID: string,
              createdAt: string,
              updatedAt: string,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          votes?:  {
            __typename: "ModelDocumentReviewSessionVoteRecordConnection",
            items:  Array< {
              __typename: "DocumentReviewSessionVoteRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              reviewerAlias: string,
              documentReviewSession: string,
              documentReady?: DocumentReviewSessionVote | null,
              candidateReady?: DocumentReviewSessionVote | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          lastModifiedBy: string,
          owner?: string | null,
        },
        calibratedDocumentReviewerRecord:  {
          __typename: "CalibratedDocumentReviewerRecord",
          id: string,
          createdAt: string,
          updatedAt: string,
          alias: string,
          completedSessions?: number | null,
          documentReviews?:  {
            __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
            items:  Array< {
              __typename: "DocumentReviewSessionToReviewerMapping",
              id: string,
              documentReviewSessionRecordID: string,
              calibratedDocumentReviewerRecordID: string,
              createdAt: string,
              updatedAt: string,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          lastModifiedBy: string,
          owner?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type ListCalibratedDocumentReviewerRecordsQueryVariables = {
  id?: string | null,
  filter?: ModelCalibratedDocumentReviewerRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCalibratedDocumentReviewerRecordsQuery = {
  listCalibratedDocumentReviewerRecords?:  {
    __typename: "ModelCalibratedDocumentReviewerRecordConnection",
    items:  Array< {
      __typename: "CalibratedDocumentReviewerRecord",
      id: string,
      alias: string,
      completedSessions?: number | null,
      _version: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCalibratedDocumentReviewerRecordByAliasQueryVariables = {
  alias: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCalibratedDocumentReviewerRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCalibratedDocumentReviewerRecordByAliasQuery = {
  getCalibratedDocumentReviewerRecordByAlias?:  {
    __typename: "ModelCalibratedDocumentReviewerRecordConnection",
    items:  Array< {
      __typename: "CalibratedDocumentReviewerRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      alias: string,
      completedSessions?: number | null,
      documentReviews?:  {
        __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
        items:  Array< {
          __typename: "DocumentReviewSessionToReviewerMapping",
          id: string,
          documentReviewSessionRecordID: string,
          calibratedDocumentReviewerRecordID: string,
          documentReviewSessionRecord:  {
            __typename: "DocumentReviewSessionRecord",
            id: string,
            createdAt: string,
            updatedAt: string,
            ownerAlias: string,
            candidateAlias: string,
            workdocsLink: string,
            chimeLink?: string | null,
            sessionStart: string,
            sessionState: DocumentReviewSessionState,
            overrideReason?: string | null,
            documentReviewers?:  {
              __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
              nextToken?: string | null,
              startedAt?: number | null,
            } | null,
            votes?:  {
              __typename: "ModelDocumentReviewSessionVoteRecordConnection",
              nextToken?: string | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            lastModifiedBy: string,
            owner?: string | null,
          },
          calibratedDocumentReviewerRecord:  {
            __typename: "CalibratedDocumentReviewerRecord",
            id: string,
            createdAt: string,
            updatedAt: string,
            alias: string,
            completedSessions?: number | null,
            documentReviews?:  {
              __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
              nextToken?: string | null,
              startedAt?: number | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            lastModifiedBy: string,
            owner?: string | null,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      lastModifiedBy: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDocumentReviewerReviewsByAliasQueryVariables = {
  alias: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCalibratedDocumentReviewerRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetDocumentReviewerReviewsByAliasQuery = {
  getCalibratedDocumentReviewerRecordByAlias?:  {
    __typename: "ModelCalibratedDocumentReviewerRecordConnection",
    items:  Array< {
      __typename: "CalibratedDocumentReviewerRecord",
      alias: string,
      documentReviews?:  {
        __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
        items:  Array< {
          __typename: "DocumentReviewSessionToReviewerMapping",
          documentReviewSessionRecord:  {
            __typename: "DocumentReviewSessionRecord",
            id: string,
            ownerAlias: string,
            candidateAlias: string,
            sessionStart: string,
            sessionState: DocumentReviewSessionState,
          },
        } | null >,
      } | null,
    } | null >,
  } | null,
};

export type GetCalibratedDocumentReviewerRecordExistsByAliasQueryVariables = {
  alias: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCalibratedDocumentReviewerRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCalibratedDocumentReviewerRecordExistsByAliasQuery = {
  getCalibratedDocumentReviewerRecordByAlias?:  {
    __typename: "ModelCalibratedDocumentReviewerRecordConnection",
    items:  Array< {
      __typename: "CalibratedDocumentReviewerRecord",
      id: string,
      alias: string,
    } | null >,
  } | null,
};

export type GetDocumentReviewSessionVoteRecordQueryVariables = {
  id: string,
};

export type GetDocumentReviewSessionVoteRecordQuery = {
  getDocumentReviewSessionVoteRecord?:  {
    __typename: "DocumentReviewSessionVoteRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    reviewerAlias: string,
    documentReviewSession: string,
    documentReady?: DocumentReviewSessionVote | null,
    candidateReady?: DocumentReviewSessionVote | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type ListDocumentReviewSessionsByCandidateQueryVariables = {
  candidateAlias: string,
  sessionState?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocumentReviewSessionRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentReviewSessionsByCandidateQuery = {
  listDocumentReviewSessionsByCandidate?:  {
    __typename: "ModelDocumentReviewSessionRecordConnection",
    items:  Array< {
      __typename: "DocumentReviewSessionRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      ownerAlias: string,
      candidateAlias: string,
      workdocsLink: string,
      chimeLink?: string | null,
      sessionStart: string,
      sessionState: DocumentReviewSessionState,
      overrideReason?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      lastModifiedBy: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListVotesbyDocumentReviewSessionQueryVariables = {
  documentReviewSession: string,
  reviewerAlias?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocumentReviewSessionVoteRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVotesbyDocumentReviewSessionQuery = {
  listVotesbyDocumentReviewSession?:  {
    __typename: "ModelDocumentReviewSessionVoteRecordConnection",
    items:  Array< {
      __typename: "DocumentReviewSessionVoteRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      reviewerAlias: string,
      documentReviewSession: string,
      documentReady?: DocumentReviewSessionVote | null,
      candidateReady?: DocumentReviewSessionVote | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      lastModifiedBy: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDocumentReviewSessionToReviewerMappingQueryVariables = {
  id: string,
};

export type GetDocumentReviewSessionToReviewerMappingQuery = {
  getDocumentReviewSessionToReviewerMapping?:  {
    __typename: "DocumentReviewSessionToReviewerMapping",
    id: string,
    documentReviewSessionRecordID: string,
    calibratedDocumentReviewerRecordID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type GetPanelReviewSessionToReviewerMappingQueryVariables = {
  id: string,
};

export type GetPanelReviewSessionToReviewerMappingQuery = {
  getPanelReviewSessionToReviewerMapping?:  {
    __typename: "PanelReviewSessionToReviewerMapping",
    id: string,
    panelReviewRecordID: string,
    calibratedPanelReviewerRecordID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    owner?: string | null,
  } | null,
};

export type ListDocumentReviewSessionToReviewerMappingsQueryVariables = {
  filter?: ModelDocumentReviewSessionToReviewerMappingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentReviewSessionToReviewerMappingsQuery = {
  listDocumentReviewSessionToReviewerMappings?:  {
    __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
    items:  Array< {
      __typename: "DocumentReviewSessionToReviewerMapping",
      id: string,
      documentReviewSessionRecordID: string,
      calibratedDocumentReviewerRecordID: string,
      documentReviewSessionRecord:  {
        __typename: "DocumentReviewSessionRecord",
        id: string,
        createdAt: string,
        updatedAt: string,
        ownerAlias: string,
        candidateAlias: string,
        workdocsLink: string,
        chimeLink?: string | null,
        sessionStart: string,
        sessionState: DocumentReviewSessionState,
        overrideReason?: string | null,
        documentReviewers?:  {
          __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
          items:  Array< {
            __typename: "DocumentReviewSessionToReviewerMapping",
            id: string,
            documentReviewSessionRecordID: string,
            calibratedDocumentReviewerRecordID: string,
            documentReviewSessionRecord:  {
              __typename: "DocumentReviewSessionRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              ownerAlias: string,
              candidateAlias: string,
              workdocsLink: string,
              chimeLink?: string | null,
              sessionStart: string,
              sessionState: DocumentReviewSessionState,
              overrideReason?: string | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            },
            calibratedDocumentReviewerRecord:  {
              __typename: "CalibratedDocumentReviewerRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              alias: string,
              completedSessions?: number | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        votes?:  {
          __typename: "ModelDocumentReviewSessionVoteRecordConnection",
          items:  Array< {
            __typename: "DocumentReviewSessionVoteRecord",
            id: string,
            createdAt: string,
            updatedAt: string,
            reviewerAlias: string,
            documentReviewSession: string,
            documentReady?: DocumentReviewSessionVote | null,
            candidateReady?: DocumentReviewSessionVote | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            lastModifiedBy: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        lastModifiedBy: string,
        owner?: string | null,
      },
      calibratedDocumentReviewerRecord:  {
        __typename: "CalibratedDocumentReviewerRecord",
        id: string,
        createdAt: string,
        updatedAt: string,
        alias: string,
        completedSessions?: number | null,
        documentReviews?:  {
          __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
          items:  Array< {
            __typename: "DocumentReviewSessionToReviewerMapping",
            id: string,
            documentReviewSessionRecordID: string,
            calibratedDocumentReviewerRecordID: string,
            documentReviewSessionRecord:  {
              __typename: "DocumentReviewSessionRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              ownerAlias: string,
              candidateAlias: string,
              workdocsLink: string,
              chimeLink?: string | null,
              sessionStart: string,
              sessionState: DocumentReviewSessionState,
              overrideReason?: string | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            },
            calibratedDocumentReviewerRecord:  {
              __typename: "CalibratedDocumentReviewerRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              alias: string,
              completedSessions?: number | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        lastModifiedBy: string,
        owner?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDocumentReviewSessionToReviewerMappingsQueryVariables = {
  filter?: ModelDocumentReviewSessionToReviewerMappingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDocumentReviewSessionToReviewerMappingsQuery = {
  syncDocumentReviewSessionToReviewerMappings?:  {
    __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
    items:  Array< {
      __typename: "DocumentReviewSessionToReviewerMapping",
      id: string,
      documentReviewSessionRecordID: string,
      calibratedDocumentReviewerRecordID: string,
      documentReviewSessionRecord:  {
        __typename: "DocumentReviewSessionRecord",
        id: string,
        createdAt: string,
        updatedAt: string,
        ownerAlias: string,
        candidateAlias: string,
        workdocsLink: string,
        chimeLink?: string | null,
        sessionStart: string,
        sessionState: DocumentReviewSessionState,
        overrideReason?: string | null,
        documentReviewers?:  {
          __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
          items:  Array< {
            __typename: "DocumentReviewSessionToReviewerMapping",
            id: string,
            documentReviewSessionRecordID: string,
            calibratedDocumentReviewerRecordID: string,
            documentReviewSessionRecord:  {
              __typename: "DocumentReviewSessionRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              ownerAlias: string,
              candidateAlias: string,
              workdocsLink: string,
              chimeLink?: string | null,
              sessionStart: string,
              sessionState: DocumentReviewSessionState,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            },
            calibratedDocumentReviewerRecord:  {
              __typename: "CalibratedDocumentReviewerRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              alias: string,
              completedSessions?: number | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        votes?:  {
          __typename: "ModelDocumentReviewSessionVoteRecordConnection",
          items:  Array< {
            __typename: "DocumentReviewSessionVoteRecord",
            id: string,
            createdAt: string,
            updatedAt: string,
            reviewerAlias: string,
            documentReviewSession: string,
            documentReady?: DocumentReviewSessionVote | null,
            candidateReady?: DocumentReviewSessionVote | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            lastModifiedBy: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        lastModifiedBy: string,
        owner?: string | null,
      },
      calibratedDocumentReviewerRecord:  {
        __typename: "CalibratedDocumentReviewerRecord",
        id: string,
        createdAt: string,
        updatedAt: string,
        alias: string,
        completedSessions?: number | null,
        documentReviews?:  {
          __typename: "ModelDocumentReviewSessionToReviewerMappingConnection",
          items:  Array< {
            __typename: "DocumentReviewSessionToReviewerMapping",
            id: string,
            documentReviewSessionRecordID: string,
            calibratedDocumentReviewerRecordID: string,
            documentReviewSessionRecord:  {
              __typename: "DocumentReviewSessionRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              ownerAlias: string,
              candidateAlias: string,
              workdocsLink: string,
              chimeLink?: string | null,
              sessionStart: string,
              sessionState: DocumentReviewSessionState,
              overrideReason?: string | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            },
            calibratedDocumentReviewerRecord:  {
              __typename: "CalibratedDocumentReviewerRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              alias: string,
              completedSessions?: number | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        lastModifiedBy: string,
        owner?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ListVotesbyReviewerQueryVariables = {
  reviewerAlias: string,
  documentReviewSession?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocumentReviewSessionVoteRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVotesbyReviewerQuery = {
  listVotesbyReviewer?:  {
    __typename: "ModelDocumentReviewSessionVoteRecordConnection",
    items:  Array< {
      __typename: "DocumentReviewSessionVoteRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      reviewerAlias: string,
      documentReviewSession: string,
      documentReady?: DocumentReviewSessionVote | null,
      candidateReady?: DocumentReviewSessionVote | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      lastModifiedBy: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPanelReviewSessionToReviewerMappingsQueryVariables = {
  filter?: ModelPanelReviewSessionToReviewerMappingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPanelReviewSessionToReviewerMappingsQuery = {
  listPanelReviewSessionToReviewerMappings?:  {
    __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
    items:  Array< {
      __typename: "PanelReviewSessionToReviewerMapping",
      id: string,
      panelReviewRecordID: string,
      calibratedPanelReviewerRecordID: string,
      panelReviewRecord:  {
        __typename: "PanelReviewRecord",
        id: string,
        createdAt: string,
        updatedAt: string,
        ownerAlias: string,
        candidateAlias: string,
        areaLeadAlias: string,
        documentReviewSession: string,
        workdocsLink: string,
        chimeLink: string,
        sessionStart: string,
        sessionState: PanelReviewState,
        overrideReason?: string | null,
        panelReviewers?:  {
          __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
          items:  Array< {
            __typename: "PanelReviewSessionToReviewerMapping",
            id: string,
            panelReviewRecordID: string,
            calibratedPanelReviewerRecordID: string,
            panelReviewRecord:  {
              __typename: "PanelReviewRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              ownerAlias: string,
              candidateAlias: string,
              areaLeadAlias: string,
              documentReviewSession: string,
              workdocsLink: string,
              chimeLink: string,
              sessionStart: string,
              sessionState: PanelReviewState,
              overrideReason?: string | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            },
            calibratedPanelReviewerRecord:  {
              __typename: "CalibratedPanelReviewerRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              alias: string,
              completedSessions?: number | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        votes?:  {
          __typename: "ModelPanelReviewVoteRecordConnection",
          items:  Array< {
            __typename: "PanelReviewVoteRecord",
            id: string,
            createdAt: string,
            updatedAt: string,
            reviewerAlias: string,
            panelReview: string,
            vote: PanelReviewSessionVote,
            voteStage: PanelReviewState,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            lastModifiedBy: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        lastModifiedBy: string,
        owner?: string | null,
      },
      calibratedPanelReviewerRecord:  {
        __typename: "CalibratedPanelReviewerRecord",
        id: string,
        createdAt: string,
        updatedAt: string,
        alias: string,
        completedSessions?: number | null,
        panelReviews?:  {
          __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
          items:  Array< {
            __typename: "PanelReviewSessionToReviewerMapping",
            id: string,
            panelReviewRecordID: string,
            calibratedPanelReviewerRecordID: string,
            panelReviewRecord:  {
              __typename: "PanelReviewRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              ownerAlias: string,
              candidateAlias: string,
              areaLeadAlias: string,
              documentReviewSession: string,
              workdocsLink: string,
              chimeLink: string,
              sessionStart: string,
              sessionState: PanelReviewState,
              overrideReason?: string | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            },
            calibratedPanelReviewerRecord:  {
              __typename: "CalibratedPanelReviewerRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              alias: string,
              completedSessions?: number | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        lastModifiedBy: string,
        owner?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPanelReviewSessionToReviewerMappingsQueryVariables = {
  filter?: ModelPanelReviewSessionToReviewerMappingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPanelReviewSessionToReviewerMappingsQuery = {
  syncPanelReviewSessionToReviewerMappings?:  {
    __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
    items:  Array< {
      __typename: "PanelReviewSessionToReviewerMapping",
      id: string,
      panelReviewRecordID: string,
      calibratedPanelReviewerRecordID: string,
      panelReviewRecord:  {
        __typename: "PanelReviewRecord",
        id: string,
        createdAt: string,
        updatedAt: string,
        ownerAlias: string,
        candidateAlias: string,
        areaLeadAlias: string,
        documentReviewSession: string,
        workdocsLink: string,
        chimeLink: string,
        sessionStart: string,
        sessionState: PanelReviewState,
        overrideReason?: string | null,
        panelReviewers?:  {
          __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
          items:  Array< {
            __typename: "PanelReviewSessionToReviewerMapping",
            id: string,
            panelReviewRecordID: string,
            calibratedPanelReviewerRecordID: string,
            panelReviewRecord:  {
              __typename: "PanelReviewRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              ownerAlias: string,
              candidateAlias: string,
              areaLeadAlias: string,
              documentReviewSession: string,
              workdocsLink: string,
              chimeLink: string,
              sessionStart: string,
              sessionState: PanelReviewState,
              overrideReason?: string | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            },
            calibratedPanelReviewerRecord:  {
              __typename: "CalibratedPanelReviewerRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              alias: string,
              completedSessions?: number | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        votes?:  {
          __typename: "ModelPanelReviewVoteRecordConnection",
          items:  Array< {
            __typename: "PanelReviewVoteRecord",
            id: string,
            createdAt: string,
            updatedAt: string,
            reviewerAlias: string,
            panelReview: string,
            vote: PanelReviewSessionVote,
            voteStage: PanelReviewState,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            lastModifiedBy: string,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        lastModifiedBy: string,
        owner?: string | null,
      },
      calibratedPanelReviewerRecord:  {
        __typename: "CalibratedPanelReviewerRecord",
        id: string,
        createdAt: string,
        updatedAt: string,
        alias: string,
        completedSessions?: number | null,
        panelReviews?:  {
          __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
          items:  Array< {
            __typename: "PanelReviewSessionToReviewerMapping",
            id: string,
            panelReviewRecordID: string,
            calibratedPanelReviewerRecordID: string,
            panelReviewRecord:  {
              __typename: "PanelReviewRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              ownerAlias: string,
              candidateAlias: string,
              areaLeadAlias: string,
              documentReviewSession: string,
              workdocsLink: string,
              chimeLink: string,
              sessionStart: string,
              sessionState: PanelReviewState,
              overrideReason?: string | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            },
            calibratedPanelReviewerRecord:  {
              __typename: "CalibratedPanelReviewerRecord",
              id: string,
              createdAt: string,
              updatedAt: string,
              alias: string,
              completedSessions?: number | null,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              lastModifiedBy: string,
              owner?: string | null,
            },
            createdAt: string,
            updatedAt: string,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            owner?: string | null,
          } | null >,
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        lastModifiedBy: string,
        owner?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPanelReviewRecordQueryVariables = {
  id: string,
};

export type GetPanelReviewRecordQuery = {
  getPanelReviewRecord?:  {
    __typename: "PanelReviewRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    ownerAlias: string,
    candidateAlias: string,
    areaLeadAlias: string,
    documentReviewSession: string,
    workdocsLink: string,
    chimeLink: string,
    sessionStart: string,
    sessionState: PanelReviewState,
    overrideReason?: string | null,
    panelReviewers?:  {
      __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
      items:  Array< {
        __typename: "PanelReviewSessionToReviewerMapping",
        id: string,
        panelReviewRecordID: string,
        calibratedPanelReviewerRecordID: string,
        panelReviewRecord:  {
          __typename: "PanelReviewRecord",
          id: string,
          owner?: string | null,
          coOwners?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          ownerAlias: string,
          candidateAlias: string,
          areaLeadAlias: string,
          documentReviewSession: string,
          workdocsLink: string,
          chimeLink: string,
          sessionStart: string,
          sessionState: PanelReviewState,
          overrideReason?: string | null,
          panelReviewers?:  {
            __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
            items:  Array< {
              __typename: "PanelReviewSessionToReviewerMapping",
              id: string,
              panelReviewRecordID: string,
              calibratedPanelReviewerRecordID: string,
              createdAt: string,
              updatedAt: string,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              owner?: string | null,
            } | null >,
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          lastModifiedBy: string,
        },
        calibratedPanelReviewerRecord:  {
          __typename: "CalibratedPanelReviewerRecord",
          id: string,
          createdAt: string,
          updatedAt: string,
          alias: string,
          completedSessions?: number | null,
          panelReviews?:  {
            __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
            items:  Array< {
              __typename: "PanelReviewSessionToReviewerMapping",
              id: string,
              panelReviewRecordID: string,
              calibratedPanelReviewerRecordID: string,
              createdAt: string,
              updatedAt: string,
              _version: number,
              _deleted?: boolean | null,
              _lastChangedAt: number,
              owner?: string | null,
              coOwners?: string | null,
            } | null >,
            nextToken?: string | null,
            startedAt?: number | null,
          } | null,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          lastModifiedBy: string,
          owner?: string | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
    coOwners?: Array< string > | null,
  } | null,
};

export type GetPanelReviewViewQueryVariables = {
  id: string,
};

export type GetPanelReviewViewQuery = {
  getPanelReviewRecord?:  {
    __typename: "PanelReviewRecord",
    id: string,
    ownerAlias: string,
    candidateAlias: string,
    areaLeadAlias: string,
    documentReviewSession: string,
    workdocsLink: string,
    chimeLink: string,
    sessionStart: string,
    sessionState: PanelReviewState,
    panelReviewers?:  {
      __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
      items:  Array< {
        __typename: "PanelReviewSessionToReviewerMapping",
        id: string,
        calibratedPanelReviewerRecord:  {
          __typename: "CalibratedPanelReviewerRecord",
          id: string,
          alias: string,
        },
      } | null >,
    } | null,
    _version: number,
    coOwners?: Array< string > | null,
  } | null,
};

export type GetPanelReviewExistsQueryVariables = {
  id: string,
};

export type GetPanelReviewExistsQuery = {
  getPanelReviewRecord?:  {
    __typename: "PanelReviewRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
  } | null,
};

export type GetMinimalPanelReviewRecordQueryVariables = {
  id: string,
};

export type GetMinimalPanelReviewRecordQuery = {
  getPanelReviewRecord?:  {
    __typename: "PanelReviewRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    ownerAlias: string,
    candidateAlias: string,
    areaLeadAlias: string,
    sessionState: PanelReviewState,
    panelReviewers?:  {
      __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
      items:  Array< {
        __typename: "PanelReviewSessionToReviewerMapping",
        id: string,
        calibratedPanelReviewerRecord:  {
          __typename: "CalibratedPanelReviewerRecord",
          id: string,
          alias: string,
          _version: number,
        },
      } | null >,
    } | null,
  } | null,
};

export type GetPanelReviewersQueryVariables = {
  id: string,
};

export type GetPanelReviewersQuery = {
  getPanelReviewRecord?:  {
    __typename: "PanelReviewRecord",
    id: string,
    ownerAlias: string,
    sessionState: PanelReviewState,
    panelReviewers?:  {
      __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
      items:  Array< {
        __typename: "PanelReviewSessionToReviewerMapping",
        id: string,
        calibratedPanelReviewerRecord:  {
          __typename: "CalibratedPanelReviewerRecord",
          id: string,
          alias: string,
        },
        _version: number,
      } | null >,
    } | null,
    _version: number,
  } | null,
};

export type GetPanelReviewStateQueryVariables = {
  id: string,
};

export type GetPanelReviewStateQuery = {
  getPanelReviewRecord?:  {
    __typename: "PanelReviewRecord",
    id: string,
    sessionState: PanelReviewState,
    _version: number,
  } | null,
};

export type GetCalibratedPanelReviewerRecordQueryVariables = {
  id: string,
};

export type GetCalibratedPanelReviewerRecordQuery = {
  getCalibratedPanelReviewerRecord?:  {
    __typename: "CalibratedPanelReviewerRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    alias: string,
    completedSessions?: number | null,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
    owner?: string | null,
  } | null,
};

export type ListPanelReviewSessionsByOwnerQueryVariables = {
  ownerAlias: string,
  sessionState?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPanelReviewRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPanelReviewSessionsByOwnerQuery = {
  listPanelReviewSessionsByOwner?:  {
    __typename: "ModelPanelReviewRecordConnection",
    items:  Array< {
      __typename: "PanelReviewRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      ownerAlias: string,
      candidateAlias: string,
      areaLeadAlias: string,
      documentReviewSession: string,
      workdocsLink: string,
      chimeLink: string,
      sessionStart: string,
      sessionState: PanelReviewState,
      overrideReason?: string | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      lastModifiedBy: string,
      owner?: string | null,
      coOwners?: Array< string > | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListPanelReviewSessionItemsByOwnerQueryVariables = {
  ownerAlias: string,
  sessionState?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPanelReviewRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPanelReviewSessionItemsByOwnerQuery = {
  listPanelReviewSessionsByOwner?:  {
    __typename: "ModelPanelReviewRecordConnection",
    items:  Array< {
      __typename: "PanelReviewRecord",
      id: string,
      ownerAlias: string,
      candidateAlias: string,
      sessionStart: string,
      sessionState: PanelReviewState,
      _version: number,
    } | null >,
  } | null,
};

export type ListCalibratedPanelReviewerRecordsQueryVariables = {
  id?: string | null,
  filter?: ModelCalibratedPanelReviewerRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCalibratedPanelReviewerRecordsQuery = {
  listCalibratedPanelReviewerRecords?:  {
    __typename: "ModelCalibratedPanelReviewerRecordConnection",
    items:  Array< {
      __typename: "CalibratedPanelReviewerRecord",
      id: string,
      alias: string,
      completedSessions?: number | null,
    } | null >,
  } | null,
};

export type GetCalibratedPanelReviewerRecordByAliasQueryVariables = {
  alias: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCalibratedPanelReviewerRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCalibratedPanelReviewerRecordByAliasQuery = {
  getCalibratedPanelReviewerRecordByAlias?:  {
    __typename: "ModelCalibratedPanelReviewerRecordConnection",
    items:  Array< {
      __typename: "CalibratedPanelReviewerRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      alias: string,
      completedSessions?: number | null,
      panelReviews?:  {
        __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
        items:  Array< {
          __typename: "PanelReviewSessionToReviewerMapping",
          id: string,
          panelReviewRecordID: string,
          calibratedPanelReviewerRecordID: string,
          panelReviewRecord:  {
            __typename: "PanelReviewRecord",
            id: string,
            createdAt: string,
            updatedAt: string,
            ownerAlias: string,
            candidateAlias: string,
            areaLeadAlias: string,
            documentReviewSession: string,
            workdocsLink: string,
            chimeLink: string,
            sessionStart: string,
            sessionState: PanelReviewState,
            overrideReason?: string | null,
            panelReviewers?:  {
              __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
              nextToken?: string | null,
              startedAt?: number | null,
            } | null,
            _version: number,
            _deleted?: boolean | null,
            _lastChangedAt: number,
            lastModifiedBy: string,
            owner?: string | null,
            coOwners?: Array< string > | null,
          },
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      lastModifiedBy: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPanelReviewerReviewsByAliasQueryVariables = {
  alias: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCalibratedPanelReviewerRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetPanelReviewerReviewsByAliasQuery = {
  getCalibratedPanelReviewerRecordByAlias?:  {
    __typename: "ModelCalibratedPanelReviewerRecordConnection",
    items:  Array< {
      __typename: "CalibratedPanelReviewerRecord",
      alias: string,
      panelReviews?:  {
        __typename: "ModelPanelReviewSessionToReviewerMappingConnection",
        items:  Array< {
          __typename: "PanelReviewSessionToReviewerMapping",
          panelReviewRecord:  {
            __typename: "PanelReviewRecord",
            id: string,
            ownerAlias: string,
            candidateAlias: string,
            sessionStart: string,
            sessionState: PanelReviewState,
          },
        } | null >,
      } | null,
    } | null >,
  } | null,
};

export type GetCalibratedPanelReviewerRecordExistsByAliasQueryVariables = {
  alias: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCalibratedPanelReviewerRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCalibratedPanelReviewerRecordExistsByAliasQuery = {
  getCalibratedPanelReviewerRecordByAlias?:  {
    __typename: "ModelCalibratedPanelReviewerRecordConnection",
    items:  Array< {
      __typename: "CalibratedPanelReviewerRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      alias: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      lastModifiedBy: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPanelReviewVoteRecordQueryVariables = {
  id: string,
};

export type GetPanelReviewVoteRecordQuery = {
  getPanelReviewVoteRecord?:  {
    __typename: "PanelReviewVoteRecord",
    id: string,
    createdAt: string,
    updatedAt: string,
    reviewerAlias: string,
    panelReview: string,
    vote: PanelReviewSessionVote,
    voteStage: PanelReviewState,
    _version: number,
  } | null,
};

export type ListVotesbyPanelReviewerQueryVariables = {
  reviewerAlias: string,
  panelReviewVoteStage?: ModelPanelReviewVoteRecordByPanelReviewerCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPanelReviewVoteRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVotesbyPanelReviewerQuery = {
  listVotesbyPanelReviewer?:  {
    __typename: "ModelPanelReviewVoteRecordConnection",
    items:  Array< {
      __typename: "PanelReviewVoteRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      reviewerAlias: string,
      panelReview: string,
      vote: PanelReviewSessionVote,
      voteStage: PanelReviewState,
      _version: number,
    } | null >,
  } | null,
};

export type ListVotesbyPanelReviewQueryVariables = {
  panelReview: string,
  reviewerAliasVoteStage?: ModelPanelReviewVoteRecordByPanelReviewSessionCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPanelReviewVoteRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVotesbyPanelReviewQuery = {
  listVotesbyPanelReview?:  {
    __typename: "ModelPanelReviewVoteRecordConnection",
    items:  Array< {
      __typename: "PanelReviewVoteRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      reviewerAlias: string,
      panelReview: string,
      vote: PanelReviewSessionVote,
      voteStage: PanelReviewState,
      _version: number,
    } | null >,
  } | null,
};

export type ListVotesbyPanelReviewStateQueryVariables = {
  voteStage: PanelReviewState,
  panelReview?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPanelReviewVoteRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVotesbyPanelReviewStateQuery = {
  listVotesbyPanelReviewState?:  {
    __typename: "ModelPanelReviewVoteRecordConnection",
    items:  Array< {
      __typename: "PanelReviewVoteRecord",
      id: string,
      createdAt: string,
      updatedAt: string,
      reviewerAlias: string,
      panelReview: string,
      vote: PanelReviewSessionVote,
      voteStage: PanelReviewState,
      _version: number,
    } | null >,
  } | null,
};

export type GetPanelReviewCommentQueryVariables = {
  id: string,
};

export type GetPanelReviewCommentQuery = {
  getPanelReviewComment?:  {
    __typename: "PanelReviewComment",
    createdAt: string,
    id: string,
    owner?: string | null,
    updatedAt: string,
    alias: string,
    panelReviewId: string,
    voteStage: PanelReviewState,
    content: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
  } | null,
};

export type ListCommentsByPanelReviewQueryVariables = {
  panelReviewId: string,
  voteStage?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPanelReviewCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsByPanelReviewQuery = {
  listCommentsByPanelReview?:  {
    __typename: "ModelPanelReviewCommentConnection",
    items:  Array< {
      __typename: "PanelReviewComment",
      createdAt: string,
      id: string,
      owner?: string | null,
      updatedAt: string,
      alias: string,
      panelReviewId: string,
      voteStage: PanelReviewState,
      content: string,
      _version: number,
      _deleted?: boolean | null,
    } | null >,
  } | null,
};

export type ListCommentsByDocumentReviewQueryVariables = {
  documentReviewId: string,
  voteStage?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocumentReviewCommentRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsByDocumentReviewQuery = {
  listCommentsByDocumentReview?:  {
    __typename: "ModelDocumentReviewCommentRecordConnection",
    items:  Array< {
      __typename: "DocumentReviewCommentRecord",
      createdAt: string,
      id: string,
      owner?: string | null,
      updatedAt: string,
      alias: string,
      documentReviewId: string,
      voteStage: DocumentReviewSessionState,
      content: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      lastModifiedBy: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDocumentReviewCommentRecordQueryVariables = {
  id: string,
};

export type GetDocumentReviewCommentRecordQuery = {
  getDocumentReviewCommentRecord?:  {
    __typename: "DocumentReviewCommentRecord",
    createdAt: string,
    id: string,
    owner?: string | null,
    updatedAt: string,
    alias: string,
    documentReviewId: string,
    voteStage: DocumentReviewSessionState,
    content: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    lastModifiedBy: string,
  } | null,
};

export type ListPanelReviewSessionsByAreaLeadAliasQueryVariables = {
  areaLeadAlias: string,
  sessionState?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPanelReviewRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPanelReviewSessionsByAreaLeadAliasQuery = {
  listPanelReviewSessionsByAreaLeadAlias?:  {
    __typename: "ModelPanelReviewRecordConnection",
    items:  Array< {
      __typename: "PanelReviewRecord",
      id: string,
      ownerAlias: string,
      candidateAlias: string,
      sessionStart: string,
      sessionState: PanelReviewState,
      _version: number,
    } | null >,
  } | null,
};
