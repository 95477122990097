/* eslint-disable no-underscore-dangle */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import {
  CalibratedReviewerItem,
  DocumentReviewSessionVoteItem,
  DocumentReviewSessionItem,
  DocumentReviewSessionResource,
  Nullable,
  OptionalString,
  ReviewSessionBase,
  DocumentReviewSessionCommentItem,
  DocumentReviewSessionMinResource,
  ExistsResource,
  DocumentReviewSessionStateResource,
  DocumentReviewerResource,
} from '@/models';
import {
  CreateDocumentReviewCommentRecordInput,
  CreateDocumentReviewCommentRecordMutation,
  CreateDocumentReviewSessionRecordInput,
  CreateDocumentReviewSessionRecordMutation,
  CreateDocumentReviewSessionToReviewerMappingInput,
  CreateDocumentReviewSessionToReviewerMappingMutation,
  CreateDocumentReviewSessionVoteRecordInput,
  CreateDocumentReviewSessionVoteRecordMutation,
  DeleteDocumentReviewCommentRecordInput,
  DeleteDocumentReviewCommentRecordMutation,
  DeleteDocumentReviewSessionRecordInput,
  DeleteDocumentReviewSessionRecordMutation,
  DeleteDocumentReviewSessionToReviewerMappingInput,
  DeleteDocumentReviewSessionToReviewerMappingMutation,
  DocumentReviewCommentRecord,
  DocumentReviewSessionRecord as DocumentReviewSessionModel,
  DocumentReviewSessionState,
  DocumentReviewSessionToReviewerMapping,
  DocumentReviewSessionVoteRecord,
  GetCalibratedDocumentReviewerRecordByAliasQuery,
  GetCalibratedDocumentReviewerRecordByAliasQueryVariables,
  GetCalibratedDocumentReviewerRecordExistsByAliasQuery,
  GetCalibratedDocumentReviewerRecordExistsByAliasQueryVariables,
  GetDocumentReviewCommentRecordQuery,
  GetDocumentReviewCommentRecordQueryVariables,
  GetDocumentReviewerReviewsByAliasQuery,
  GetDocumentReviewerReviewsByAliasQueryVariables,
  GetDocumentReviewersQuery,
  GetDocumentReviewersQueryVariables,
  GetDocumentReviewExistsQuery,
  GetDocumentReviewExistsQueryVariables,
  GetDocumentReviewSessionRecordQuery,
  GetDocumentReviewSessionRecordQueryVariables,
  GetDocumentReviewSessionToReviewerMappingQuery,
  GetDocumentReviewSessionToReviewerMappingQueryVariables,
  GetDocumentReviewSessionVoteRecordQuery,
  GetDocumentReviewSessionVoteRecordQueryVariables,
  GetDocumentReviewStateQuery,
  GetDocumentReviewStateQueryVariables,
  GetMinimalDocumentReviewRecordQuery,
  GetMinimalDocumentReviewRecordQueryVariables,
  ListCalibratedDocumentReviewerRecordsQuery,
  ListCalibratedDocumentReviewerRecordsQueryVariables,
  ListCommentsByDocumentReviewQuery,
  ListCommentsByDocumentReviewQueryVariables,
  ListDocumentReviewSessionItemsByOwnerQuery,
  ListDocumentReviewSessionItemsByOwnerQueryVariables,
  ListDocumentReviewSessionsByCandidateQuery,
  ListDocumentReviewSessionsByCandidateQueryVariables,
  ListDocumentReviewSessionsByOwnerQuery,
  ListVotesbyDocumentReviewSessionQuery,
  ListVotesbyDocumentReviewSessionQueryVariables,
  ListVotesbyReviewerQuery,
  ListVotesbyReviewerQueryVariables,
  ModelStringKeyConditionInput,
  UpdateDocumentReviewCommentRecordInput,
  UpdateDocumentReviewCommentRecordMutation,
  UpdateDocumentReviewSessionRecordInput,
  UpdateDocumentReviewSessionRecordMutation,
  UpdateDocumentReviewSessionVoteRecordInput,
  UpdateDocumentReviewSessionVoteRecordMutation,
} from './API';
import {
  DocumentReviewSessionActions,
  UseCalibratedDocumentReviewersResult,
  UseDocumentReviewersResult,
  UseDocumentReviewSessionActionsResult,
  UseDocumentReviewSessionCommentsResult,
  UseDocumentReviewSessionResult,
  UseDocumentReviewSessionsByCandidateResult,
  UseDocumentReviewSessionsResult,
  UseDocumentReviewVotesResult,
} from './models';
import { getCompactId, getDateFromAPIValueUTC } from '@/common/utils';
import { QueryKeys } from './queryKeys';
import {
  getCalibratedDocumentReviewerRecordByAlias,
  getCalibratedDocumentReviewerRecordExistsByAlias,
  getDocumentReviewCommentRecord,
  getDocumentReviewerReviewsByAlias,
  getDocumentReviewers,
  getDocumentReviewExists,
  getDocumentReviewSessionRecord,
  getDocumentReviewSessionToReviewerMapping,
  getDocumentReviewSessionVoteRecord,
  getDocumentReviewState,
  getMinimalDocumentReviewRecord,
  listCalibratedDocumentReviewerRecords,
  listCommentsByDocumentReview,
  listDocumentReviewSessionItemsByOwner,
  listDocumentReviewSessionsByCandidate,
  listVotesbyDocumentReviewSession,
  listVotesbyReviewer,
} from '@/graphql/queries';
import { getConcreteModels, useApiMutation, useApiQuery } from '@/backend/api';
import { useNotifications } from '@/contexts';
import {
  createDocumentReviewCommentRecord,
  createDocumentReviewSessionRecord,
  createDocumentReviewSessionToReviewerMapping,
  createDocumentReviewSessionVoteRecord,
  deleteDocumentReviewSessionRecord,
  deleteDocumentReviewSessionToReviewerMapping,
  deleteDocumentReviewCommentRecord,
  updateDocumentReviewSessionRecord,
  updateDocumentReviewSessionVoteRecord,
  updateDocumentReviewCommentRecord,
} from '@/graphql/mutations';

type MinModel = NonNullable<GetMinimalDocumentReviewRecordQuery['getDocumentReviewSessionRecord']>;

type DocReviewerModel = NonNullable<GetDocumentReviewersQuery['getDocumentReviewSessionRecord']>;

type ExistsModel = NonNullable<GetDocumentReviewExistsQuery['getDocumentReviewSessionRecord']>;

type StateModel = NonNullable<GetDocumentReviewStateQuery['getDocumentReviewSessionRecord']>;

type ResourceModel =
  | DocumentReviewSessionModel
  | NonNullable<GetDocumentReviewSessionRecordQuery['getDocumentReviewSessionRecord']>;

type Model =
  | DocumentReviewSessionModel
  | NonNullable<
      NonNullable<ListDocumentReviewSessionsByOwnerQuery['listDocumentReviewSessionsByOwner']>['items'][number]
    >
  | NonNullable<
      NonNullable<ListDocumentReviewSessionItemsByOwnerQuery['listDocumentReviewSessionsByOwner']>['items'][number]
    >;

type ListingModel = NonNullable<
  NonNullable<ListDocumentReviewSessionItemsByOwnerQuery['listDocumentReviewSessionsByOwner']>['items'][number]
>;

type ReviewerModel = NonNullable<
  NonNullable<
    GetCalibratedDocumentReviewerRecordByAliasQuery['getCalibratedDocumentReviewerRecordByAlias']
  >['items'][number]
>;

type ReviewerExistsModel = NonNullable<
  NonNullable<
    GetCalibratedDocumentReviewerRecordExistsByAliasQuery['getCalibratedDocumentReviewerRecordByAlias']
  >['items'][number]
>;

type ReviewerListingItem = NonNullable<
  NonNullable<ListCalibratedDocumentReviewerRecordsQuery['listCalibratedDocumentReviewerRecords']>['items'][number]
>;

type ItemModel =
  | NonNullable<
      NonNullable<ListDocumentReviewSessionsByOwnerQuery['listDocumentReviewSessionsByOwner']>['items'][number]
    >
  | NonNullable<
      NonNullable<ListDocumentReviewSessionsByCandidateQuery['listDocumentReviewSessionsByCandidate']>['items'][number]
    >;

type ItemListingModel = NonNullable<
  NonNullable<ListDocumentReviewSessionItemsByOwnerQuery['listDocumentReviewSessionsByOwner']>['items'][number]
>;

type ReviewerItemModel = NonNullable<
  NonNullable<
    GetCalibratedDocumentReviewerRecordByAliasQuery['getCalibratedDocumentReviewerRecordByAlias']
  >['items'][number]
>;

type ReviewerListingModel = NonNullable<
  NonNullable<GetDocumentReviewerReviewsByAliasQuery['getCalibratedDocumentReviewerRecordByAlias']>['items'][number]
>;

type SessionVoteItemModel =
  | DocumentReviewSessionVoteRecord
  | NonNullable<
      NonNullable<ListVotesbyDocumentReviewSessionQuery['listVotesbyDocumentReviewSession']>['items'][number]
    >;

type SessionCommentItemModel =
  | DocumentReviewCommentRecord
  | NonNullable<NonNullable<ListCommentsByDocumentReviewQuery['listCommentsByDocumentReview']>['items'][number]>;

function isValidItem(model: Nullable<Model>): boolean {
  return !!model;
}

function isValidReviewerItem(model: Nullable<ReviewerModel>): boolean {
  return !!model;
}

function isValidReviewerExistsItem(model: Nullable<ReviewerExistsModel>): boolean {
  return !!model;
}

function isValidListing(model: Nullable<ListingModel>): boolean {
  return !!model;
}

function isValidReviewerListing(model: Nullable<ReviewerListingModel>): boolean {
  return !!model;
}

function isValidSessionVoteItem(model: Nullable<SessionVoteItemModel>): boolean {
  return !!model;
}

function isValidResource(model: Nullable<ResourceModel>): boolean {
  return isValidItem(model) && model?.sessionState !== DocumentReviewSessionState.CANCELLED;
}

function isValidSessionCommentItem(model: Nullable<SessionCommentItemModel>): boolean {
  return !!model;
}

function isValidMinItem(model: Nullable<MinModel>): boolean {
  return !!model;
}

function isValidStateItem(model: Nullable<StateModel>): boolean {
  return !!model;
}

function isValidExistsItem(model: Nullable<ExistsModel>): boolean {
  return !!model;
}

function isValidMinResource(model: Nullable<MinModel>): boolean {
  return isValidMinItem(model) && model?.sessionState !== DocumentReviewSessionState.CANCELLED;
}

function isValidDocReviewerResource(model: Nullable<DocReviewerModel>): boolean {
  return !!model;
}

function isValidStateResource(model: Nullable<StateModel>): boolean {
  return isValidStateItem(model) && model?.sessionState !== DocumentReviewSessionState.CANCELLED;
}

function isValidExistsResource(model: Nullable<ExistsModel>): boolean {
  return isValidExistsItem(model);
}

function getReviewerItemFromModel(model: ReviewerModel): CalibratedReviewerItem {
  return {
    id: model.id,
    alias: model.alias,
    completedSessions: model.completedSessions || 0,
  };
}

function getReviewerItemExistsFromModel(model: ReviewerExistsModel): CalibratedReviewerItem {
  return {
    id: model.id,
    alias: model.alias,
    completedSessions: 0,
  };
}

function getReviewerItemListingFromModel(model: ReviewerListingItem): CalibratedReviewerItem {
  return {
    id: model.id,
    alias: model.alias,
    completedSessions: model.completedSessions || 0,
  };
}

function getBaseFromModel(model: Model): ReviewSessionBase {
  return {
    id: model.id,
    candidateAlias: model.candidateAlias,
    ownerAlias: model.ownerAlias,
    sessionStart: model.sessionStart,
    version: model._version,
  };
}

function getItemFromModel(model: Model): DocumentReviewSessionItem {
  return {
    id: model.id,
    candidateAlias: model.candidateAlias,
    ownerAlias: model.ownerAlias,
    sessionState: model.sessionState,
    sessionStart: model.sessionStart,
    version: model._version,
  };
}

function getResourceFromModel(model: ResourceModel): DocumentReviewSessionResource {
  return {
    ...getBaseFromModel(model),
    sessionState: model.sessionState,
    chimeLink: model.chimeLink,
    createdAt: getDateFromAPIValueUTC(model.createdAt),
    updatedAt: getDateFromAPIValueUTC(model.updatedAt),
    coOwners: model.coOwners ?? [],
    workdocsLink: model.workdocsLink,
    documentReviewers:
      model.documentReviewers?.items.map((item) => ({
        id: item?.id,
        alias: item?.calibratedDocumentReviewerRecord?.alias,
        completedSessions: item?.calibratedDocumentReviewerRecord?.completedSessions,
      })) ?? [],
  };
}

function getSessionVoteItemFromModel(model: SessionVoteItemModel): DocumentReviewSessionVoteItem {
  return {
    id: model.id,
    documentReviewSessionId: model.documentReviewSession,
    alias: model.reviewerAlias,
    documentReady: model.documentReady!,
    candidateReady: model.candidateReady!,
    version: model._version,
  };
}

function getSessionCommentItemFromModel(model: SessionCommentItemModel): DocumentReviewSessionCommentItem {
  return {
    id: model.id,
    reviewSessionId: model.documentReviewId!,
    alias: model.alias,
    voteStage: model.voteStage,
    content: model.content,
    createdAt: getDateFromAPIValueUTC(model.createdAt),
    updatedAt: getDateFromAPIValueUTC(model.updatedAt),
    version: model._version,
  };
}

function getMinResourceFromModel(model: MinModel): DocumentReviewSessionMinResource {
  return {
    id: model.id,
    candidateAlias: model.candidateAlias,
    ownerAlias: model.ownerAlias,
    sessionState: model.sessionState,
    sessionStart: model.sessionStart,
    coOwners: model.coOwners ?? [],
    chimeLink: model.chimeLink,
    workdocsLink: model.workdocsLink,
    version: model._version,
    documentReviewers:
      model.documentReviewers?.items.map((item) => ({
        id: item?.id ?? '',
        alias: item?.calibratedDocumentReviewerRecord?.alias,
      })) ?? [],
  };
}

function getDocReviewerResourceFromModel(model: DocReviewerModel): DocumentReviewerResource {
  return {
    id: model.id,
    sessionState: model.sessionState,
    documentReviewers:
      model.documentReviewers?.items.map((item) => ({
        id: item?.id ?? '',
        alias: item?.calibratedDocumentReviewerRecord?.alias,
      })) ?? [],
    version: model._version,
  };
}

function getExistsResourceFromModel(model: ExistsModel): ExistsResource {
  return {
    id: model.id,
    version: model._version,
  };
}

function getStateResourceFromModel(model: StateModel): DocumentReviewSessionStateResource {
  return {
    id: model.id,
    sessionState: model.sessionState,
    version: model._version,
  };
}

export function useCalibratedDocumentReviewerRecords(): UseCalibratedDocumentReviewersResult {
  const { getItems } = useApiQuery();

  const query = useQuery({
    meta: { errorMessage: `Error fetching calibrated document reviewers` },
    queryKey: QueryKeys.calibratedDocumentReviewer.all,
    queryFn: async () => {
      const calibratedDocumentReviewerRecords = await getItems<
        ListCalibratedDocumentReviewerRecordsQueryVariables,
        ListCalibratedDocumentReviewerRecordsQuery
      >({
        query: listCalibratedDocumentReviewerRecords,
        input: {},
      });

      const calibratedDocumentReviewerModels = getConcreteModels<ReviewerListingItem>(
        calibratedDocumentReviewerRecords?.listCalibratedDocumentReviewerRecords?.items,
        isValidReviewerListing
      );

      return calibratedDocumentReviewerModels?.map((item) => getReviewerItemListingFromModel(item)) ?? [];
    },
  });

  return {
    calibratedDocumentReviewers: query.data ?? [],
    isCalibratedDocumentReviewersLoading: query.isPending,
  };
}

export function useDocumentReviewSessions(
  alias: OptionalString,
  states?: DocumentReviewSessionState[]
): UseDocumentReviewSessionsResult {
  const { getItems } = useApiQuery();

  const query = useQuery({
    meta: { errorMessage: `Error fetching document review sessions for: ${getCompactId(alias)}@` },
    queryKey: QueryKeys.documentReviewSession.alias(alias).status(states),
    queryFn: async () => {
      if (!alias) return undefined;
      let reviewState: ModelStringKeyConditionInput | undefined;
      if (states?.length === 1) {
        reviewState = { eq: states[0] };
      }
      if (states?.length && states.length > 1) {
        reviewState = { between: [states.sort()[0], states.sort()[1]] };
      }

      const reviewSessionsByOwner = await getItems<
        ListDocumentReviewSessionItemsByOwnerQueryVariables,
        ListDocumentReviewSessionItemsByOwnerQuery
      >({
        query: listDocumentReviewSessionItemsByOwner,
        input: { ownerAlias: alias, sessionState: reviewState },
      });

      const ownerSessionModels = getConcreteModels<ItemListingModel>(
        reviewSessionsByOwner?.listDocumentReviewSessionsByOwner?.items,
        isValidListing
      );

      const reviewer = await getItems<
        GetDocumentReviewerReviewsByAliasQueryVariables,
        GetDocumentReviewerReviewsByAliasQuery
      >({
        query: getDocumentReviewerReviewsByAlias,
        input: { alias, filter: { _deleted: { ne: true } } },
      });
      const reviewerModel = getConcreteModels<ReviewerListingModel>(
        reviewer?.getCalibratedDocumentReviewerRecordByAlias?.items[0],
        isValidReviewerListing
      );

      let reviewerSessionModels;
      if (reviewerModel?.length === 1) {
        reviewerSessionModels =
          reviewerModel[0].documentReviews?.items
            .filter((item) => item?.documentReviewSessionRecord.sessionState !== DocumentReviewSessionState.CANCELLED)
            .map((item) => item?.documentReviewSessionRecord) ?? [];
      }
      const allSessionModels = [
        ...(ownerSessionModels?.map((item) => getItemFromModel(item)) ?? []),
        ...(reviewerSessionModels ?? []),
      ];

      return allSessionModels;
    },
    enabled: !!alias,
  });

  return {
    documentReviewSessions: query.data ?? [],
    isDocumentReviewSessionsLoading: !!alias && query.isPending,
  };
}

export function useDocumentReviewSessionsByCandidate(): UseDocumentReviewSessionsByCandidateResult {
  const queryClient = useQueryClient();
  const { getItems } = useApiQuery();

  type GetDocumentReviewSessionsParams = Parameters<
    UseDocumentReviewSessionsByCandidateResult['listDocumentReviewSessionsByCandidateAlias']
  >[0];

  const listSessionsParams = useCallback(
    (params: GetDocumentReviewSessionsParams) => ({
      meta: {
        errorMessage: `Error fetching document review sessions for candidate: ${getCompactId(params.candidateAlias)}`,
      },
      queryKey: QueryKeys.documentReviewSession.candidateAlias(params.candidateAlias).status(params.state),
      queryFn: async () => {
        if (!params || !params.candidateAlias) return null;

        let reviewState: ModelStringKeyConditionInput | undefined;
        if (params.state?.length === 1) {
          reviewState = { eq: params.state[0] };
        }
        if (params.state?.length && params.state.length > 1) {
          reviewState = { between: [params.state.sort()[0], params.state.sort()[1]] };
        }

        const reviewSessionsByCandidate = await getItems<
          ListDocumentReviewSessionsByCandidateQueryVariables,
          ListDocumentReviewSessionsByCandidateQuery
        >({
          query: listDocumentReviewSessionsByCandidate,
          input: { candidateAlias: params.candidateAlias, sessionState: reviewState },
        });

        const candidateSessionModels = getConcreteModels<ItemModel>(
          reviewSessionsByCandidate?.listDocumentReviewSessionsByCandidate?.items,
          isValidItem
        );

        return candidateSessionModels?.map((item) => getItemFromModel(item)) ?? [];
      },
    }),
    [getItems]
  );

  const listDocumentReviewSessionsByCandidateAlias = useCallback(
    async (input: GetDocumentReviewSessionsParams) => queryClient.fetchQuery({ ...listSessionsParams(input) }),
    [queryClient, listSessionsParams]
  );

  return {
    listDocumentReviewSessionsByCandidateAlias,
  };
}

export function useDocumentReviewSession(id: OptionalString): UseDocumentReviewSessionResult {
  const { getItem } = useApiQuery();
  const queryClient = useQueryClient();

  const getQueryParams = useCallback(
    (documentReviewSessionId: OptionalString) => ({
      meta: { errorMessage: `Error fetching document review session: ${getCompactId(documentReviewSessionId)}` },
      queryKey: QueryKeys.documentReviewSession.id(documentReviewSessionId),
      queryFn: async () => {
        if (!documentReviewSessionId) return null;
        const data = await getItem<GetDocumentReviewSessionRecordQueryVariables, GetDocumentReviewSessionRecordQuery>({
          query: getDocumentReviewSessionRecord,
          input: { id: documentReviewSessionId },
        });
        const models = getConcreteModels<ResourceModel>(data?.getDocumentReviewSessionRecord, isValidResource);
        return models?.length ? getResourceFromModel(models[0]) : null;
      },
    }),
    [getItem]
  );

  const getMinimalQueryParams = useCallback(
    (reviewSessionId: OptionalString) => ({
      meta: { errorMessage: `Error fetching document review session: ${getCompactId(reviewSessionId)}` },
      queryKey: QueryKeys.documentReviewSession.id(reviewSessionId),
      queryFn: async () => {
        if (!reviewSessionId) return null;
        const data = await getItem<GetMinimalDocumentReviewRecordQueryVariables, GetMinimalDocumentReviewRecordQuery>({
          query: getMinimalDocumentReviewRecord,
          input: { id: reviewSessionId },
        });
        const models = getConcreteModels<MinModel>(data?.getDocumentReviewSessionRecord, isValidMinResource);
        return models?.length ? getMinResourceFromModel(models[0]) : null;
      },
    }),
    [getItem]
  );

  const getReviewersQueryParams = useCallback(
    (reviewSessionId: OptionalString) => ({
      meta: { errorMessage: `Error fetching document review session reviewers for : ${getCompactId(reviewSessionId)}` },
      queryKey: QueryKeys.documentReviewSession.id(reviewSessionId),
      queryFn: async () => {
        if (!reviewSessionId) return null;
        const data = await getItem<GetDocumentReviewersQueryVariables, GetDocumentReviewersQuery>({
          query: getDocumentReviewers,
          input: { id: reviewSessionId },
        });
        const models = getConcreteModels<DocReviewerModel>(
          data?.getDocumentReviewSessionRecord,
          isValidDocReviewerResource
        );
        return models?.length ? getDocReviewerResourceFromModel(models[0]) : null;
      },
    }),
    [getItem]
  );

  const getExistsQueryParams = useCallback(
    (reviewSessionId: OptionalString) => ({
      meta: { errorMessage: `Error fetching document review session: ${getCompactId(reviewSessionId)}` },
      queryKey: QueryKeys.documentReviewSession.id(reviewSessionId),
      queryFn: async () => {
        if (!reviewSessionId) return null;
        const data = await getItem<GetDocumentReviewExistsQueryVariables, GetDocumentReviewExistsQuery>({
          query: getDocumentReviewExists,
          input: { id: reviewSessionId },
        });
        const models = getConcreteModels<ExistsModel>(data?.getDocumentReviewSessionRecord, isValidExistsResource);
        return models?.length ? getExistsResourceFromModel(models[0]) : null;
      },
    }),
    [getItem]
  );

  const getStateQueryParams = useCallback(
    (reviewSessionId: OptionalString) => ({
      meta: { errorMessage: `Error fetching document review session: ${getCompactId(reviewSessionId)}` },
      queryKey: QueryKeys.documentReviewSession.id(reviewSessionId),
      queryFn: async () => {
        if (!reviewSessionId) return null;
        const data = await getItem<GetDocumentReviewStateQueryVariables, GetDocumentReviewStateQuery>({
          query: getDocumentReviewState,
          input: { id: reviewSessionId },
        });
        const models = getConcreteModels<StateModel>(data?.getDocumentReviewSessionRecord, isValidStateResource);
        return models?.length ? getStateResourceFromModel(models[0]) : null;
      },
    }),
    [getItem]
  );

  const query = useQuery({
    ...getMinimalQueryParams(id),
    enabled: !!id,
  });

  const getDocumentReviewSession = useCallback(
    async (documentReviewSessionId: OptionalString) =>
      queryClient.fetchQuery({ ...getQueryParams(documentReviewSessionId) }),
    [queryClient, getQueryParams]
  );

  const getReviewersForDocumentSession = useCallback(
    async (documentReviewSessionId: OptionalString) =>
      queryClient.fetchQuery({ ...getReviewersQueryParams(documentReviewSessionId) }),
    [queryClient, getReviewersQueryParams]
  );

  const getExistsDocumentReviewSession = useCallback(
    async (documentReviewSessionId: OptionalString) =>
      queryClient.fetchQuery({ ...getExistsQueryParams(documentReviewSessionId) }),
    [queryClient, getExistsQueryParams]
  );

  const getMinimalDocumentReviewSession = useCallback(
    async (documentReviewSessionId: OptionalString) =>
      queryClient.fetchQuery({ ...getMinimalQueryParams(documentReviewSessionId) }),
    [queryClient, getMinimalQueryParams]
  );

  const getDocumentReviewSessionState = useCallback(
    async (documentReviewSessionId: OptionalString) =>
      queryClient.fetchQuery({ ...getStateQueryParams(documentReviewSessionId) }),
    [queryClient, getStateQueryParams]
  );

  return {
    getDocumentReviewSession,
    getReviewersForDocumentSession,
    getMinimalDocumentReviewSession,
    getDocumentReviewSessionState,
    getExistsDocumentReviewSession,
    documentReviewSession: query.data,
    isDocumentReviewSessionLoading: !!id && query.isPending,
  };
}

export function useDocumentReviewSessionActions(id?: OptionalString): UseDocumentReviewSessionActionsResult {
  const { createItem, updateItem, deleteItem } = useApiMutation();
  const { getDocumentReviewSessionState, getReviewersForDocumentSession } = useDocumentReviewSession(null);
  const { addNotification } = useNotifications();
  const queryClient = useQueryClient();
  const { detachReviewer } = useDocumentReviewerActions();

  type CreateParams = Parameters<DocumentReviewSessionActions['create']>[0];
  type UpdateParams = Parameters<DocumentReviewSessionActions['update']>[0];
  type DeleteParams = Parameters<DocumentReviewSessionActions['delete']>[0];

  const invalidateQueries = useCallback(async () => {
    const invalidations = [queryClient.invalidateQueries({ queryKey: QueryKeys.documentReviewSession.all })];
    await Promise.allSettled(invalidations);
  }, [queryClient]);

  const createMutation = useMutation({
    mutationFn: async (params: CreateParams) => {
      const input: CreateDocumentReviewSessionRecordInput = { ...params };

      if (!params.coOwners || params.coOwners.length < 1) {
        return null;
      }

      const data = await createItem<CreateDocumentReviewSessionRecordMutation>(
        createDocumentReviewSessionRecord,
        input
      );
      return data?.createDocumentReviewSessionRecord
        ? getResourceFromModel(data?.createDocumentReviewSessionRecord as DocumentReviewSessionModel)
        : null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully created document review session [${getCompactId(result?.id)}] for candidate [${result?.candidateAlias}]`,
      });
      invalidateQueries();
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (params: UpdateParams) => {
      const docReview = await getDocumentReviewSessionState(id);
      if (!docReview) return null;
      const input: UpdateDocumentReviewSessionRecordInput = {
        ...params,
        id: docReview.id,
        _version: docReview.version,
      };
      const data = await updateItem<UpdateDocumentReviewSessionRecordMutation>(
        updateDocumentReviewSessionRecord,
        input
      );
      return data?.updateDocumentReviewSessionRecord
        ? getResourceFromModel(data?.updateDocumentReviewSessionRecord as DocumentReviewSessionModel)
        : null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully updated document review ${getCompactId(result?.id)} for candidate [${result?.candidateAlias}]`,
      });
      invalidateQueries();
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (docReviewId: DeleteParams) => {
      const docReview = await getReviewersForDocumentSession(docReviewId ?? id);
      if (!docReview || docReview.sessionState !== DocumentReviewSessionState.SCHEDULED) return null;

      const updateInput: UpdateDocumentReviewSessionRecordInput = {
        id: docReview.id,
        sessionState: DocumentReviewSessionState.CANCELLED,
        _version: docReview.version,
      };

      const updateResult = await updateItem<UpdateDocumentReviewSessionRecordMutation>(
        updateDocumentReviewSessionRecord,
        updateInput
      );

      let deleteResult: Nullable<DeleteDocumentReviewSessionRecordMutation>;

      if (updateResult?.updateDocumentReviewSessionRecord) {
        if (docReview.documentReviewers?.length) {
          const reviewerMappings = docReview.documentReviewers.filter(
            (mapping): mapping is DocumentReviewSessionToReviewerMapping => !!mapping
          );
          await Promise.allSettled(
            reviewerMappings.map((mapping) => detachReviewer({ id: mapping.id, _version: mapping.version }))
          );
        }

        const input: DeleteDocumentReviewSessionRecordInput = {
          id: updateResult.updateDocumentReviewSessionRecord.id,
          _version: updateResult.updateDocumentReviewSessionRecord._version,
        };
        deleteResult = await deleteItem<DeleteDocumentReviewSessionRecordMutation>(
          deleteDocumentReviewSessionRecord,
          input
        );
      }

      return deleteResult?.deleteDocumentReviewSessionRecord ?? null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully deleted document review [${getCompactId(result?.id)}] for candidate [${result?.candidateAlias}`,
      });
      invalidateQueries();
    },
  });

  const onCreate = useCallback(async (params: CreateParams) => createMutation.mutateAsync(params), [createMutation]);
  const onUpdate = useCallback(async (params: UpdateParams) => updateMutation.mutateAsync(params), [updateMutation]);
  const onDelete = useCallback(
    async (docReviewId: DeleteParams) => deleteMutation.mutateAsync(docReviewId),
    [deleteMutation]
  );

  const reviewSessionActions = useMemo(
    () => ({ create: onCreate, update: onUpdate, delete: onDelete }),
    [onCreate, onUpdate, onDelete]
  );

  return {
    reviewSessionActions,
    isMutating: createMutation.isPending || updateMutation.isPending || deleteMutation.isPending,
  };
}

export function useDocumentReviewerActions(): UseDocumentReviewersResult {
  const { createItem, deleteItem } = useApiMutation();
  const { getItem } = useApiQuery();
  const queryClient = useQueryClient();

  type AttachByIdParams = Parameters<UseDocumentReviewersResult['attachReviewerById']>[0];
  type AttachByAliasParams = Parameters<UseDocumentReviewersResult['attachReviewerByAlias']>[0];
  type DetachParams = Parameters<UseDocumentReviewersResult['detachReviewer']>[0];
  type GetParams = Parameters<UseDocumentReviewersResult['getReviewerByAlias']>[0];

  const getReviewerQueryParams = useCallback(
    (params: GetParams) => ({
      meta: { errorMessage: `Error fetching calibrated document reviewer: ${params}` },
      queryKey: QueryKeys.calibratedDocumentReviewer.alias(params),
      queryFn: async () => {
        if (!params) return null;
        const data = await getItem<
          GetCalibratedDocumentReviewerRecordByAliasQueryVariables,
          GetCalibratedDocumentReviewerRecordByAliasQuery
        >({
          query: getCalibratedDocumentReviewerRecordByAlias,
          input: { alias: params },
        });
        const models = getConcreteModels<ReviewerItemModel>(
          data?.getCalibratedDocumentReviewerRecordByAlias?.items,
          isValidReviewerItem
        );
        return models?.length ? getReviewerItemFromModel(models[0]) : null;
      },
    }),
    [getItem]
  );

  const getReviewerExistsQueryParams = useCallback(
    (params: GetParams) => ({
      meta: { errorMessage: `Error fetching calibrated document reviewer: ${params}` },
      queryKey: QueryKeys.calibratedDocumentReviewer.alias(params),
      queryFn: async () => {
        if (!params) return null;
        const data = await getItem<
          GetCalibratedDocumentReviewerRecordExistsByAliasQueryVariables,
          GetCalibratedDocumentReviewerRecordExistsByAliasQuery
        >({
          query: getCalibratedDocumentReviewerRecordExistsByAlias,
          input: { alias: params },
        });
        const models = getConcreteModels<ReviewerExistsModel>(
          data?.getCalibratedDocumentReviewerRecordByAlias?.items,
          isValidReviewerExistsItem
        );
        return models?.length ? getReviewerItemExistsFromModel(models[0]) : null;
      },
    }),
    [getItem]
  );

  const attachReviewerByIdMutation = useMutation({
    mutationFn: async (input: AttachByIdParams) =>
      createItem<CreateDocumentReviewSessionToReviewerMappingMutation>(
        createDocumentReviewSessionToReviewerMapping,
        input
      ),
  });

  const attachReviewerByAliasMutation = useMutation({
    mutationFn: async (params: AttachByAliasParams) => {
      if (!params || !params?.alias || !params?.documentReviewSessionRecordID) return null;
      const reviewer = await queryClient.fetchQuery({ ...getReviewerExistsQueryParams(params.alias) });
      if (!reviewer) return null;

      const input: CreateDocumentReviewSessionToReviewerMappingInput = {
        documentReviewSessionRecordID: params.documentReviewSessionRecordID,
        calibratedDocumentReviewerRecordID: reviewer.id,
      };

      return createItem<CreateDocumentReviewSessionToReviewerMappingMutation>(
        createDocumentReviewSessionToReviewerMapping,
        input
      );
    },
  });

  const detachReviewerMutation = useMutation({
    mutationFn: async (params: DetachParams) => {
      let version = params._version;
      if (!version) {
        const reviewerMapping = await getItem<
          GetDocumentReviewSessionToReviewerMappingQueryVariables,
          GetDocumentReviewSessionToReviewerMappingQuery
        >({
          query: getDocumentReviewSessionToReviewerMapping,
          input: { id: params.id },
        });
        version = reviewerMapping?.getDocumentReviewSessionToReviewerMapping?._version ?? 1;
      }
      const input: DeleteDocumentReviewSessionToReviewerMappingInput = { id: params.id, _version: version };
      return deleteItem<DeleteDocumentReviewSessionToReviewerMappingMutation>(
        deleteDocumentReviewSessionToReviewerMapping,
        input
      );
    },
  });

  const attachReviewerById = useCallback(
    async (input: AttachByIdParams) => attachReviewerByIdMutation.mutateAsync(input),
    [attachReviewerByIdMutation]
  );

  const attachReviewerByAlias = useCallback(
    async (input: AttachByAliasParams) => attachReviewerByAliasMutation.mutateAsync(input),
    [attachReviewerByAliasMutation]
  );

  const detachReviewer = useCallback(
    async (input: DetachParams) => detachReviewerMutation.mutateAsync(input),
    [detachReviewerMutation]
  );

  const getReviewerByAlias = useCallback(
    async (input: GetParams) => queryClient.fetchQuery({ ...getReviewerQueryParams(input) }),
    [queryClient, getReviewerQueryParams]
  );

  const getReviewerExistsByAlias = useCallback(
    async (input: GetParams) => queryClient.fetchQuery({ ...getReviewerExistsQueryParams(input) }),
    [queryClient, getReviewerExistsQueryParams]
  );

  return { attachReviewerById, attachReviewerByAlias, detachReviewer, getReviewerByAlias, getReviewerExistsByAlias };
}

export function useDocumentReviewSessionVoteRecords(): UseDocumentReviewVotesResult {
  const { createItem, updateItem } = useApiMutation();
  const { addNotification } = useNotifications();
  const queryClient = useQueryClient();
  const { getItems, getItem } = useApiQuery();

  type ListVotesParams = Parameters<UseDocumentReviewVotesResult['listVotesBySessionId']>[0];
  type CreateVoteParams = Parameters<UseDocumentReviewVotesResult['onCreate']>[0];
  type UpdateVoteParams = Parameters<UseDocumentReviewVotesResult['onUpdate']>[0];

  const invalidateQueries = useCallback(async () => {
    const invalidations = [queryClient.invalidateQueries({ queryKey: QueryKeys.documentReviewSessionVotes.all })];
    await Promise.allSettled(invalidations);
  }, [queryClient]);

  const listVotesParams = useCallback(
    (params: ListVotesParams) => ({
      meta: { errorMessage: `Error fetching document review session votes for session: ${params}` },
      queryKey: QueryKeys.documentReviewSessionVotes.documentReviewSession(params.documentReviewSession),
      queryFn: async () => {
        if (!params) return null;
        const documentReviewSessionVotes = await getItems<
          ListVotesbyDocumentReviewSessionQueryVariables,
          ListVotesbyDocumentReviewSessionQuery
        >({
          query: listVotesbyDocumentReviewSession,
          input: params,
        });

        const documentReviewSessionVoteModels = getConcreteModels<SessionVoteItemModel>(
          documentReviewSessionVotes?.listVotesbyDocumentReviewSession?.items,
          isValidSessionVoteItem
        );

        return documentReviewSessionVoteModels?.map((item) => getSessionVoteItemFromModel(item)) ?? [];
      },
    }),
    [getItems]
  );

  const createMutation = useMutation({
    mutationFn: async (params: CreateVoteParams) => {
      const input: CreateDocumentReviewSessionVoteRecordInput = { ...params };

      if (!params || !params.documentReviewSession || !params.reviewerAlias || params.id) return null;

      const existingVoteInput = {
        reviewerAlias: params.reviewerAlias,
        documentReviewSession: { eq: params.documentReviewSession },
      };

      // Check to see if a vote for this reviewer and this review session already exist
      const existingVotes = await getItems<ListVotesbyReviewerQueryVariables, ListVotesbyReviewerQuery>({
        query: listVotesbyReviewer,
        input: existingVoteInput,
      });

      // Vote for this session+reviewer combo already exists, do not create a dupe
      if (existingVotes?.listVotesbyReviewer?.items?.length) {
        if (
          existingVotes?.listVotesbyReviewer?.items.map((vote) => {
            return vote?.documentReviewSession === params.documentReviewSession;
          })
        )
          return null;
      }

      const data = await createItem<CreateDocumentReviewSessionVoteRecordMutation>(
        createDocumentReviewSessionVoteRecord,
        input
      );
      return data?.createDocumentReviewSessionVoteRecord
        ? getSessionVoteItemFromModel(data?.createDocumentReviewSessionVoteRecord as SessionVoteItemModel)
        : null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully created document review session vote: ${getCompactId(result?.id)}`,
      });
      invalidateQueries();
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (params: UpdateVoteParams) => {
      if (!params || !params.id) return null;
      const existingVoteData = await getItem<
        GetDocumentReviewSessionVoteRecordQueryVariables,
        GetDocumentReviewSessionVoteRecordQuery
      >({
        query: getDocumentReviewSessionVoteRecord,
        input: { id: params.id },
      });

      if (!existingVoteData) return null;
      const existingVote = getSessionVoteItemFromModel(
        existingVoteData.getDocumentReviewSessionVoteRecord as SessionVoteItemModel
      );
      const input: UpdateDocumentReviewSessionVoteRecordInput = { ...params, _version: existingVote.version };
      const data = await updateItem<UpdateDocumentReviewSessionVoteRecordMutation>(
        updateDocumentReviewSessionVoteRecord,
        input
      );
      return data?.updateDocumentReviewSessionVoteRecord
        ? getSessionVoteItemFromModel(data?.updateDocumentReviewSessionVoteRecord as SessionVoteItemModel)
        : null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully updated document review session vote: ${getCompactId(result?.id)}`,
      });
      invalidateQueries();
    },
  });

  const listVotesBySessionId = useCallback(
    async (input: ListVotesParams) => queryClient.fetchQuery({ ...listVotesParams(input) }),
    [queryClient, listVotesParams]
  );

  const onCreate = useCallback(
    async (params: CreateVoteParams) => createMutation.mutateAsync(params),
    [createMutation]
  );
  const onUpdate = useCallback(
    async (params: UpdateVoteParams) => updateMutation.mutateAsync(params),
    [updateMutation]
  );

  return { listVotesBySessionId, onCreate, onUpdate };
}

export function useDocumentReviewSessionCommentRecords(): UseDocumentReviewSessionCommentsResult {
  const { createItem, updateItem, deleteItem } = useApiMutation();
  const { addNotification } = useNotifications();
  const queryClient = useQueryClient();
  const { getItems, getItem } = useApiQuery();
  const { getMinimalDocumentReviewSession, getDocumentReviewSessionState } = useDocumentReviewSession(null);

  type ListCommentsParams = Parameters<UseDocumentReviewSessionCommentsResult['listCommentsBySessionId']>[0];
  type CreateCommentParams = Parameters<UseDocumentReviewSessionCommentsResult['onCreateDocumentComment']>[0];
  type UpdateCommentParams = Parameters<UseDocumentReviewSessionCommentsResult['onUpdateDocumentComment']>[0];
  type DeleteCommentParams = Parameters<UseDocumentReviewSessionCommentsResult['onDeleteDocumentComment']>[0];

  const invalidateQueries = useCallback(async () => {
    const invalidations = [queryClient.invalidateQueries({ queryKey: QueryKeys.documentReviewSessionComments.all })];
    await Promise.allSettled(invalidations);
  }, [queryClient]);

  const listCommentsParams = useCallback(
    (params: ListCommentsParams) => ({
      meta: { errorMessage: `Error fetching document review session comments for session: ${params}` },
      queryKey: QueryKeys.documentReviewSessionComments.documentReviewSession(params.documentReviewId),
      queryFn: async () => {
        if (!params) return [];

        const reviewSession = await getMinimalDocumentReviewSession(params.documentReviewId);
        if (!reviewSession) return [];

        const { documentReviewers } = reviewSession;
        const validReviewer = documentReviewers.find((reviewer) => reviewer?.alias === params.alias);

        if (params.alias !== reviewSession?.ownerAlias && !validReviewer) return [];

        const reviewSessionComments = await getItems<
          ListCommentsByDocumentReviewQueryVariables,
          ListCommentsByDocumentReviewQuery
        >({
          query: listCommentsByDocumentReview,
          input: params,
        });

        const reviewSessionCommentModels = getConcreteModels<SessionCommentItemModel>(
          reviewSessionComments?.listCommentsByDocumentReview?.items,
          isValidSessionCommentItem
        )
          ?.filter((comment) => {
            return comment?._deleted !== true;
          })
          ?.sort((a, b) => {
            return (a?.createdAt ?? '').localeCompare(b?.createdAt ?? '');
          });

        return reviewSessionCommentModels?.map((item) => getSessionCommentItemFromModel(item)) ?? [];
      },
    }),
    [getItems, getMinimalDocumentReviewSession]
  );

  const createMutation = useMutation({
    mutationFn: async (params: CreateCommentParams) => {
      const input: CreateDocumentReviewCommentRecordInput = { ...params };

      if (!params || !params.documentReviewId || !params.alias || !params.content) return null;

      const reviewSessionState = await getDocumentReviewSessionState(params.documentReviewId);

      if (
        !reviewSessionState ||
        reviewSessionState.sessionState === DocumentReviewSessionState.CANCELLED ||
        reviewSessionState.sessionState === DocumentReviewSessionState.COMPLETE
      ) {
        return null;
      }

      const data = await createItem<CreateDocumentReviewCommentRecordMutation>(
        createDocumentReviewCommentRecord,
        input
      );
      return data?.createDocumentReviewCommentRecord
        ? getSessionCommentItemFromModel(data?.createDocumentReviewCommentRecord as SessionCommentItemModel)
        : null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully created document review session comment: ${getCompactId(result?.id)}`,
      });
      invalidateQueries();
    },
  });

  const updateMutation = useMutation({
    mutationFn: async (params: UpdateCommentParams) => {
      if (!params || !params.id) return null;
      const existingCommentData = await getItem<
        GetDocumentReviewCommentRecordQueryVariables,
        GetDocumentReviewCommentRecordQuery
      >({
        query: getDocumentReviewCommentRecord,
        input: { id: params.id },
      });

      if (!existingCommentData) return null;
      const existingComment = getSessionCommentItemFromModel(
        existingCommentData.getDocumentReviewCommentRecord as SessionCommentItemModel
      );
      const input: UpdateDocumentReviewCommentRecordInput = { ...params, _version: existingComment.version };
      const data = await updateItem<UpdateDocumentReviewCommentRecordMutation>(
        updateDocumentReviewCommentRecord,
        input
      );
      return data?.updateDocumentReviewCommentRecord
        ? getSessionCommentItemFromModel(data?.updateDocumentReviewCommentRecord as SessionCommentItemModel)
        : null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully updated document review session comment: ${getCompactId(result?.id)}`,
      });
      invalidateQueries();
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (params: DeleteCommentParams) => {
      if (!params || !params.id) return null;
      const existingCommentData = await getItem<
        GetDocumentReviewCommentRecordQueryVariables,
        GetDocumentReviewCommentRecordQuery
      >({
        query: getDocumentReviewCommentRecord,
        input: { id: params.id },
      });

      if (!existingCommentData) return null;
      const existingComment = getSessionCommentItemFromModel(
        existingCommentData.getDocumentReviewCommentRecord as SessionCommentItemModel
      );
      const input: DeleteDocumentReviewCommentRecordInput = { ...params, _version: existingComment.version };
      const data = await deleteItem<DeleteDocumentReviewCommentRecordMutation>(
        deleteDocumentReviewCommentRecord,
        input
      );
      return data?.deleteDocumentReviewCommentRecord
        ? getSessionCommentItemFromModel(data?.deleteDocumentReviewCommentRecord as SessionCommentItemModel)
        : null;
    },
    onSuccess: (result) => {
      addNotification({
        type: 'success',
        header: `Successfully deleted document review session comment: ${getCompactId(result?.id)}`,
      });
      invalidateQueries();
    },
  });

  const listCommentsBySessionId = useCallback(
    async (input: ListCommentsParams) => queryClient.fetchQuery({ ...listCommentsParams(input) }),
    [queryClient, listCommentsParams]
  );

  const onCreateDocumentComment = useCallback(
    async (params: CreateCommentParams) => createMutation.mutateAsync(params),
    [createMutation]
  );

  const onUpdateDocumentComment = useCallback(
    async (params: UpdateCommentParams) => updateMutation.mutateAsync(params),
    [updateMutation]
  );

  const onDeleteDocumentComment = useCallback(
    async (params: DeleteCommentParams) => deleteMutation.mutateAsync(params),
    [deleteMutation]
  );

  return {
    listCommentsBySessionId,
    onCreateDocumentComment,
    onUpdateDocumentComment,
    onDeleteDocumentComment,
    isMutating: createMutation.isPending || updateMutation.isPending || deleteMutation.isPending,
  };
}
