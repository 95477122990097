import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import { CardsProps } from '@amzn/awsui-components-react/polaris/cards';
import Header from '@amzn/awsui-components-react/polaris/header';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import type { UseCollectionResult } from '@amzn/awsui-collection-hooks';
import MarkdownContent from '@/common/MarkdownContent';
import { Nullable, PanelReviewSessionCommentItem } from '@/models';
import { PHONETOOL_URL, RESOURCES } from '@/common/constants';
import { getFormattedDate } from '@/common/utils';
import UserPhoto from '@/components/common/person/UserPhoto';
import { useAppContext } from '@/contexts';
import { PromoButton } from '@/components/common/Buttons';
import { getNameDefinition } from '@/common/labels';
import { PanelReviewState } from '@/api/API';

const resourceName = getNameDefinition(RESOURCES.COMMENT);

interface TimestampParams {
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
}

/**
 * Set the timestamp based on the createdAt.
 * If the comment was edited, append that date to the right in a different font.
 * Amplify automatically sets the createdAt and updatedAt to the same value on creation,
 * so we can safely assume if they differ, the comment was changed.
 */
const CommentTimestamp = React.memo<TimestampParams>(
  ({ createdAt, updatedAt }): React.ReactElement => (
    <Box fontSize="body-s" variant="awsui-key-label">
      {getFormattedDate(createdAt)}
      {updatedAt && updatedAt.getTime() !== createdAt?.getTime() && (
        <Box variant="span" fontSize="body-s" color="text-body-secondary">
          {` (edited: ${getFormattedDate(updatedAt)})`}
        </Box>
      )}
    </Box>
  )
);

export const CommentCardDefinition = (): CardsProps.CardDefinition<PanelReviewSessionCommentItem> => {
  const ProfilePicture = ({ profileAlias }) => (
    <Box float="left" display="inline">
      <UserPhoto alias={profileAlias} imageSize="s" />
    </Box>
  );

  const Author = ({ profileAlias }) => (
    <Link fontSize="heading-xs" target="_blank" href={`${PHONETOOL_URL}${profileAlias}`}>
      {`@${profileAlias}`}
    </Link>
  );

  return {
    header: (item) => (
      <div>
        <ProfilePicture profileAlias={item.alias} />
        <Author profileAlias={item.alias} />
        <CommentTimestamp createdAt={item.createdAt} updatedAt={item.updatedAt} />
      </div>
    ),
    sections: [
      {
        id: 'comment-content',
        content: (item) => <MarkdownContent>{item.content}</MarkdownContent>,
      },
    ],
  };
};

interface CommentCardHeaderParams {
  item: PanelReviewSessionCommentItem | undefined;
  setIsCreateEditVisible: (v: boolean) => void;
  setCreateEditMode: (v: 'create' | 'edit') => void;
  setIsDeleteVisible: (v: boolean) => void;
  voteStage: PanelReviewState;
  countText: string;
}

/**
 * The `Header` element of the CommentCard. This component is unique
 * because there isn't a need to `title` the comment cards but there is a need to provide
 * action buttons and a text filter. To save real-estate, the filter component is crammed
 * where the header text would normally be.
 *
 */
export const CommentCardHeader = ({
  item,
  setIsCreateEditVisible,
  setCreateEditMode,
  setIsDeleteVisible,
  voteStage,
  ...props
}: CommentCardHeaderParams & UseCollectionResult<PanelReviewSessionCommentItem>['filterProps']): React.ReactElement => {
  const { currentUser, spoofUser } = useAppContext();
  const user = spoofUser?.alias || currentUser?.alias;

  return (
    <Header
      headingTagOverride="h5"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <PromoButton
            resourceName={resourceName}
            action="edit"
            targetType="function"
            isDisabled={item?.alias !== user}
            actionParams={{
              doAction: () => {
                setCreateEditMode('edit');
                setIsCreateEditVisible(true);
              },
            }}
          />
          <PromoButton
            resourceName={resourceName}
            action="delete"
            targetType="function"
            isDisabled={item?.alias !== user}
            actionParams={{
              doAction: () => {
                setIsCreateEditVisible(false);
                setIsDeleteVisible(true);
              },
            }}
          />
          <PromoButton
            resourceName={resourceName}
            action="create"
            targetType="function"
            isDisabled={
              voteStage === PanelReviewState.CANCELLED ||
              voteStage === PanelReviewState.SCHEDULED ||
              voteStage === PanelReviewState.COMPLETE
            }
            actionParams={{
              doAction: () => {
                setCreateEditMode('create');
                setIsCreateEditVisible(true);
              },
            }}
          />
        </SpaceBetween>
      }
    >
      <TextFilter {...props} filteringPlaceholder="Find comments" />
    </Header>
  );
};
