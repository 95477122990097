import { useState, ForwardedRef, forwardRef, memo } from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import { useTableContext } from '@/contexts/CrossTableContext';
import { useDocumentReviewSessionActions } from '@/api/document-review';

interface CancelDocumentReviewSessionModalParams {
  isVisible: boolean;
  onSetVisibility: (v: boolean) => void;
  contextKey: string;
}

const CancelDocumentReviewSessionComponent = (
  { contextKey, isVisible, onSetVisibility }: CancelDocumentReviewSessionModalParams,
  ref: ForwardedRef<HTMLElement>
) => {
  // Extract the forwarded ref, and pass into the Polaris `Modal` component.
  // the component uses this to anchor the modal. This ensures consistent styling and z-axis behavior.
  const parentRef = (ref as React.MutableRefObject<HTMLElement>) || undefined;
  const { selectedId } = useTableContext(contextKey ?? '');
  const [isCancelling, setIsCancelling] = useState(false);
  // const { documentReviewSession, isDocumentReviewSessionLoading } = useDocumentReviewSession(selectedId);
  const { reviewSessionActions, isMutating } = useDocumentReviewSessionActions();

  function resetModal() {
    onSetVisibility(false);
  }

  const deleteDocumentReviewSession = async () => {
    if (!selectedId) return;
    try {
      await reviewSessionActions.delete(selectedId);
    } finally {
      setIsCancelling(false);
      resetModal();
    }
  };

  const onCancel = () => {
    setIsCancelling(true);
    void deleteDocumentReviewSession();
  };

  return (
    <Modal
      modalRoot={parentRef?.current || undefined}
      onDismiss={() => resetModal()}
      closeAriaLabel="Close dialog"
      visible={isVisible}
      size="medium"
      header="Cancel review session"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" disabled={isCancelling || isMutating} onClick={() => resetModal()}>
              Back
            </Button>
            <Button
              variant="primary"
              loading={isCancelling}
              disabled={isCancelling || isMutating}
              onClick={() => onCancel()}
            >
              {isCancelling ? 'Cancelling...' : 'Confirm'}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween direction="vertical" size="m">
        <Box variant="span">
          Cancel review session{` `}
          <Box variant="span" fontWeight="bold">
            {selectedId}
          </Box>
          {` `}
          permanently? This action cannot be undone.
        </Box>
        <Alert statusIconAriaLabel="Info">
          Proceeding with this action will cancel the selected document review session.{' '}
        </Alert>
      </SpaceBetween>
    </Modal>
  );
};

const CancelDocumentReviewSessionModal = memo(forwardRef(CancelDocumentReviewSessionComponent));

export default CancelDocumentReviewSessionModal;
