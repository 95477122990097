import Modal from '@amzn/awsui-components-react/polaris/modal';
import { Box, SpaceBetween, Button } from '@amzn/awsui-components-react';
import { Reviewer } from '../config';
import { PanelReviewState } from '@/api/API';
import { PANEL_REVIEW_STATE_TEXT } from '@/common/constants';

interface WarningModalProps {
  visible: boolean;
  missingReviewerVotes: Array<Reviewer>;
  reviewState: PanelReviewState;
  futureState: PanelReviewState;
  onDismiss: () => void;
}

const WarningModal: React.FC<WarningModalProps> = ({
  visible,
  missingReviewerVotes,
  reviewState,
  futureState,
  onDismiss,
}) => (
  <Modal
    onDismiss={onDismiss}
    visible={visible}
    header="Panel vote(s) missing"
    footer={
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="primary" onClick={onDismiss}>
            Close
          </Button>
        </SpaceBetween>
      </Box>
    }
  >
    <p>
      Cannot progress review to {PANEL_REVIEW_STATE_TEXT[futureState]}, missing reviewer vote(s) for{' '}
      {PANEL_REVIEW_STATE_TEXT[reviewState]}:
      <br />
      <br />
      {missingReviewerVotes.map((reviewer) => (
        <li key={reviewer.alias}>
          {reviewer.firstName} {reviewer.lastName} (@{reviewer.alias})
        </li>
      ))}
    </p>
  </Modal>
);

export default WarningModal;
