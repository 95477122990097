import type { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import {
  AuditTrailRecordType,
  Density,
  DocumentReviewSessionState,
  DocumentReviewSessionVote,
  PanelReviewSessionVote,
  PanelReviewState,
  PathDefinitionType,
  PeerReviewStatus,
  PeerReviewType,
  PromoPathStatus,
  UserProfileStatus,
  VisualMode,
  WorkSummaryStatus,
} from '@/api/API';
import { AuthGroup } from '../resources/rbac-constants';

export type Nullable<T> = T | null | undefined;

export type OptionalString = Nullable<string>;
export type OptionalNumber = Nullable<number>;
export type OptionalBool = Nullable<boolean>;

export type BrowserState = Nullable<Record<string, Nullable<string | string[] | Item | Item[]>>>;

interface ToolsContentSetterParams {
  sectionKey?: string;
  content?: JSX.Element;
}

export interface ToolsContentSetter {
  (params: ToolsContentSetterParams): void;
}

interface PeopleProfileBase {
  alias: string;
  email: string;
  firstName?: OptionalString;
  isManager: boolean;
  jobLevel: number;
  lastName?: OptionalString;
  name?: string;
}

interface PeopleManager {
  alias: string;
  name: string;
}

export interface DirectReportRecord extends PeopleProfileBase {
  hireDate: Nullable<Date>;
}

export interface PeopleProfileRecord extends PeopleProfileBase {
  jobTitle: string;
  manager: PeopleManager;
  orgName?: string;
}

export interface PromoUserProfile extends PeopleProfileBase {
  jobTitle: string;
  manager: PeopleManager;
  name: string;
  orgName: string;
  personId: string;
  identityId?: OptionalString;
  hasLoggedIn?: boolean;
  hireDate?: OptionalString;
  groups?: AuthGroup[];
  isValidUser?: boolean;
  version?: number;
  status?: UserProfileStatus;
  preferences?: {
    visualMode: Nullable<VisualMode>;
    density: Nullable<Density>;
  };
}

export type PromoNotification = FlashbarProps.MessageDefinition & {
  timestamp?: number;
};

export enum ViewerType {
  CANDIDATE,
  MANAGER,
  PEER_REVIEWER,
}

/** A generic item. All resource/object models should extend this. */
export interface Item {
  id: string;
}

interface PromoPathBase extends Item {
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
  name: string;
  startLevel: number;
  targetLevel: number;
  allowsAttachments: boolean;
  suggestedLPs: string[];
  role: string;
  version: number;
  status: PromoPathStatus;
  type: PathDefinitionType;
}

export interface ManagerPathItem extends PromoPathBase {
  managerAlias: string;
  candidateCount: number;
  type: PathDefinitionType.MANAGER;
}

/** Full `PromoPath` template resource with all fields. Used in create/view/edit */
export interface ManagerPathResource extends ManagerPathItem {
  categories: CategoryResource[];
  totalRequired?: OptionalNumber;
}

export interface CandidatePathItem extends PromoPathBase {
  candidate: string;
  manager: string;
  targetQuarter: string;
  totalCompleted?: OptionalNumber;
  totalRequired?: OptionalNumber;
  progressPct?: OptionalNumber;
  type: PathDefinitionType.CANDIDATE;
}

export interface CandidatePathResource extends CandidatePathItem {
  categories: CategoryResource[];
}

/** Base `Category` with key fields. Description is optional here. Used for listing or pulling back in relationships */
export interface CategoryItem extends Item {
  label: string;
  description?: string;
  version?: number;
  value?: string;
}

/** Full `Category` with all fields. Used when working with `PromoPath` and `CandidatePromoPath` */
export interface CategoryResource extends CategoryItem {
  description: string;
  isDefault: boolean;
}

export interface BaselineRoleDimensionItem {
  jobLevel?: number;
  description?: string;
  title?: string;
  version?: number;
  id?: string;
  items?: string;
}

export interface BaselineRoleDimensionOptionItem extends BaselineRoleDimensionItem {
  id?: string;
  label?: string;
  description?: string;
  version?: number;
}

export interface BaselineRoleDimensionResource extends BaselineRoleDimensionItem {
  baselineRoleDimensionItem?: BaselineRoleDimensionResource[];
}

export interface BaselineRoleDimensionByIdResource extends BaselineRoleDimensionItem {
  title?: string;
  isDefault?: boolean;
}

export interface BaselineRoleDimension {
  createdAt: string;
  id: string;
  updatedAt: string;
  jobLevel: number;
  title: string;
  description: string;
  jobRole: string;
  _version: number;
  _deleted?: boolean | null;
  _lastChangedAt: number;
  lastModifiedBy: string;
  owner?: string | null;
  __typename: 'BaselineRoleDimension';
}

export interface ListBaselineRoleDimensionsByLevelQueryResult {
  listBaselineRoleDimensionsByLevel: {
    items: (BaselineRoleDimension | null)[];
    nextToken?: string | null;
    startedAt?: number | null;
    __typename: 'ModelBaselineRoleDimensionConnection';
  };
}
/** Base `FileRecord` returned when listing files or retrieving with a `WorkSummary` */
export interface FileRecordItem extends Item {
  name: string;
  size: number;
  version: number;
  uploadedAt: Nullable<Date>;
  fileDateModified: Nullable<Date>;
}

/** Full `FileRecord` - includes s3Key. Used in creating/downloading. */
export interface FileRecordResource extends FileRecordItem {
  s3Key: string;
  storageIdKey: string;
  ownerAlias: string;
  workSummaries: { id: string; title: string }[];
}

export interface FileRecordAttachment extends FileRecordItem {
  fileAttachmentId?: string;
}

/** Base `WorkSummary` used in listing / collections. Doesn't include certain large/expensive fields */
export interface WorkSummaryBase extends Item {
  alias: string;
  title: string;
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
  status: WorkSummaryStatus | keyof typeof WorkSummaryStatus;
  workSummaryType: string;
  statusReason: string | undefined;
  promoPathId: OptionalString;
  submittedAt: Nullable<Date>;
  leadershipPrinciples?: string[];
  baselineRoleDimensionIds?: string[];
  version: number;
  categories: CategoryItem[];
  coOwners: Set<string>;
}

export interface WorkSummaryItem extends WorkSummaryBase {
  commentCount: number;
}

export interface WorkSummaryAdminItem extends WorkSummaryBase {
  reviewerAlias: string | undefined;
  managerAlias: string | undefined;
  candidatePromoPathVersion: number;
}

export interface CandidateWorkSummaryItem extends WorkSummaryItem {
  name: string;
}

/** Full `WorkSummary` - includes all fields. Used for create/update/view */
export interface WorkSummaryResource extends WorkSummaryBase {
  content: string;
  contentVersion: number;
  leadershipPrinciples: string[];
  baselineRoleDimensionIds?: string[];
  documents: FileRecordAttachment[];
  peerReviewers: { alias: string; name: string; id: string; version: number }[];
}

export interface PeerReviewBase extends Item {
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
  requiredBy: Nullable<Date>;
  reviewerAlias: string;
  content: string;
  reviewType: Nullable<PeerReviewType>;
  reviewStatus: PeerReviewStatus;
  version: number;
}

export interface PeerReviewItem extends PeerReviewBase {
  workSummaryId: string;
  workSummaryTitle: OptionalString;
  candidateAlias: OptionalString;
  candidateManagerAlias: OptionalString;
  commentCount: number;
  reviewerName: OptionalString;
  reviewedVersion: OptionalNumber;
  latestVersion: OptionalNumber;
}

export interface PeerReviewResource extends PeerReviewItem {
  workSummaryDetail: string;
  workSummaryType: OptionalString;
  workSummaryCategories: CategoryItem[];
  candidatePromoPathTargetLevel: OptionalNumber;
  candidatePromoPathName: OptionalString;
  documents: (FileRecordItem & { workSummaryFileId?: OptionalString })[];
}

export interface PeerReviewRequest {
  userProfileExists: boolean;
  alias?: string;
  name?: string;
  email?: string;
  manager?: string;
  isManager?: boolean;
  jobLevel?: number;
  reviewType?: PeerReviewType | null | undefined;
  isVerified: boolean;
  requestId?: string;
  requestStatus: PeerReviewStatus;
  version?: number;
}

export interface CommentItem extends Item {
  authorAlias: string;
  authorName: string;
  content: string;
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
  workSummaryId?: string | null | undefined;
  version: number;
}

export interface AuditTrailItem extends Item {
  modifiedByAlias: string;
  humanreadablelog: string[];
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
  recordType: AuditTrailRecordType;
  recordId: string;
}

export interface PromoReadinessBase {
  alias: string;
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
  id: string;
  certList?: Array<string> | null;
  techPillar?: Array<string> | null;
  tfcMembership: string;
  rating: string;
  feedbackProviders?: Array<string> | null;
  otherContributions: string;
  lastModifiedBy: string;
  version: number;
}

export type PapiJobProfile = {
  __typename: 'PapiJobProfile';
  businessTitle?: string | null;
  jobLevel?: number | null;
  hireDate?: string | null;
  lastHireDate?: string | null;
  costCenterEntryDate?: string | null;
  jobEntryDate?: string | null;
  departmentEntryDate?: string | null;
};

export type PapiLocationProfile = {
  __typename: 'PapiLocationProfile';
  buildingCode: string;
  city?: string | null;
  country?: string | null;
  name?: string | null;
  timezone?: string | null;
};

export interface PapiProfile {
  username: string;
  personId: string;
  firstName: string;
  lastName?: string | null;
  jobLevel?: string | null;
  job?: Array<PapiJobProfile> | null;
  emailAddress?: string | null;
  isManager?: string | null;
  businessTitle?: string | null;
  jobFamilyName?: string | null;
  manager?: string | null;
  skipManager?: string | null;
  employeeId?: string | null;
  costCenterId?: number;
  lastHireDate?: string | null;
  location?: Array<PapiLocationProfile> | null;
}

export interface ReviewSessionBase extends Item {
  candidateAlias: string;
  ownerAlias: string;
  sessionStart: string;
  version: number;
}

export interface DocumentReviewSessionItem extends ReviewSessionBase {
  sessionState: DocumentReviewSessionState;
}

export interface DocumentReviewSessionResource extends DocumentReviewSessionItem {
  chimeLink: Nullable<string>;
  documentReviewers: Array<CalibratedReviewerItem> | [];
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
  coOwners: string[];
  workdocsLink: string;
}

export interface PanelReviewSessionItem extends ReviewSessionBase {
  sessionState: PanelReviewState;
}

export interface PanelReviewSessionResource extends PanelReviewSessionItem {
  areaLeadAlias: string;
  documentReviewSession: string;
  chimeLink: string;
  panelReviewers: Array<CalibratedReviewerItem> | [];
  workdocsLink: string;
  coOwners: string[];
}

export interface PanelReviewSessionViewResource extends PanelReviewSessionItem {
  areaLeadAlias: string;
  documentReviewSession: string;
  chimeLink: string;
  workdocsLink: string;
  coOwners: string[];
  panelReviewers: Array<MinimalCalibratedReviewerItem> | [];
}

export interface PanelReviewSessionMinResource extends Item {
  candidateAlias: string;
  ownerAlias: string;
  areaLeadAlias: string;
  sessionState: PanelReviewState;
  panelReviewers: Array<MinimalCalibratedReviewerItem> | [];
}

export interface PanelReviewSessionStateResource extends Item {
  sessionState: PanelReviewState;
  version: number;
}

export interface DocumentReviewSessionMinResource extends ReviewSessionBase {
  sessionState: DocumentReviewSessionState;
  documentReviewers: Array<MinimalCalibratedReviewerItem> | [];
  chimeLink: Nullable<string>;
  workdocsLink: string;
  coOwners: string[];
}

export interface DocumentReviewerResource extends Item {
  sessionState: DocumentReviewSessionState;
  documentReviewers: Array<MinimalCalibratedReviewerItem> | [];
  version: number;
}

export interface PanelReviewerResource extends Item {
  sessionState: PanelReviewState;
  panelReviewers: Array<MinimalCalibratedReviewerItem> | [];
  version: number;
}

export interface DocumentReviewSessionStateResource extends Item {
  sessionState: DocumentReviewSessionState;
  version: number;
}

export interface ExistsResource extends Item {
  version: number;
}

export interface CalibratedReviewerItem extends Item {
  alias: string;
  completedSessions: number | 0;
}

export interface MinimalCalibratedReviewerItem extends Item {
  alias?: string;
  version?: number;
}

export interface CalibratedReviewerResource extends CalibratedReviewerItem {}

export interface DocumentReviewSessionVoteItem extends Item {
  alias: string;
  documentReviewSessionId: string;
  documentReady?: DocumentReviewSessionVote;
  candidateReady?: DocumentReviewSessionVote;
  version: number;
}

export interface PanelReviewSessionVoteItem extends Item {
  alias: string;
  panelReviewSessionId: string;
  vote: PanelReviewSessionVote;
  voteStage: PanelReviewState;
  version: number;
}

export interface ReviewSessionCommentItem extends Item {
  alias: string;
  reviewSessionId: string;
  content: string;
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
  version: number;
}

export interface PanelReviewSessionCommentItem extends Item {
  alias: string;
  panelReviewSessionId: string;
  voteStage: PanelReviewState;
  content: string;
  createdAt: Nullable<Date>;
  updatedAt: Nullable<Date>;
  version: number;
}

export interface DocumentReviewSessionCommentItem extends ReviewSessionCommentItem {
  voteStage: DocumentReviewSessionState;
}
