import { SpaceBetween, TextContent, Box, Button, Form, Select, StatusIndicator } from '@amzn/awsui-components-react';
import { DocumentReviewSessionMinResource } from '@/models';
import { RESOURCES } from '@/common/constants';
import { RoutableLink } from '@/components/common/links';
import { Owner } from '../config';
import { DocumentReviewSessionState } from '@/api/API';

interface SessionInfoProps {
  documentReviewSession: DocumentReviewSessionMinResource;
  isOwner: boolean;
  owner: Owner;
  initialSessionState: DocumentReviewSessionState;
  sessionState: DocumentReviewSessionState;
  onStateChange: (newState: DocumentReviewSessionState) => void;
  onSaveStateChange: (newState: DocumentReviewSessionState) => void;
}

const DocInfo: React.FC<SessionInfoProps> = ({
  documentReviewSession,
  isOwner,
  owner,
  initialSessionState,
  sessionState,
  onStateChange,
  onSaveStateChange,
}) => (
  <SpaceBetween size="m">
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Session ID:
      </Box>{' '}
      <RoutableLink
        href={`${RESOURCES.DOCUMENT_REVIEW_SESSION.hrefToReview}/${documentReviewSession.id?.toString()}`}
        text={documentReviewSession.id?.toString()}
      />
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Workdocs:
      </Box>{' '}
      <a href={documentReviewSession.workdocsLink} target="_blank" rel="noopener noreferrer">
        Link
      </a>
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Chime Link:
      </Box>{' '}
      <a href={documentReviewSession.chimeLink ?? ''} target="_blank" rel="noopener noreferrer">
        {documentReviewSession.chimeLink}
      </a>
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Session Date:
      </Box>{' '}
      {documentReviewSession.sessionStart?.toString()}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Session Owner:
      </Box>{' '}
      {`${owner.firstName} ${owner.lastName} (@${owner.alias})`}
    </TextContent>
    <TextContent>
      <Box fontWeight="bold" variant="span">
        Session State:
      </Box>{' '}
      <StatusIndicator type="pending">{initialSessionState}</StatusIndicator>
    </TextContent>
    {isOwner ? (
      <form onSubmit={(e) => e.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="primary"
                disabled={sessionState === initialSessionState}
                onClick={() => onSaveStateChange(sessionState)}
              >
                {sessionState === DocumentReviewSessionState.COMPLETE ? 'Complete' : 'Save'}
              </Button>
            </SpaceBetween>
          }
        >
          <TextContent>
            <h4>Modify session state</h4>
          </TextContent>
          <Select
            selectedOption={{ label: sessionState }}
            onChange={({ detail }) => onStateChange(detail.selectedOption.value as DocumentReviewSessionState)}
            options={[
              {
                label: DocumentReviewSessionState.SCHEDULED,
                value: DocumentReviewSessionState.SCHEDULED,
                disabled: initialSessionState !== DocumentReviewSessionState.COMPLETE,
              },
              {
                label: DocumentReviewSessionState.COMPLETE,
                value: DocumentReviewSessionState.COMPLETE,
                disabled: initialSessionState !== DocumentReviewSessionState.SCHEDULED,
              },
            ]}
          />
        </Form>
      </form>
    ) : null}
  </SpaceBetween>
);

export default DocInfo;
