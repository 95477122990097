import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Form from '@amzn/awsui-components-react/polaris/form';
import { useNavigate } from 'react-router-dom';
import { Nullable, PanelReviewSessionViewResource } from '@/models';
import ViewPanelReviewSession from './ViewPanelReviewSession';

interface FormParams {
  panelReviewSession?: Nullable<PanelReviewSessionViewResource>;
}

const PanelReviewSessionViewForm = ({ panelReviewSession }: FormParams): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Form
      actions={
        <SpaceBetween direction="horizontal" size="l">
          <Button onClick={() => navigate(-1)} variant="link">
            Back
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        <ViewPanelReviewSession panelReviewSession={panelReviewSession} />
      </SpaceBetween>
    </Form>
  );
};

export default PanelReviewSessionViewForm;
