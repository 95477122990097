import React, { useState, useEffect } from 'react';
import { SpaceBetween, Cards } from '@amzn/awsui-components-react/polaris';
import CandidateInfo from './components/CandidateInfo';
import DocInfo from './components/SessionInfo';
import VotingSection from './components/VotingSection';
import WarningModal from './components/WarningModal';
import { usePapiProfile } from '@/api/amzn-people';
import {
  DocumentReviewSessionVoteItem,
  DocumentReviewSessionCommentItem,
  DocumentReviewSessionMinResource,
} from '@/models';
import { DocumentReviewSessionState, DocumentReviewSessionVote } from '@/api/API';
import { useAppContext } from '@/contexts';
import {
  useDocumentReviewSessionVoteRecords,
  useDocumentReviewSessionActions,
  useDocumentReviewSessionCommentRecords,
  useDocumentReviewSession,
} from '@/api/document-review';
import { Owner, REVIEW_SESSION_REFRESH_SECONDS, Reviewer } from './config';
import { MIN_REVIEWERS_PER_DOCUMENT_REVIEW } from '@/common/constants';
import { DocumentReviewSessionsPage } from '@/common/pages';
import useNavigator from '@/common/hooks/use-navigator';
import CommentList from '../DocumentReviewComment/CommentList';

const ViewDocumentReviewSession = ({ documentReviewSession }) => {
  const [localReviewSessionData] = useState<DocumentReviewSessionMinResource>(documentReviewSession);
  const [initialSessionState, setInitialSessionState] = useState<DocumentReviewSessionState>(
    localReviewSessionData.sessionState
  );
  const [reviewSessionState, setReviewSessionState] = useState(localReviewSessionData.sessionState);
  const [userVote, setUserVote] = useState({ documentReady: null, candidateReady: null });
  const [documentReadyVote, setDocumentReadyVote] = useState<DocumentReviewSessionVote>();
  const [candidateReadyVote, setCandidateReadyVote] = useState<DocumentReviewSessionVote>();
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [overrideReason, setOverrideReason] = useState('');
  const [overrideReasonValidationText, setOverrideReasonValidationText] = useState<string>('');
  const [owner, setOwner] = useState<Owner>({ alias: '', firstName: '', lastName: '' });
  const [reviewers, setReviewers] = useState<Array<Reviewer>>([]);
  const [isReviewersLoading, setIsReviewersLoading] = useState(true);
  const { reviewSessionActions } = useDocumentReviewSessionActions(localReviewSessionData.id);
  const { listVotesBySessionId, onCreate, onUpdate } = useDocumentReviewSessionVoteRecords();
  const { listCommentsBySessionId, isMutating } = useDocumentReviewSessionCommentRecords();
  const { getDocumentReviewSessionState } = useDocumentReviewSession(null);
  const [sessionVotes, setSessionVotes] = useState<Map<string, DocumentReviewSessionVoteItem>>(new Map());
  const [comments, setComments] = useState<Array<DocumentReviewSessionCommentItem>>([]);
  const { user: candidateProfile } = usePapiProfile(localReviewSessionData.candidateAlias);
  const { getPapiProfile } = usePapiProfile();
  const { currentUser, spoofUser } = useAppContext();
  const { goToPage } = useNavigator();
  const user = spoofUser?.alias || currentUser?.alias;

  const isOwner = user === localReviewSessionData.ownerAlias;

  const handleVoteRefresh = () => {
    void listVotesBySessionId({ documentReviewSession: localReviewSessionData.id, alias: user }).then((results) => {
      setSessionVotes(new Map(results?.map((vote) => [vote.alias, vote])));
    });
  };

  useEffect(() => {
    const reviewerProfiles: Reviewer[] = [];
    let reviewerPapiCount = 0;
    localReviewSessionData.documentReviewers.forEach((reviewer) => {
      void getPapiProfile(reviewer.alias).then((result) => {
        reviewerProfiles.push({
          alias: reviewer.alias!,
          firstName: `${result?.firstName}`,
          lastName: `${result?.lastName}`,
        });

        reviewerPapiCount += 1;
        if (reviewerPapiCount === localReviewSessionData.documentReviewers.length) {
          setReviewers(
            reviewerProfiles.sort((a, b) => {
              return (a?.alias ?? '').localeCompare(b?.alias ?? '');
            })
          );
          setIsReviewersLoading(false);
        }
      });
    });

    void listVotesBySessionId({ documentReviewSession: localReviewSessionData.id, alias: user }).then((results) => {
      setSessionVotes(new Map(results?.map((vote) => [vote.alias, vote])));
    });

    void listCommentsBySessionId({
      documentReviewId: localReviewSessionData.id,
      alias: user!,
    }).then((results) => {
      setComments(results);
    });

    const ownerProfile = getPapiProfile(localReviewSessionData.ownerAlias);

    void ownerProfile.then((result) => {
      setOwner({
        alias: localReviewSessionData.ownerAlias,
        firstName: `${result?.firstName}`,
        lastName: `${result?.lastName}`,
      });
    });

    const pollInterval = setInterval(() => {
      void listCommentsBySessionId({
        documentReviewId: localReviewSessionData.id,
        alias: user!,
      }).then((results) => {
        setComments(results);
      });

      void listVotesBySessionId({ documentReviewSession: localReviewSessionData.id, alias: user }).then((results) => {
        setSessionVotes(new Map(results?.map((vote) => [vote.alias, vote])));
      });

      if (!isOwner) {
        void getDocumentReviewSessionState(localReviewSessionData.id).then((state) => {
          if (state && state.sessionState && initialSessionState !== state.sessionState) {
            setReviewSessionState(state.sessionState);
            setInitialSessionState(state.sessionState);
          }
        });
      }
    }, REVIEW_SESSION_REFRESH_SECONDS * 1000);

    return () => clearInterval(pollInterval);
  }, [
    getDocumentReviewSessionState,
    getPapiProfile,
    initialSessionState,
    isOwner,
    listCommentsBySessionId,
    listVotesBySessionId,
    localReviewSessionData,
    user,
  ]);

  const handleCommentRefresh = () => {
    void listCommentsBySessionId({
      documentReviewId: localReviewSessionData.id,
      alias: user!,
    }).then((results) => {
      setComments(results);
    });
  };

  const handleSaveStateChange = async (newState) => {
    if (initialSessionState !== newState) {
      let runUpdate = true;
      if (newState === DocumentReviewSessionState.COMPLETE) {
        let yesYesCount = 0;
        sessionVotes.forEach((vote) => {
          if (
            vote.documentReady &&
            vote.documentReady === 'YES' &&
            vote.candidateReady &&
            vote.candidateReady === 'YES'
          ) {
            yesYesCount += 1;
          }
        });
        if (yesYesCount === 0) {
          runUpdate = false;
          setShowWarningModal(true);
          return;
        }
      }

      if (runUpdate) {
        const updateResult = await reviewSessionActions.update({
          sessionState: newState,
          overrideReason,
        });
        if (updateResult && updateResult.sessionState === newState) {
          if (newState === DocumentReviewSessionState.COMPLETE) {
            goToPage(DocumentReviewSessionsPage);
          }
          setReviewSessionState(updateResult.sessionState);
          setInitialSessionState(updateResult.sessionState);
          handleVoteRefresh();
        }
      }
    }
  };

  const handleModalOverride = async () => {
    if (overrideReason) {
      setShowWarningModal(false);
      const updateResult = await reviewSessionActions.update({
        sessionState: DocumentReviewSessionState.COMPLETE,
        overrideReason,
      });
      if (updateResult && updateResult.sessionState === DocumentReviewSessionState.COMPLETE) {
        setReviewSessionState(updateResult.sessionState);
        setInitialSessionState(updateResult.sessionState);

        goToPage(DocumentReviewSessionsPage);
      }
    }
  };

  const handleModalDismiss = () => {
    setShowWarningModal(false);
    setReviewSessionState(initialSessionState);
  };

  const handleStateChange = (newState) => {
    setReviewSessionState(newState);
  };

  const handleVote = (voteType, value) => {
    if (voteType) {
      if (voteType === 'documentReady') {
        if (value === 'Yes') {
          setDocumentReadyVote(DocumentReviewSessionVote.YES);
        }
        if (value === 'No') {
          setDocumentReadyVote(DocumentReviewSessionVote.NO);
        }
      }
      if (voteType === 'candidateReady') {
        if (value === 'Yes') {
          setCandidateReadyVote(DocumentReviewSessionVote.YES);
        }
        if (value === 'No') {
          setCandidateReadyVote(DocumentReviewSessionVote.NO);
        }
      }
    }
    setUserVote((prevVote) => ({ ...prevVote, [voteType]: value }));
  };

  const handleVoteSave = () => {
    if (candidateReadyVote && documentReadyVote) {
      if (sessionVotes.has(user!) && sessionVotes.get(user!)?.id) {
        const voteId = sessionVotes.get(user!)?.id;
        void onUpdate({
          id: voteId!,
          documentReviewSession: localReviewSessionData.id,
          reviewerAlias: user!,
          documentReady: documentReadyVote,
          candidateReady: candidateReadyVote,
        }).then(() => handleVoteRefresh());
      } else if (
        localReviewSessionData.documentReviewers.map((reviewer) => {
          return reviewer.alias === user;
        })
      ) {
        void onCreate({
          documentReviewSession: localReviewSessionData.id,
          reviewerAlias: user!,
          documentReady: documentReadyVote,
          candidateReady: candidateReadyVote,
        }).then(() => handleVoteRefresh());
      }
    }
  };

  const items = [
    {
      header: 'Candidate Information',
      content: <CandidateInfo candidateProfile={candidateProfile} manager={owner} />,
    },
    {
      header: 'Session Information',
      content: (
        <DocInfo
          documentReviewSession={localReviewSessionData}
          isOwner={isOwner}
          owner={owner}
          initialSessionState={initialSessionState}
          sessionState={reviewSessionState}
          onStateChange={handleStateChange}
          onSaveStateChange={handleSaveStateChange}
        />
      ),
    },
    {
      header: 'Comments',
      content: (
        <CommentList
          comments={comments}
          isCommentsLoading={isMutating}
          documentReviewId={localReviewSessionData.id}
          voteStage={initialSessionState}
          commentRefresh={handleCommentRefresh}
        />
      ),
    },
    {
      header: 'Reviewer Votes',
      content: (
        <VotingSection
          user={user!}
          isOwner={isOwner}
          reviewers={reviewers}
          sessionVotes={sessionVotes}
          sessionState={initialSessionState}
          userVote={userVote}
          isReviewersLoading={isReviewersLoading}
          onVote={handleVote}
          onSave={handleVoteSave}
          onRefresh={handleVoteRefresh}
        />
      ),
    },
  ];

  return (
    <>
      <WarningModal
        visible={showWarningModal}
        minReviewers={MIN_REVIEWERS_PER_DOCUMENT_REVIEW}
        overrideReason={overrideReason}
        setOverrideReason={setOverrideReason}
        overrideReasonValidationText={overrideReasonValidationText}
        setOverrideReasonValidationText={setOverrideReasonValidationText}
        onDismiss={handleModalDismiss}
        onProceed={handleModalOverride}
      />
      <SpaceBetween direction="vertical" size="l">
        <Cards
          items={items}
          cardDefinition={{
            header: (item) => item.header,
            sections: [
              {
                id: 'content',
                content: (item) => item.content,
              },
            ],
          }}
          cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
        />
      </SpaceBetween>
    </>
  );
};

export default ViewDocumentReviewSession;
