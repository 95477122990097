/* eslint-disable @typescript-eslint/lines-between-class-members */

import { AppLayoutProps } from '@amzn/awsui-components-react/polaris/app-layout';
import { AuthAction } from '@/resources/rbac-constants';

/**
 * Object definition for a navigable/routable page in the UI
 * When adding a new page, simply create a new page definition,
 * and add it to the 'allPages' function below. All navigation will automatically update.
 */
export interface PageDefinition {
  id: string;
  /** Relative url path to the page. Shouldn't include any parent values, and no trailing/leading slash */
  path: string;
  /**
   * If this is a child of another page/section, this sets the direct parent page.
   * Effectively turns the pages into a linked list.
   */
  parentPage?: PageDefinition;
  /** Label for how this page should show in the Sidebar nav */
  sidebarLabel: string;
  /** Label for the page in the Breadcrumbs header */
  breadcrumbLabel: string;
  /** Flag for whether this page is just a container. Ex: work-summaries */
  isContainerOnly?: boolean;
  /** Flag for whether this page should show in side nav */
  isSidebarEnabled: boolean;
  /** specifies the content type of the page. This influences how headers and other styling display. */
  contentType?: PageType;

  authAction?: AuthAction;
}

type PageType = AppLayoutProps['contentType'];

interface BasePageParams {
  label: string;
  path?: string;
  type?: PageType;
}

interface CreateContainerParams extends BasePageParams {
  showInSidebar?: boolean;
  breadcrumb?: string;
  authAction?: AuthAction;
}

interface CreateChildPageParams extends BasePageParams {
  showInSidebar?: boolean;
  path: string;
}

class AppPage implements PageDefinition {
  id: string;
  path: string;
  sidebarLabel: string;
  breadcrumbLabel: string;
  parentPage?: PageDefinition;
  isSidebarEnabled = false;
  isContainerOnly = false;
  contentType?: PageType;
  authAction?: AuthAction | undefined;

  constructor(
    id: string,
    path: string,
    label: string,
    parent: PageDefinition | undefined = undefined,
    type: PageType = 'default',
    showInSidebar?: boolean,
    breadcrumb?: string,
    authAction?: AuthAction
  ) {
    this.id = id;
    this.path = path;
    this.sidebarLabel = label;
    this.breadcrumbLabel = breadcrumb || (!parent ? label.split(' ')[0] : label);
    this.parentPage = parent;
    this.isContainerOnly = !parent;
    this.isSidebarEnabled = showInSidebar === undefined ? false : showInSidebar;
    this.contentType = type;
    this.authAction = authAction ?? parent?.authAction;
  }

  addChild(params: CreateChildPageParams): AppPage {
    const { label, path, type = 'default', showInSidebar = false } = params;
    const pageId = `${this.id}${label.replace(' ', '').toLowerCase()}`;
    return new AppPage(pageId, path, label, this, type, showInSidebar);
  }

  static createContainer(params: CreateContainerParams): AppPage {
    const { path = '', label, breadcrumb, type = 'default', showInSidebar = true, authAction } = params;
    const pageId = path ? path.toLowerCase() : label.toLowerCase();
    return new AppPage(pageId, path, label, undefined, type, showInSidebar, breadcrumb, authAction);
  }
}

/** This is the `promohub` landing page. Root of the app. */
export const HomePage = AppPage.createContainer({ label: 'PromoHub', showInSidebar: false });
export const CandidatePage = AppPage.createContainer({
  path: 'candidate',
  label: 'My dashboard',
  breadcrumb: 'Candidate',
});
export const CandidateOverviewPage = CandidatePage.addChild({ label: 'Overview', path: 'view' });
export const CreateWorkSummaryPage = CandidatePage.addChild({
  label: 'Create work summary',
  path: 'work-summary/create',
  type: 'form',
});
export const EditWorkSummaryPage = CandidatePage.addChild({
  label: 'Edit work summary',
  path: 'work-summary/edit',
  type: 'form',
});

export const ManagerPage = AppPage.createContainer({
  label: 'Manager dashboard',
  path: 'manager',
  authAction: AuthAction.NAVIGATE_MANAGER,
});
export const CandidateProgressPage = ManagerPage.addChild({ label: 'Candidate progress', path: 'candidate-progress' });
export const ReviewWorkSummariesPage = ManagerPage.addChild({
  label: 'Work summary reviews',
  path: 'review-work-summaries',
});
export const PromoReadinessPage = ManagerPage.addChild({
  label: 'Promo readiness',
  path: 'promo-readiness',
});
export const ReviewWorkSummaryPage = ManagerPage.addChild({
  label: 'Review work summary',
  path: 'work-summary/review',
  type: 'form',
});
export const ManagePromoPathsPage = ManagerPage.addChild({ label: 'Promo paths', path: 'promo-path/view' });
export const CreatePromoPathPage = ManagerPage.addChild({
  label: 'Create promo path',
  path: 'promo-path/create',
  type: 'form',
});
export const EditPromoPathPage = ManagerPage.addChild({
  label: 'Edit promo path',
  path: 'promo-path/edit',
  type: 'form',
});

export const FilesPage = AppPage.createContainer({ label: 'Files', path: 'files', type: 'table' });
export const ViewFilesPage = FilesPage.addChild({ label: 'My files', path: 'view', type: 'table' });

export const PeerReviewPage = AppPage.createContainer({
  breadcrumb: 'Peer reviews',
  label: 'Peer review',
  path: 'peer-review',
  type: 'table',
});
export const PeerReviewDashboardPage = PeerReviewPage.addChild({
  label: 'My review requests',
  path: 'view',
  type: 'table',
});
export const EditPeerReviewPage = PeerReviewPage.addChild({
  label: 'Edit peer review',
  path: 'edit',
  type: 'form',
});

export const AdminPage = AppPage.createContainer({
  label: 'Admin',
  path: 'admin',
  authAction: AuthAction.NAVIGATE_ADMIN,
});
export const AdminWorkSummarySearchPage = AdminPage.addChild({
  label: 'Work summary search',
  path: 'ws-search',
  type: 'table',
});
export const EditWorkSummaryAdminPage = AdminPage.addChild({
  label: 'Edit work summary',
  path: 'edit',
  type: 'form',
});

export const DocumentReviewSessionsPage = AppPage.createContainer({
  breadcrumb: 'Document Review Sessions',
  label: 'Document Review Sessions',
  path: 'document-review-sessions',
  showInSidebar: true,
  type: 'table',
});

export const ReviewDocumentReviewSessionPage = DocumentReviewSessionsPage.addChild({
  label: 'View document review session',
  path: 'review',
  type: 'form',
});

export const EditDocumentReviewSessionPage = DocumentReviewSessionsPage.addChild({
  label: 'Edit document review session',
  path: 'edit',
  type: 'form',
});

export const ListDocumentReviewSessionsPage = DocumentReviewSessionsPage.addChild({
  label: 'List of Document Review Sessions',
  path: 'list-sessions',
  type: 'table',
  showInSidebar: true,
});

export const CreateDocumentReviewSessionPage = DocumentReviewSessionsPage.addChild({
  label: 'Create document review session',
  path: 'create',
  type: 'form',
});

export const ListDocumentReviewersPage = DocumentReviewSessionsPage.addChild({
  label: 'List of Calibrated Document Reviewers',
  path: 'list-reviewers',
  type: 'table',
  showInSidebar: true,
});

export const PanelReviewSessionsPage = AppPage.createContainer({
  breadcrumb: 'Panel Review Sessions',
  label: 'Panel Review Sessions',
  path: 'panel-review-sessions',
  showInSidebar: true,
  type: 'table',
});

export const ListPanelReviewSessionsPage = PanelReviewSessionsPage.addChild({
  label: 'List of Panel Review Sessions',
  path: 'list-sessions',
  type: 'table',
  showInSidebar: true,
});

export const CreatePanelReviewSessionPage = PanelReviewSessionsPage.addChild({
  label: 'Create panel review session',
  path: 'create',
  type: 'form',
});

export const EditPanelReviewSessionPage = PanelReviewSessionsPage.addChild({
  label: 'Edit panel review session',
  path: 'edit',
  type: 'form',
});

export const ReviewPanelReviewSessionPage = PanelReviewSessionsPage.addChild({
  label: 'View panel review session',
  path: 'review',
  type: 'form',
});

export const ListPanelReviewersPage = PanelReviewSessionsPage.addChild({
  label: 'List of Calibrated Panel Reviewers',
  path: 'list-reviewers',
  type: 'table',
  showInSidebar: true,
});

/** Returns a list of all the app pages. */
export const allPages = [
  HomePage,
  AdminPage,
  AdminWorkSummarySearchPage,
  EditWorkSummaryAdminPage,
  CandidatePage,
  CandidateOverviewPage,
  CreateWorkSummaryPage,
  ManagerPage,
  CandidateProgressPage,
  ReviewWorkSummariesPage,
  PromoReadinessPage,
  CreatePromoPathPage,
  EditPromoPathPage,
  EditWorkSummaryPage,
  ManagePromoPathsPage,
  PeerReviewPage,
  PeerReviewDashboardPage,
  EditPeerReviewPage,
  DocumentReviewSessionsPage,
  ReviewDocumentReviewSessionPage,
  ReviewPanelReviewSessionPage,
  EditDocumentReviewSessionPage,
  CreateDocumentReviewSessionPage,
  ListDocumentReviewSessionsPage,
  ListDocumentReviewersPage,
  PanelReviewSessionsPage,
  ListPanelReviewSessionsPage,
  ListPanelReviewersPage,
  ReviewPanelReviewSessionPage,
  CreatePanelReviewSessionPage,
  EditPanelReviewSessionPage,
  FilesPage,
  ViewFilesPage,
  ReviewWorkSummaryPage,
];

/** Object definition for a `breadcrumb` for the `BreadcrumbGroup` header. */
interface BreadcrumbItem {
  text: string;
  href: string;
}

/**
 * Sets a `default` child for our `container` pages.
 * Example: work-summaries and manager-tools contain sub-pages,
 * so in breadcrumbs clicking these would go nowhere. This provides a place to go.
 */
const containerToDefaultPage = {
  candidate: CandidateOverviewPage,
  manager: CandidateProgressPage,
  files: ViewFilesPage,
  'peer-review': PeerReviewDashboardPage,
  admin: AdminWorkSummarySearchPage,
  'document-review-sessions': DocumentReviewSessionsPage,
  'panel-review-sessions': PanelReviewSessionsPage,
};

/**
 * Dynamically creates the full (absolue) path to the page definition passed in.
 * Note: We should explore `useMemo()` for this long term. This leverages linked-list
 * traversal to build the path.
 */
export const resolveFullPath = (page: PageDefinition, rerouteContainers = true): string => {
  let currentPage: PageDefinition | undefined = page;
  currentPage =
    currentPage?.isContainerOnly && rerouteContainers ? containerToDefaultPage[currentPage.path] : currentPage;
  const paths: string[] = [];
  while (currentPage) {
    paths.push(currentPage.path);
    currentPage = currentPage.parentPage || undefined;
  }
  return paths.reverse().join('/');
};

/**
 * Creates a mapping of absolute-path to `PageDefinition`.
 * Mainly for use with Breadcrumbs. Allows breadcrumbs to remain dynamic,
 * so whenever a new page is added, no work is needed here.
 */
export const AbsolutePathToPageDef = new Map<string, PageDefinition>([
  ...allPages.map((page) => [resolveFullPath(page, false), page] as const),
]);

/**
 * Returns the list of breadcrumbs to display based on the current page.
 * This keeps the breadcrumbs dynamic as it leverages the linked-list setup
 * of the pages to resolve the breadcrumb.
 */
export const resolveBreadcrumbFromPage = (page: PageDefinition) => {
  let currentPage: PageDefinition | undefined = { ...page };
  const crumbs: BreadcrumbItem[] = [];
  while (currentPage) {
    const pathRef = resolveFullPath(
      currentPage.isContainerOnly ? containerToDefaultPage[currentPage.path] : currentPage
    );
    crumbs.push({ text: currentPage.breadcrumbLabel, href: pathRef });
    currentPage = currentPage.parentPage || undefined;
  }
  return crumbs.reverse();
};

// eslint-disable-next-line spaced-comment
//in-source test suites
if (import.meta.vitest) {
  const { it, expect, describe } = import.meta.vitest;

  describe('pages', () => {
    it('should resolve breadcrumbs', () => {
      expect(resolveBreadcrumbFromPage(HomePage)).toEqual([{ text: 'PromoHub', href: '' }]);
      expect(resolveBreadcrumbFromPage(CandidatePage)).toEqual([{ text: 'Candidate', href: 'candidate/view' }]);
      expect(resolveBreadcrumbFromPage(CandidateOverviewPage)).toEqual([
        { text: 'Candidate', href: 'candidate/view' },
        { text: 'Overview', href: 'candidate/view' },
      ]);
      expect(resolveBreadcrumbFromPage(CreateWorkSummaryPage)).toEqual([
        { text: 'Candidate', href: 'candidate/view' },
        { text: 'Create work summary', href: 'candidate/work-summary/create' },
      ]);
    });

    it('should resolve full paths', () => {
      expect(resolveFullPath(HomePage)).toEqual('');
      expect(resolveFullPath(CandidatePage)).toEqual('candidate/view');
      expect(resolveFullPath(CandidateOverviewPage)).toEqual('candidate/view');
      expect(resolveFullPath(CreateWorkSummaryPage)).toEqual('candidate/work-summary/create');
    });

    it('should resolve absolute paths', () => {
      expect(AbsolutePathToPageDef.get('')?.path).toEqual('');
      expect(AbsolutePathToPageDef.get('candidate/view')?.path).toEqual('view');
      expect(AbsolutePathToPageDef.get('candidate/work-summary/create')?.path).toEqual('work-summary/create');
      expect(AbsolutePathToPageDef.get('candidate/work-summary/edit')?.path).toEqual('work-summary/edit');
      expect(AbsolutePathToPageDef.get('manager/candidate-progress')?.path).toEqual('candidate-progress');
    });
  });
}
