import {
  Table,
  Header,
  SpaceBetween,
  Button,
  FormField,
  Form,
  Select,
  TextContent,
} from '@amzn/awsui-components-react';

import { Reviewer, UserVote, Vote } from '../config';
import { DocumentReviewSessionVoteItem } from '@/models';
import { DocumentReviewSessionState } from '@/api/API';
import PromoSpinner from '@/components/common/PromoSpinner';

interface VotingSectionProps {
  user: string;
  isOwner: boolean;
  reviewers: Array<Reviewer>;
  sessionState: DocumentReviewSessionState;
  sessionVotes: Map<string, DocumentReviewSessionVoteItem>;
  userVote: UserVote;
  isReviewersLoading: boolean;
  onVote: (voteType: keyof UserVote, value: string) => void;
  onSave: () => void;
  onRefresh: () => void;
}

const VotingSection: React.FC<VotingSectionProps> = ({
  user,
  isOwner,
  reviewers,
  sessionState,
  sessionVotes,
  userVote,
  isReviewersLoading,
  onVote,
  onSave,
  onRefresh,
}) => {
  let isVotingEnabled = false;

  if (user && !isOwner && sessionState === DocumentReviewSessionState.SCHEDULED) {
    isVotingEnabled = true;
  }
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <SpaceBetween direction="vertical" size="l">
        {isReviewersLoading ? (
          <PromoSpinner variant="inline" size="big" />
        ) : (
          <Table
            items={reviewers}
            header={
              <Header
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="primary"
                      onClick={() => {
                        onRefresh();
                      }}
                    >
                      Refresh
                    </Button>
                  </SpaceBetween>
                }
              >
                Reviewers
              </Header>
            }
            columnDefinitions={[
              { header: 'Reviewer', cell: (item: Reviewer) => `${item.firstName} ${item.lastName} (@${item.alias})` },
              {
                header: 'Document Ready',
                cell: (item: Reviewer) => sessionVotes.get(item.alias)?.documentReady || '-',
              },
              {
                header: 'Candidate Ready',
                cell: (item: Reviewer) => sessionVotes.get(item.alias)?.candidateReady || '-',
              },
            ]}
          />
        )}
        {isVotingEnabled && (
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={() => {
                    onSave();
                  }}
                >
                  Save
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween direction="vertical" size="l">
              <FormField label="Document ready">
                <Select
                  selectedOption={userVote.documentReady ? { label: userVote.documentReady } : null}
                  onChange={({ detail }) => onVote('documentReady', detail.selectedOption.value as Vote)}
                  options={[
                    { label: Vote.Yes, value: Vote.Yes },
                    { label: Vote.No, value: Vote.No },
                  ]}
                  disabled={!isVotingEnabled}
                  placeholder="Select your vote"
                />
              </FormField>
              <FormField label="Candidate ready">
                <Select
                  selectedOption={userVote.candidateReady ? { label: userVote.candidateReady } : null}
                  onChange={({ detail }) => onVote('candidateReady', detail.selectedOption.value as Vote)}
                  options={[
                    { label: Vote.Yes, value: Vote.Yes },
                    { label: Vote.No, value: Vote.No },
                  ]}
                  disabled={!isVotingEnabled}
                  placeholder="Select your vote"
                />
              </FormField>
              {!isVotingEnabled && (
                <TextContent>
                  <p>Voting is only enabled when the session is in the "SCHEDULED" state.</p>
                </TextContent>
              )}
            </SpaceBetween>
          </Form>
        )}
      </SpaceBetween>
    </form>
  );
};

export default VotingSection;
