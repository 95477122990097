import { ForwardedRef, forwardRef, memo } from 'react';
import Alert from '@amzn/awsui-components-react/polaris/alert';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import { DocumentReviewSessionCommentItem } from '@/models';
import { useDocumentReviewSessionCommentRecords } from '@/api/document-review';

interface DeleteCommentModalParams {
  isVisible: boolean;
  onSetVisibility: (v: boolean) => void;
  comment: DocumentReviewSessionCommentItem | undefined;
  commentRefresh: () => void;
}

const DeleteCommentComponent = (
  { comment, isVisible, onSetVisibility, commentRefresh }: DeleteCommentModalParams,
  ref: ForwardedRef<HTMLElement>
) => {
  // Extract the forwarded ref, and pass into the Polaris `Modal` component.
  // the component uses this to anchor the modal. This ensures consistent styling and z-axis behavior.
  const parentRef = (ref as React.MutableRefObject<HTMLElement>) || undefined;
  const { onDeleteDocumentComment, isMutating } = useDocumentReviewSessionCommentRecords();

  function resetModal() {
    onSetVisibility(false);
  }

  const deleteCommentItem = async () => {
    if (!comment) {
      return;
    }
    try {
      await onDeleteDocumentComment({ id: comment.id });
    } finally {
      resetModal();
      commentRefresh();
    }
  };

  return (
    <Modal
      modalRoot={parentRef?.current || undefined}
      onDismiss={() => resetModal()}
      closeAriaLabel="Close dialog"
      visible={isVisible}
      size="medium"
      header="Delete comment"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" disabled={isMutating} onClick={() => resetModal()}>
              Cancel
            </Button>
            <Button variant="primary" loading={isMutating} disabled={isMutating} onClick={() => deleteCommentItem()}>
              {isMutating ? 'Deleting...' : 'Confirm'}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween direction="vertical" size="m">
        <Box variant="span">Delete comment permanently?</Box>
        <Alert statusIconAriaLabel="Info">This action cannot be undone.</Alert>
      </SpaceBetween>
    </Modal>
  );
};

const DeleteCommentModal = memo(forwardRef(DeleteCommentComponent));

export default DeleteCommentModal;
